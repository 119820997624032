/* 객실 상세 - 호텔 */
.room-detail-hotel {

	/* 사진 영역 */
	.photo-wrap {
		height: 640px;
		position: relative;

		&[data-len='1'] {
			.bx-pager-item { width: 0; overflow: hidden; }
			.bx-pager-item + .other-control { margin-left: 0; }
		}
	}

	.contact-reservation {
		padding: 35px 0;
		background: #f6f3ee;

		.wrap {
			width: $content-width;
			margin: auto;

			@include clearfix;
		}

		.contact {
			float: left;
			width: 50%;
			padding: 6px 0 12px;

			strong {
				font-size: 18px;
			}
		}

		.reservation {
			box-sizing: border-box;
			float: right;
			width: 50%;
			padding: 6px 0 12px 70px;
			border-left: 1px solid #ddd;
		}

		.title {
			float: left;
			padding-left: 9px;
			padding-right: 42px;
			font-weight: 500;
			font-size: 22px;
			color: #232323;
		}

		.desc {
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
		}

		.link-action {
			display: block;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 8px;
				right: 0;

				@include sprite($room-link);
			}
		}
	}

	.detail-content {
		width: $content-width;
		margin: 0 auto;
		padding: 67px 0 0;
	}

	.room-title {
		margin-bottom: 46px;
		text-align: center;

		.title {
			font-size: 32px;
			color: #232323;

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				margin: 17px auto 24px;
				background: #111;
			}
		}

		.desc {
			font-weight: 300;
			font-size: 16px;
		}
	}

	.info-list {
		padding-top: 2px;
		padding-left: 7px;

		li {
			box-sizing: border-box;
			display: table;
			width: 100%;
			padding-left: 18px;
			font-weight: 300;
			font-size: 16px;
			letter-spacing: 0;
			position: relative;

			&:not(:first-child) { margin-top: 6px; }

			&:before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				background: #666;
				position: absolute;
				top: 9px;
				left: 4px;
			}
		}

		.head {
			display: table-cell;
			width: 116px;

			&.inline { display: inline-block; }
		}
	}

	.info-img {
		display: table-cell;
		width: 770px;
		height: 380px;
		vertical-align: top;
		background-position: 0 0;
		background-repeat: no-repeat;
	}

	.basic-info {
		box-sizing: border-box;
		display: table;
		width: 100%;
		table-layout: fixed;
		position: relative;

		.con {
			box-sizing: border-box;
			display: table-cell;
			padding: 50px 0 50px 0;
			vertical-align: middle;
		}
	}

	.info-guide {
		box-sizing: border-box;
		display: table;
		width: 100%;
		table-layout: fixed;
		margin-top: 50px;
		position: relative;
		background-position: 0 0;
		background-repeat: no-repeat;

		.con {
			box-sizing: border-box;
			display: table-cell;
			padding: 50px 0 50px 50px;
			vertical-align: middle;
		}
	}

	.price-area {
		margin-top: 70px;

		.actions {
			margin-top: -56px;
			margin-bottom: 16px;
			text-align: right;
			font-weight: 300;
			font-size: 16px;
		}
	}

	/* 객실 편의시설 */
	.amenity-area {
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		padding: 45px 0;
	}

	.amenity-list {
		@include clearfix;

		.item {
			box-sizing: border-box;
			float: left;
			width: calc(100% / 6);
			padding-top: 66px;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			vertical-align: top;

			&.amenity1 { background: url('@@img/room/amenity1.png') no-repeat 50% 0; }
			&.amenity2 { background: url('@@img/room/amenity2.png') no-repeat 50% 0; }
			&.amenity3 { background: url('@@img/room/amenity3.png') no-repeat 50% 0; }
			&.amenity4 { background: url('@@img/room/amenity4.png') no-repeat 50% 0; }
			&.amenity5 { background: url('@@img/room/amenity5.png') no-repeat 50% 0; }
			&.amenity6 { background: url('@@img/room/amenity6.png') no-repeat 50% 0; }
			&.amenity7 { background: url('@@img/room/amenity7.png') no-repeat 50% 0; }
		}
	}

	.amenity-info-list {
		margin-top: 44px;
		padding-left: 50px;

		@include clearfix;

		.item {
			position: relative;
			float: left;
			padding-top: 14px;
			font-size: 0;

			&:not(:first-child) { margin-left: 65px; }
	
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 1px;
				background: #a8a8a8;
			}
		}
		
		.tit { 
			display: inline-block;
			padding-right: 20px;
			color: #232323;
			font-size: 20px;
			font-weight: 500;
			line-height: 1;
			vertical-align: top;
		}

		.list {
			display: inline-block;
			margin-top: 3px;
			width: 190px;
			vertical-align: top;
			
			li {
				position: relative;
				padding-left: 13px;
				font-size: 15px;
				font-weight: 300;

				&:not(:first-child) { margin-top: 8px; }

				&:before {
					content: '';
					display: block;
					width: 2px;
					height: 2px;
					background: #999;
					position: absolute;
					top: .7em;
					left: 0;
				}
			}
		}
	}

	/* 하단 정보 */
	.foot-info-title {
		width: $content-width;
		margin-left: auto;
		margin-right: auto;
	}

	.foot-info {
		margin-bottom: -120px;
		padding: 60px 0;
		color: #fff;
		background: #76777b;

		.wrap {
			box-sizing: border-box;
			width: $content-width;
			display: table;
			table-layout: fixed;
			margin: 0 auto;
			padding: 0 65px;
		}
	}

	.foot-item {
		box-sizing: border-box;
		display: table-cell;
		width: calc(100% / 4);
		padding-bottom: 15px;
		vertical-align: top;
		text-align: center;
		border-left: 1px solid #8d8d90;

		@for $i from 1 through 4 {
			&:nth-child(#{$i}) { background: url('@@img/room/hotel-foot#{$i}.png') no-repeat 50% 0; }
		}

		&:last-child { border-right: 1px solid #8d8d90; }
		
		dt {
			display: block;
			height: 60px;
			padding-top: 74px;
			font-size: 18px;
			color: #fff;
		}

		dd {
			box-sizing: border-box;
			display: inline-block;
			max-width: 100%;
			padding: 0 10px;
			text-align: left;
			font-weight: 300;
			font-size: 15px;
		}

		li {
			padding-left: 12px;
			text-indent: -12px;

			&:before {
				content: '•';
				display: inline;
				margin-right: 4px;
			}

			&:not(:first-child) { margin-top: 7px; }
		}

		.bu {
			margin-top: 7px;
			padding-left: 17px;
			text-indent: -17px;
		}
	}
}
/* 건축아트 유민미술관 */
.architecture-yuminart {

	.photo-wrap {
		height: 460px;

		&[data-len='1'] {
			.bx-pager-item { width: 0; overflow: hidden; }
			.bx-pager-item + .other-control { margin-left: 0; }
		}
	}

	.vr-view { width: 100%; height: 460px; }

	.architecture-title {
		margin: 90px 0 95px;
		text-align: center;

		.title {
			font-size: 32px;
			color: #232323;
			line-height: 1.3;

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				margin: 20px auto 24px;
				background: #111;
			}
		}

		.desc {
			font-weight: 300;
			font-size: 16px;
			line-height: 1.8;
		}
	}

	.yuminart-info-wrap {
		overflow: hidden;
		box-sizing: border-box;

		.thumb { float: left; }
		.con { 
			float: right;
			
			.con-tit {
				color: #232323;
				font-size: 20px;
				font-weight: 500;
				margin: 5px 0 25px;
				line-height: 1.3;
			}

			p {
				font-size: 16px;
				font-weight: 300;

				&:not(:first-child) { margin-top: 27px; }
			}
		}
	}

	.yuminart-list {
		.item {
			overflow: hidden;
			box-sizing: border-box;

			&:nth-child(even) {
				overflow: hidden;

				.thumb { float: right; }
				.con-wrap { float: left; padding-left: 0; }
			}
		}

		.thumb { 
			float: left;
			img { width: 525px; height: 320px; }
		}

		.con-wrap { 
			float: left;
			padding-left: 30px;
			width: 645px;
			font-size: 16px;
			font-weight: 300;

			p {
				&:not(:first-child) { margin-top: 10px; }
			}
			
		}
		.con-tit {
			color: #232323;
			font-size: 22px;
			font-weight: 500;
			margin-bottom: 15px;
			line-height: 1.3;
		}
	}

	.yuminart-moving-line {
		margin-top: 60px;
		width: 1200px;
		height: 544px;
		background: url('@@img/architecture/yuminart-moving-line.jpg') 0 0 no-repeat;

		li {
			@include hidden;
		}
	}

	.architect-area { margin-bottom: -120px; }

	.author-list {
		overflow: hidden;
		width: 1200px;
		box-sizing: border-box;

		.item {
			float: left;
			box-sizing: border-box;
			width: 600px;
			height: 418px;
			border: 1px solid #ddd;
			padding: 55px 0 0 45px;
			
			&:nth-child(even) {
				width: 600px;
				height: 418px;
				border: 1px solid #ddd;
				margin-left: -1px;
			}

			&:nth-child(2) ~ .item { height: 497px; margin-top: -1px; }

			&:nth-child(1) { background: url('@@img/architecture/yuminart-author-img1.jpg') 430px 49px no-repeat; }
			&:nth-child(2) { background: url('@@img/architecture/yuminart-author-img2.jpg') 430px 49px no-repeat; }
			&:nth-child(3) { background: url('@@img/architecture/yuminart-author-img3.jpg') 430px 49px no-repeat; }
			&:nth-child(4) { background: url('@@img/architecture/yuminart-author-img4.jpg') 430px 49px no-repeat; }
		}

		.tit { 
			min-height: 118px;
			color: #000;
			font-weight: 500;
			font-size: 22px;
			line-height: 1.4;

			span { 
				display: inline-block;
				width: 100%;
				font-size: 15px;
				color: #666;
			}
		}

		.con {
			margin-top: 25px;
			font-size: 16px;
			font-weight: 300;
		}
	}

	.discount-tit { 
		font-weight: 500;
		font-size: 15px;
		color: #1a1a1a;
	}

}
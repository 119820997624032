/* 인쇄 스타일 */
@media print {
	@page { size: auto; }
	
	html,body { min-width: 1200px; zoom: 90%; }

	.wrapper {
		padding-bottom: 50px;

		.common-header,
		.section-container > .section-head,
		.common-footer { display: none; }
	}
}
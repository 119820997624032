/* 예약확인 - 로그인 */
.mypage-login {
	
	.mypage-login-form {
		position: relative;
		margin-top: 25px;

		.input-row {
			width: calc(100% - 121px);
			& + .input-row { margin-top: 5px; }
		}
		
		.input {
			box-sizing: border-box;
			display: block;
			width: 100%;
			height: 48px;
			line-height: 46px;
			padding: 0 20px;
			font-size: 16px;
			color: #555;
			background: #fff;
			border: 1px solid #ddd;
			border-radius: 0;

			@include placeholderColor($color: #999);
		}
		
		.btn-submit {
			display: inline-block;
			width: 111px;
			height: 101px;

			position: absolute;
			top: 0;
			right: 0;
			
			background: #232323;
			color: #fff;
			font-size: 18px;
			font-weight: 500;
			text-align: center;
		}
		
		.msg {
			margin-top: 18px;
			font-size: 15px;
			font-weight: 300;
		}

		.option-row {
			margin-top: 20px;

			label {
				color: #232323;
				font-size: 15px;
				font-weight: 300;
			}
		}
	}
	
	.login-wrap {
		position: relative;
		box-sizing: border-box;
		padding: 33px 0 34px;
		border-top: 2px solid #232323;
		border-bottom: 1px solid #232323;
		
		@include clearfix;
	
		.title {
			font-size: 24px;
			font-weight: 500;
			letter-spacing: -.05em;
			text-align: center;
		}
		.desc {
			font-size: 16px;
			font-weight: 300;
			text-align: center;
		}

		.action {
			margin-top: 30px;
			padding-top: 18px;
			border-top: 1px solid #ddd;
			
			.find { @include clearfix; }
	
			.msg {
				float: left;
				color: #232323;
				font-size: 16px;
				font-weight: 300;
				line-height: 40px;
			}
	
			.btn-area {
				float: right;
				.btn { padding: 0 20px; }
			}
		}

		/* 회원 */
		.member-login {
			box-sizing: border-box;
			float: left;
			width: 710px;
			padding: 35px 118px 35px 105px;

			.mypage-login-form { width: 488px; }
			.title { color: #232323; }
		}

		/* 비회원 */
		.nonmember-login {
			box-sizing: border-box;
			float: right;
			width: 490px;
			padding: 35px 44px 35px 56px;
			border-left: 1px solid #ddd;
			
			.title { color: #666; }
			.title,
			.desc { margin: 0 -44px 0 -56px; }

			.mypage-login-form { width: 389px; }

			.btn-submit { background: #999; }
		}
	}
	
}
/* 골프 - 이용안내 */
.golf-guide {
	
	/* 월별 Tee Off 및 주말,공휴일 예약 캘린더 */
	.table-container {
		th.calendar { background: #c2c2c2; color: #fff; }
	}

}


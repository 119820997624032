//
// 예약/구매 정보 입력 FORM 페이지에서 공통 사용하는 유형
// --------------------------------------------------

/* 입력 테이블 하단 가이드 목록 */
.foot-guide-list {
	padding: 25px 0 27px;
	border-bottom: 1px solid #ddd;

	> li {
		padding: 0 25px;
		font-weight: 300;
		font-size: 16px;
		position: relative;

		&:not(:first-child) { margin-top: 4px; }

		&:after {
			content: '';
			display: block;
			width: 3px;
			height: 3px;
			background: #b4b4b4;
			position: absolute;
			top: 11px;
			left: 12px;
		}

		ul { margin-top: 5px; }
	}
}
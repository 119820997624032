/* 가입완료 */
.join-ok {

	.main-box {
		box-sizing: border-box;
		width: 1000px;
		margin: 0 auto;
		padding: 60px 0;
		text-align: center;
		border-top: 2px solid #232323;
		border-bottom: 1px solid #ddd;
	}

	.main-msg {
		margin-top: -9px;
		line-height: 48px;
		font-weight: 300;
		font-size: 34px;
		color: #000;
		letter-spacing: -.05em;
	}

	.sub-desc {
		margin-top: 17px;
		line-height: 30px;
		font-weight: 300;
		font-size: 20px;
	}

	.btn-area {
		margin-top: 35px;
	}

}
/* 골프 예약 변경 신청 */
.pop-golf-change {
	&.common-pop-body { width: 490px; }

	.main-msg {
		margin-bottom: 14px;
		text-align: center;
		font-size: 18px;
		color: #232323;
	}

	.time-table {
		border: 1px solid #ddd;

		h2 {
			height: 49px;
			line-height: 49px;
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			background: #f5f5f5;
			border-bottom: 1px solid #ddd;
		}

		ul {
			height: 250px;
			padding: 0 20px;
			overflow: scroll;
			overflow-x: hidden;
		}

		li {
			&:not(:first-child) { border-top: 1px solid #ddd; }
		}

		a {
			display: block;
			height: 49px;
			line-height: 49px;
			text-align: center;
			font-weight: 300;
			font-size: 16px;
			text-decoration: none;
		}

		.selected a {
			background: #fafafa;
		}
	}
}
/* 휘닉스 블루캐니언 - 소개  */
.waterpark-intro {
	
	/* 워터파크 대표이미지 영역 */
	.main-photo {
		height: 453px;
	}
	
	/* 워터파크 소개 */
	.intro {
		width: $content-width;
		margin: 100px auto;

		@include clearfix;

		.wrap {
			float: left;
			width: calc(100% - 590px);
		}

		.tit {
			color: #232323;
			font-size: 32px;
			font-weight: 400;
			letter-spacing: -.05em;

			&:after {
				content: '';
				display: block;
				width: 26px;
				height: 2px;
				margin: 23px 0 28px;
				background: #232323;
			}
		}
		
		.desc {
			font-size: 16px;
			font-weight: 300;
			text-align: justify;
		}
		
		.video-area {
			float: right;
			width: 525px;
			height: 260px;
		}
	}
	
	.cont-wrap {
		padding: 60px 0;
		background: #f5f5f5;
	}

	/* 어트랙션 운영 현황 */
	.attraction-state {
		span {
			display: inline-block;
			height: 18px;
			padding-left: 24px;
			font-size: 15px;
			line-height: 18px;
		
			&.ing {
				background: url('@@img/waterpark/operation-ing.png') no-repeat left top;
				background-size: 18px 18px;
			}
			&.none {
				margin-left: 35px;
				background: url('@@img/waterpark/operation-none.png') no-repeat left top;
				background-size: 17px 18px;
			}
		}
	}

	.attraction-wrap {
		table {
			width: 100%;
			table-layout: fixed;
			background: #fff;

			&:not(:first-child) { margin-top: 10px; }

			th, td {
				box-sizing: border-box;
				border: 1px solid #ddd;
				text-align: center;
				vertical-align: middle;
				white-space: nowrap;
			}

			th {
				height: 110px;
				background: #1784c3;
				color: #fff;
				font-size: 18px;
				font-weight: 500;
				letter-spacing: -.05em;
				
				&.indoor { background: #006097; } /* 실내존 */

				span { 
					display: block;
					font-size: 15px;
				}
			}

			td {
				font-size: 15px;
				font-weight: 500;
			}

			tr:first-child td { height: 40px; }
			tr:last-child td { height: 70px; }
		}
	}

	.service-wrap {
		width: $content-width;
		margin: 0 auto;
		padding: 57px 0 0;
		letter-spacing: -.05em;
		overflow: hidden;
	}

}
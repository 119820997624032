/* 건축아트 글라스하우스 */
.architecture-glass-house {

	.vr-view { width: 100%; height: 460px; }

	.glass-view-method {
		box-sizing: border-box;
		border: 1px solid #ddd;
		padding: 40px 50px 40px;
		position: relative;
		width: $content-width;

		dl {
			box-sizing: border-box;
			font-size: 16px;
			color: #666;
			@include clearfix;

			&:not(:first-child) {
				margin-top: 17px;
				padding-top: 17px;
				border-top: 1px dashed #ddd;
			}
		}

		dt {
			box-sizing: border-box;
			float: left;
			width: 220px;
			padding-left: 10px;
			color: #232323;
			font-weight: 500;
		}

		dd {
			float: left;
			font-weight: 300;
			width: calc(100% - 220px);

			p {
				&:not(:first-child) {
					margin-top: 10px;
				}
			}
		}
	}
}
/* 객실 이용안내 */
.room-guide {

	/* 리스트 */
	.room-guide-list {
		@include clearfix;
		
		.guide {
			box-sizing: border-box;
			float: left;
			width: 380px;
			height: 430px;
			padding: 0 10px;
			border: 1px solid #ddd;
			text-align: center;
			letter-spacing: -.05em;

			&:not(:nth-child(3n+1)) { margin-left: 30px; }
			&:nth-child(3) ~ li { margin-top: 30px; }
			&:nth-child(6) ~ li { height: 460px; }

			&:before {
				content: '';
				display: block;
				margin: 46px auto 43px;
			}

			&.icon1:before { @include sprite($room-guide1); }
			&.icon2:before { @include sprite($room-guide2); }
			&.icon3:before { @include sprite($room-guide3); }
			&.icon4:before { @include sprite($room-guide4); }
			&.icon5:before { @include sprite($room-guide5); }
			&.icon6:before { @include sprite($room-guide6); }
			&.icon7:before { @include sprite($room-guide7); }
			&.icon8:before { @include sprite($room-guide8); }
			&.icon9:before { @include sprite($room-guide9); }

			.num {
				color: #232323;
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 0;
				line-height: 1;

				.point { 
					display: inline-block;
					margin-left: 5px;
				}
			}
			
			.main-tit {
				margin-bottom: 35px;
				color: #232323;
				font-size: 20px;
				font-weight: 400;
				line-height: 1;

				&:before {
					content: '';
					display: block;
					width: 15px;
					height: 1px;
					margin: 12px auto 10px;
					background: #76777b;
					text-align: center;
				}
			}

			.sub-desc {
				display: inline-block;
				font-size: 15px;
				font-weight: 300;
				line-height: 1.3;

				.point { 
					color: #232323;
					font-weight: 400;
				}

				&.bullet {
					&:before {
						content: '';
						display: inline-block;
						width: 4px;
						height: 4px;
						margin-right: 2px;
						border-radius: 100%;
						background: #666;
						vertical-align: 3px;
					}
				}
			}

			.next-content { margin-top: 30px; }
			.guide-link { margin-top: 18px; }
		}
	}

}
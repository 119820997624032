/* 스노우 스쿨 - 스쿨 안내 */
.snowpark-school-info {

	/* 스노우 스쿨 접수 절차 */
	.reception-process {
		box-sizing: border-box;
		padding: 58px 0;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
		font-size: 0;

		.reception:first-child { margin-bottom: 50px; }

		.info {
			display: inline-block;
			width: 155px;
			height: 155px;
			border-radius: 100%;
			background: #f7f7f7;

			.inner {
				display: table-cell;
				width: 155px;
				height: 155px;
				vertical-align: middle;
				text-align: center;
			}
		}

		.title {
			color: #fff;
			font-size: 20px;
			font-weight: 500;
			text-align: center;
		}

		.txt {
			color: #fff;
			font-size: 18px;
			font-weight: 500;
			letter-spacing: -.05em;

			.light { font-size: 15px; }
		}

		.txt2 {
			color: #232323;
			font-size: 16px;
			font-weight: 500;
		}

		.light { font-weight: 300; }

		.visit { background-color: #76777b; }
		.online { background-color: #07304c; }
		.first { background-color: #01609a; }
		.last { background-color: #b0895c; }

		.process {
			display: inline-block;
			margin-left: 15px;
			padding-left: 15px;
			border-left: 1px solid #ddd;
			font-size: 0;

			li {
				display: inline-block;
				width: 155px;
				background: url('@@img/snowpark/school/arrow-right.png') no-repeat left 5px center;

				&:not(:first-child) { padding-left: 16px; }
			}
		}
	}

	.content-foot-btn {
		margin-top: 30px;
		text-align: center;

		.btn { width: 142px; padding: 0; }
		.btn + .btn { margin-left: 6px; }
	}

	/* 스노우 스쿨 강습 영상 */
	.school-lesson-info {
		box-sizing: border-box;
		display: table;
		width: $content-width;
		padding: 20px 0 50px;
		border-bottom: 1px solid #ddd;
		table-layout: fixed;
	
		.info {
			box-sizing: border-box;
			display: table-cell;
			width: 540px;
			height: 100%;
			padding: 0 40px 0 65px;
			vertical-align: middle;
		}
	
		.info-copy {
			line-height: 1.3;
			font-weight: 500;
			font-size: 24px;
			color: #232323;
		}
	
		.info-desc {
			padding-top: 36px;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
			color: #666;
			position: relative;
		}
	
		.video-area {
			display: table-cell;
			width: 600px;
			height: 320px;
			vertical-align: top;
		}
	}

}
/* 나의 상담내역 */
.mypage-inquiry {
	.head-desc {
		margin-bottom: 20px;
		color: #232323;
		font-size: 20px;
		font-weight: 300;
		text-align: center;
	}

	.board-list {
		.left { text-align: left; }
	}

	/* 문의유형 */
	.cate {
		display: inline-block;
		width: 85px;
		text-align: center;
		vertical-align: middle;
	}

	/* 답변 상태 */
	.state {
		font-weight: 400;
		color: #232323;
	}

	/* 상세 */
	.detail-tit {
		.cate {
			display: table-cell;
			position: relative;
			width: 85px;
			padding: 3px 40px 0 0;
			color: #666;
			font-size: 16px;
			font-weight: 300;
			text-align: left;
			vertical-align: top;
			
			&:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 6px;
				right: 20px;
				width: 1px;
				height: 20px;
				background: #ddd;
			}
		}
	}

	/* 답변영역  */
	.reply-wrap {
		margin: 60px -20px 0;
		padding: 32px 30px 40px;
		background: #f5f5f5;
		font-size: 16px;
		font-weight: 300;

		.reply {
			padding-left: 40px;
			background: url('@@img/mypage/ico-answer.png') no-repeat left top;
		}

		.no-reply { text-align: center; }
	}

}
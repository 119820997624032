/* 학생단체 프로그램 */
.party-student-program {

	.program-info-list {
		padding-left: 164px;
		background: url('@@img/party/pyeongchang/student-mark.jpg') no-repeat 0 0;

		li {
			padding-left: 15px;
			position: relative;
			color: #232323;
			font-size: 16px;
			font-weight: 300;
			margin-bottom: 10px;

			&:before {
				content: '';
				display: inline-block;
				width: 3px;
				height: 3px;
				background: #666;
				position: absolute;
				top: 12px;
				left: 0;
			}

			strong {
				display: inline-block;
				width: 140px;
				font-weight: 500;
			}
		}

	}

	.info2 {
		p {
			color: #fff;
			font-size: 16px;
			font-weight: 300;

			span {
				display: inline-block;
				margin-bottom: 2px;
				width: 94px;
			}
		}
	}

	.table-container {
		margin-bottom: 25px;
	}
	
	.info-program {

		.top-line { border-top: 1px solid #b8b8b8; }

		th { 
			color: #232323;
		}

		.tit-desc {
			color: #666;
		}

		td {
			font-size: 15px;
			color: #666;

			strong {
				display: inline-block;
				margin-bottom: 5px;
				font-weight: 500;
				color: #006097;
			}

			.tit {
				margin-bottom: 5px;
				font-weight: 500;
				color: #b08a5d;
			}
		}
	}

	.inner-list {
		margin: 30px;
		text-align: left;
	}

	.inner-list-item {
		
		ul {
			margin: 7px 0 15px;
		}
		li {
			padding-left: 10px;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				width: 5px;
				height: 1px;
				background: #666;
				position: absolute;
				top: 12px;
				left: 0;
			}
		}
	}

	.manage-box-list1 {
		@include clearfix;
		
		.item { 
			box-sizing: border-box;
			float: left;
			width: 216px;
			margin-left: 30px;

			&:first-child {
				margin-left: 0;
			}
		}
		
		.thumbnail {
			img {
				width: 216px;
				height: 160px;
			}
		}

		.tit {
			font-weight: 300;
			text-align: center;
			font-size: 16px;
			
			strong {
				width: 100%;
				display: inline-block;
				margin-top: 23px;
				color: #232323;
				font-size: 18px;
				font-weight: 500;
			}
		}

	}

	.manage-box-list2 {
		@include clearfix;
	
		box-sizing: border-box;
		margin-top: 45px;
		padding-top: 47px;
		padding: 47px 50px 47px;
		background: #f5f5f5;

		> li {
			float: left;
			width: calc((100% - 80px) / 3);

			&:not(:first-child) { margin-left: 40px; }

			&:nth-child(1) .tit { background: url('@@img/party/pyeongchang/ico-manage1.png') no-repeat 0 0; }
			&:nth-child(2) .tit { background: url('@@img/party/pyeongchang/ico-manage2.png') no-repeat 0 0; }
			&:nth-child(3) .tit { background: url('@@img/party/pyeongchang/ico-manage3.png') no-repeat 0 0; }
		
		}
		.tit {
			display: inline-block;
			width: 82px;
			padding-top: 88px;
			font-weight: 500;
			color: #232323;
			font-size: 18px;
			text-align: center;
		}
	}

	.inner-manage-list {
		float: right;
		width: calc(100% - 102px);
		padding: 4px 0 0;

		li {
			margin-bottom: 6px;
			padding-left: 10px;
			position: relative;
			font-size: 15px;
			font-weight: 300;

			&:before {
				content: '';
				display: inline-block;
				width: 4px;
				height: 4px;
				background: #666;
				border-radius: 10px;
				position: absolute;
				top: 10px;
				left: 0;
			}
		}
	}
		
}
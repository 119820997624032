/* 단지안내 */
.guide-map-jeju {
	max-width: $content-width;

	.map {
		overflow: hidden;
		img { width: 100%; }
	}

	.detail {
		box-sizing: border-box;
		height: 420px;
		margin-top: 20px;
		padding: 40px 0 60px;
		border: 6px solid #f5f5f5;
		background: #fff;
		text-align: center;

		> div { 
			display: inline-block;
			text-align: left;
			vertical-align: top;

			&:not(:first-child) { margin-left: 150px; }
		}

		li:not(:first-child) { margin-top: 11px; }

		.tit {
			margin-bottom: 15px;
			color: #232323;
			font-size: 20px;
			font-weight: 500;
		}

		.num {
			display: inline-block;
			width: 27px;
			height: 27px;
			margin-right: 6px;
			border-radius: 100%;
			color: #fff;
			font-size: 12px;
			font-weight: 700;
			text-align: center;
			line-height: 27px;
		}

		a,
		span {
			display: inline-block;
			font-size: 16px;
			font-weight: 300;
			line-height: 27px;
		}
		
		.room { 
			margin-bottom: 39px;

			.num { background: #ab7e4a; }
			a:hover, a:focus { color: #ab7e4a; }
		}
		.villa { 
			.num { background: #ab7e4a; }
			a:hover, a:focus { color: #ab7e4a; }
		}
		.architecture {
			.num { background: #f66808; }
			a:hover, a:focus { color: #f66808; }
		}
		.experience { 
			.num { background: #4ba727; }
			a:hover, a:focus { color: #4ba727; }
		}
		.etc { 
			.num { background: #29acf7; }
			a:hover, a:focus { color: #29acf7; }
		}
	}
}
/* 고객 설문조사 */
.customer-poll {
	width: 100%;
	min-width: 800px;
	margin: 0 auto;

	.survey-top {
		width: 800px;
		margin: 0 auto;
	}

	h1 {
		box-sizing: border-box;
		height: 90px;
		text-align: center;
		background: #232323 url('@@img/etc/logo.png') no-repeat 50% 25px;
		text-indent: -9999px;
		font-size: 0;
		line-height: 0;
	}

	.tit {
		padding-top: 70px;
		text-align: center;
		font-weight: 500;
		font-size: 38px;
		color: #232323;

		span { color: #b0895c; }
	}

	.dec {
		padding-top: 35px;
		font-size: 18px;
		text-align: center;
		font-weight: 300;
		color: #898989;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			height: 1px;
			width: 26px;
			background: #232323;
			left: 50%;
			top: 10px;
		}
	}

	.survey-list {
		width: 800px;
		margin: 40px auto 50px;
		padding-top: 50px;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #232323;
	}

	.item {
		padding-bottom: 50px;

		dl {
			overflow: hidden;
			width: calc(100% - 60px);
		}
		dt {
			float: left;
			width: 60px;
			margin-top: -3px;
			text-align: right;
			color: #232323;
			font-weight: 500;
			font-size: 26px;
		}
		dd { 
			float: left;
			padding-left: 14px;
			color: #232323;
			font-weight: 500;
			font-size: 20px;
		}
	}

	.link-re-area {
		text-align: right;
		margin-top: -25px;

		a {
			background: url('@@img/etc/ico-re.png') no-repeat 0 2px;
			font-size: 15px;
			color: #232323;
			font-weight: 500;
			padding-left: 25px;
		}
	}

	.notice {
		color: #898989;
		font-size: 16px;
		font-weight: 300;
	}

	.select-con {
		overflow: hidden;
		box-sizing: border-box;
		width: 800px;
		margin-top: 15px;
		padding: 30px;
		background: #f5f5f5;
	}

	.check-list {
		padding: 0 20px 0 40px;
		font-size: 16px;
		overflow: hidden;

		li {
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding-right: 20px;
			padding-bottom: 10px;

			&.full { width: 100%; }
		}
	}

	.select-con-list {
		overflow: hidden;
		box-sizing: border-box;
		width: 800px;
		margin-top: 15px;
		padding: 35px 55px;
		background: #f5f5f5;

		li {
			box-sizing: border-box;
			width: 130px;
			height: 40px;
			float: left;

			&:not(:first-child) { 
				margin-left: 10px;
			}
		}
	}

	.select-btn {
		box-sizing: border-box;
		width: 130px;
		height: 40px;
		border: 1px solid #cfcfcf;
		color: #232323;
		font-size: 16px;
		background: #fff;
		text-align: center;
	 }

	 .active {
		position: relative;
		width: 130px;
		height: 40px;
		border: 2px solid #b0895c;
		color: #232323;
		font-size: 16px;
		background: #fff;
		font-weight: 500;
		padding-left: 30px;
	 }

	 .num {
		 display: inline-block;
		 width: 36px;
		 height: 36px;
		 background: #b0895c;
		 color: #fff;
		 font-weight: 500;
		 position: absolute;
		 line-height: 36px;
		 left: 0;
		 top: 0;
	 }

	.btn-area { 
		text-align: center;
		margin-bottom: 120px;
	}
}
/* 연회 */
.party-banquet {
	
	/* 사진 영역 */
	.photo-wrap {
		height: 500px;
		position: relative;

		&[data-len='1'] {
			.bx-pager-item { width: 0; overflow: hidden; }
			.bx-pager-item + .other-control { margin-left: 0; }
		}
	}

	.contact-reservation {
		padding: 35px 0;
		background: #f6f3ee;

		.wrap {
			width: $content-width;
			margin: auto;

			@include clearfix;
		}

		.contact {
			float: left;
			width: 50%;
			padding: 6px 0 12px;
		}

		.reservation {
			box-sizing: border-box;
			float: right;
			width: 50%;
			padding: 6px 0 12px 50px;
			border-left: 1px solid #ddd;
		}

		.title {
			float: left;
			padding-left: 9px;
			padding-right: 42px;
			font-weight: 500;
			font-size: 22px;
			color: #232323;
			line-height: 25px;
		}

		.desc {
			float: left;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
		}

		.link-action {
			display: block;
			position: relative;
			
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 8px;
				right: 30px;

				@include sprite($room-link);
			}
		}
		
	}

	.detail-content {
		width: $content-width;
		margin: 0 auto;
		padding: 86px 0 0;
	}

	.room-title {
		margin-bottom: 70px;
		text-align: center;

		.title {
			font-size: 32px;
			color: #232323;

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				margin: 17px auto 24px;
				background: #111;
			}
		}

		.desc {
			font-weight: 300;
			font-size: 16px;
		}
	}

	.party-hall-list {
		@include clearfix;

		.item {
			box-sizing: border-box;
			position: relative;
			float: left;
			width: 370px;
			margin-left: 40px;

			&.item:first-child {
				margin-left: 0;
			}
		}

		.img-area {
			position: relative;
			width: 370px;
			height: 370px;
			overflow: hidden;

			img { width: 100%; height: 100%; }

			&:hover {
				.show-box {
					opacity: 1;
					left: 0;
				}
			}
		}

		.show-box {
			transition: opacity .4s;
			opacity: 0;
			position: absolute;
			left: -9999px;
			top: 0;
			background: rgba(35,35,35,.6);
			width: 100%;
			height: 100%;
			display: inline-block;
			box-sizing: border-box;
			vertical-align: middle;
			text-align: center;

			&:after {
				content: '';
				display: inline-block;
				width: 0;
				height: 100%;
				vertical-align: middle;
			}

			p:not(:last-child) { margin-bottom: 10px; }

			.btn-control { 
				width: 130px;
				background: #b0895c;
			}

			.show {
				box-sizing: border-box;
				display: inline-block;
				max-width: 100%;
				vertical-align: middle;
				font-size: 14px;
				white-space: normal;
				outline: none;
				position: relative;
				z-index: 2001;
			}
		}

		h5 {
			font-size: 24px;
			color: #232323;
			margin-bottom: 17px;
			line-height: 25px;
			font-weight: 500;
			text-align: center;

			span {
				display: block;
				color: #b0895c;
				font-size: 16px;
			}
			
		}

		.item-list {
			border-bottom: 1px solid #ddd;
			padding: 28px 0 17px 11px;

			li {
				position: relative;
				color: #232323;
				font-weight: 300;
				padding-left: 15px;
				margin-bottom: 10px;
				font-size: 16px;

				strong {
					display: inline-block;
					font-weight: 500;
					width: 83px;
				}
				
				&:before {
					content: '';
					display: block;
					width: 3px;
					height: 3px;
					background: #666;
					position: absolute;
					left: 0;
					top: 10px;
				}
			}
		}

	}

	/* 연회 - 제주 */
	.common-basic-info {
		p {
			&:not(:first-child) {
				margin-top: 3px;
			}
		}
	}

	.floor-plan-wrap {
		overflow: hidden;

		.item {
			float: left;
			padding: 69px 0 105px;
			width: 585px;
			box-sizing: border-box;
			border: 1px solid #cacaca;
			text-align: center;

			&:not(:first-child) {
				float: right;
			}
		}

		.tit {
			color: #232323;
			font-size: 22px;
			line-height: 24px;
			margin-bottom: 40px;

			span {
				display: inline-block;
				width: 100%;
				font-size: 16px;
				color: #666;
			}
		}

		.floor-list {
			li {
				&:not(:first-child) {
					margin-top: 25px;
				}
			}
		}

		.thum {
			box-sizing: border-box;
			margin-bottom: 10px;

			img { width: 300px; }
		}

		.txt {
			font-size: 16px;
			font-weight: 300;
		}
	}

	.page-foot {
		a {
			width: 200px;
		}
	}


}
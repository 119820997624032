/* 객실/골프 예약 달력 */
.reservation-calendar {
	box-sizing: border-box;
	width: 100%;
	padding: 10px;
	background: #f5f5f5;
	position: relative;

	.calendar-desc {
		margin-bottom: 20px;
		padding-left: 19px;
		text-indent: -19px;
		font-weight: 300;
		font-size: 16px;
		color: #232323;
	}

	/* 달력영역 */
	.calendar-area {
		box-sizing: border-box;
		display: block;
		width: 780px;
		padding: 34px 40px 40px;
		background: #fff;

		.head {
			width: 288px;
			margin: 0 auto 29px;
			text-align: center;
			position: relative;
		}

		.year-month {
			line-height: 1;
			font-weight: 500;
			font-size: 36px;
			color: #232323;
		}

		.btn-prev {
			display: block;
			position: absolute;
			top: 5px;
			left: 0;

			@include spriteIR($reservation-calendar2-prev);
		}

		.btn-next {
			display: block;
			position: absolute;
			top: 5px;
			right: 0;

			@include spriteIR($reservation-calendar2-next);
		}

		.type-list {
			margin-bottom: 21px;
			text-align: center;

			dd {
				display: inline-block;
				font-weight: 300;
				font-size: 16px;
				color: #232323;

				&:not(:last-child) { margin-right: 40px; }

				&:before {
					content: '';
					box-sizing: border-box;
					display: inline-block;
					width: 40px;
					height: 8px;
					margin-right: 10px;
					vertical-align: middle;
					background: #aaa;
					position: relative;
					top: -2px;
				}

				&.is-possible:before { background: #75c8e0; }
				&.is-impossible:before { background: #efad9d; }
				&.is-wait:before { background: #d14e40; }
				&.is-close:before { background: #efea9d; border: 1px solid #ddd; }
				&.is-end:before { background: #fff; border: 1px solid #ddd; }
				&.is-open:before { background: #8b8b8b; }
			}
		}

		table {
			width: 100%;
			table-layout: fixed;
		}

		th {
			box-sizing: border-box;
			width: calc(100% / 7);
			height: 50px;
			text-align: center;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
			border: 1px solid #ddd;
		}

		td {
			box-sizing: border-box;
			width: calc(100% / 7);
			height: 70px;
			vertical-align: top;
			font-weight: 300;
			font-size: 16px;
			color: #232323;
			background: #fff;
			border: 1px solid #ddd;
		}

		.day {
			box-sizing: border-box;
			display: block;
			height: 100%;
			line-height: 70px;
			text-align: center;
			text-decoration: none;

			&.is-disabled { background: #f5f5f5; }
			&.is-selected-start { background: #006097; color: #fff; }
			&.is-selected { background: #d6e9f7; }
			&.is-selected-end { background: #3890c2; color: #fff; }
			&.is-possible { background: #75c8e0; color: #fff; }
			&.is-impossible { background: #efad9d; color: #fff; }
			&.is-wait { background: #d14e40; color: #fff; }
			&.is-end { background: #fff; }
			&.is-open { background: #8b8b8b; color: #fff; }
			&.is-selected { background: #01609a; color: #fff; }
		}

		.sun, .holiday {
			color: #d14e40;
		}

		.sat {
			color: #31a0e0;
		}
		
	}

	/* 시간선택영역 */
	.time-table-area {
		box-sizing: border-box;
		width: 330px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 40px;

		.title {
			height: 50px;
			padding-top: 20px;
			font-weight: 500;
			font-size: 26px;
			color: #232323;
			letter-spacing: -.05em;
		}
	}

	.btn-area {
		width: 100%;
		position: absolute;
		bottom: 50px;
		left: 0;
	}

	.btn-reservation {
		display: block;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-size: 18px;
		color: #fff;
		text-decoration: none;
		background: #232323;
	}

	.tab-filter {
		display: table;
		width: 100%;
		table-layout: fixed;

		li {
			display: table-cell;
			width: calc(100% / 3);

			a {
				display: block;
				height: 38px;
				line-height: 38px;
				margin: -1px;
				text-align: center;
				text-decoration: none;
				font-size: 16px;
				color: #999;
				background: #fff;
				border: 1px solid #ddd;
			}

			&.selected a {
				color: #232323;
				position: relative;
				border-color: #232323;
			}
		}
	}

	.time-list-wrap {
		box-sizing: border-box;
		height: calc(100% - 190px);
		background: #fff;
		border: 1px solid #ddd;
		overflow: auto;
		overflow-x: hidden;
	}

	.time-list {
		padding: 15px 0;
		
		a {
			display: block;
			height: 40px;
			line-height: 40px;
			text-decoration: none;
			overflow: hidden;
		}

		.time {
			float: left;
			width: 100px;
			text-align: center;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
		}

		.name {
			float: right;
			width: calc(100% - 100px);
			font-size: 16px;
			color: #232323;

			// &.type1 { color: #46841c; }
			// &.type2 { color: #378aba; }
		}

		.selected { background: #f5f5f5; }
	}

	.wait-msg {
		text-align: center;
		position: relative;
		top: 50%;
		transform: translate(0,-50%);

		.main {
			font-size: 20px;
			color: #232323;
		}

		.sub {
			margin-top: 22px;
			font-weight: 300;
			font-size: 16px;
			color: #666;

			strong { color: #666; }
		}
	}

	/* 정보입력영역 */
	.info-form-area {
		box-sizing: border-box;
		width: 330px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 40px;

		.btn-area {
			width: 100%;
			position: absolute;
			bottom: 50px;
			left: 0;
		}

		.layout-form {
			box-sizing: border-box;
			height: 100%;
			padding-top: 70px;
			font-size: 18px;
			position: relative;

			.form-head {
				width: 100px;
			}
		}
	}
}
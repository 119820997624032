/* 예약정보 입력 */
.reservation-info-form {

	.license-agree-desc {
		margin-top: 16px;
		padding-bottom: 5px;
		font-weight: 300;
		font-size: 15px;
		color: $point;
	}

	.foot-guide {
		padding: 25px 10px 27px;
		font-weight: 300;
		font-size: 16px;
		border-bottom: 1px solid #ddd;
	}

	.foot-guide-list {
		padding: 25px 0 27px;
		border-bottom: 1px solid #ddd;

		> li {
			padding: 0 25px;
			font-weight: 300;
			font-size: 16px;
			position: relative;
			
			&:not(:first-child) { margin-top: 4px; }

			&:after {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				background: #b4b4b4;
				position: absolute;
				top: 11px;
				left: 12px;
			}

			ul { margin-top: 5px; }
		}
	}

}
/* 탑승장 안내 팝업 */
.pop-traffic-bus {
	
	.head-btn { text-align: right; }
	.map-area { 
		overflow: hidden;
		margin-top: 10px;

		img { width: 100%; }
	}

}
/* 체험,휴식 - 레저 */
.experience-leisure {

	/* 레포츠 안내 */
	.text-card-list {		
		.con2 { 
			color: #666;
			font-weight: 300;
		}
	}

	/* 웰니스 숲길 */
	.wellness-treckking {
		position: relative;
		box-sizing: border-box;
		padding: 36px 48px 50px;
		border: 1px solid #ddd;


		.desc { 
			padding: 0 380px 30px 0;
			font-size: 16px;
			font-weight: 300;
		}
		.dot-bu-list {
			position: absolute;
			top: 40px;
			right: 48px;
		}
	}

	/* 플라잉짚 - 코스안내 */
	.course-guide {
		box-sizing: border-box;
		padding: 50px 50px;
		border: 1px solid #ddd;
	
		.tit {
			position: relative;
			margin: 50px 0 20px 5px;
			padding-left: 12px;
			font-size: 16px;
			font-weight: 300;
			line-height: 18px;
			
			&:first-child { margin-top: 0; }

			strong {
				display: inline-block;
				margin-right: 20px;
				color: #232323;
				font-size: 18px;
				font-weight: 500;
				letter-spacing: -.05em;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 7px;
				left: 0;
				width: 3px;
				height: 3px;
				background: #b4b4b4;
			}
		}

		/* 주의사항 테이블 */
		.attention {
			td { 
				padding: 25px 50px;
				text-align: left;
				vertical-align: top;
	
				li:not(:first-child) { margin-top: 10px; }
			}
		}
	}

	/* 코스이미지 */
	.course-view-list {
		@include clearfix;

		.item {
			box-sizing: border-box;
			float: left;

			img { 
				width: 100%;
				height: 100%;
			}
		}

		/* 2단 */
		&.half {
			.item {
				width: calc((100% - 30px) / 2);

				&:not(:nth-child(2n-1)) { margin-left: 30px; }
			}
		}

		/* 4단 */
		&.quarter {
			.item {
				position: relative;
				width: calc((100% - 90px) / 4);
				
				&:not(:nth-child(4n+1)) { 
					margin-left: 30px;
				
					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 72px;
						left: -21px;
						width: 17px;
						height: 25px;
						background: url('@@img/experience/pyeongchang/leisure/arrow-right.png') no-repeat;
					}
				}
				&:nth-child(4) ~ .item { margin-top: 30px; }
			}
		}

		.info {
			border: 1px solid #ddd;

			p {
				padding: 15px 0;
				background: #f7f7f7;
				font-size: 15px;
				font-weight: 300;
				text-align: center;
				
				&.start { color: #fff; background: #01609a; }
				&.end { color: #fff; background: #b0895c; }
			}
		}
	}
}
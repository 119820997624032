/* 시즌 영업 일정 */
.snowpark-season-schedule {
	.head {
		width: 235px;
		margin: 0 auto 40px;
		text-align: center;
		position: relative;
	}

	.year-month {
		line-height: 1;
		font-weight: 500;
		font-size: 30px;
		color: #232323;
	}

	.btn-prev {
		display: block;
		position: absolute;
		top: 5px;
		left: 0;

		@include spriteIR($snowpark-calendar-prev);
	}

	.btn-next {
		display: block;
		position: absolute;
		top: 5px;
		right: 0;

		@include spriteIR($snowpark-calendar-next);
	}

	.schedule {
		padding-left: 20px;
		text-align: left;

		.item {
			font-size: 14px;
			height: 26px;
			line-height: 26px;

			&:not(:first-child) {
				margin-top: 3px;
			}

			&:before {
				content: '';
				display: inline-block;
				width: 15px;
				height: 15px;
				border-radius: 10px;
				margin-right: 7px;
				vertical-align: middle;
				background: #aaa;
				position: relative;
				top: -2px;
			}

			&.is-daytime-am {
				color: #4ca728;
				&:before { background: #4ca728; }
			}
			&.is-daytime-pm {
				color: #ce5d17;
				&:before { background: #ce5d17; }
			}
			&.is-night {
				color: #01609a;
				&:before { background: #01609a; }
			}
			&.is-midenight {
				color: #07304c;
				&:before { background: #07304c; }
			}
			&.is-whitenight {
				color: #999;
				&:before { background: #999; }
			}
		}
	}

	.schedule-calendar {
		table {
			width: 100%;
			table-layout: fixed;
			border-top: 1px solid #232323;
		}

		th {
			box-sizing: border-box;
			width: calc(100% / 7);
			height: 50px;
			text-align: right;
			padding: 0 15px 0 0;
			font-weight: 400;
			font-size: 16px;
			color: #232323;
			border: 1px solid #ddd;
		}

		td {
			box-sizing: border-box;
			width: calc(100% / 7);
			height: 170px;
			vertical-align: top;
			font-weight: 400;
			font-size: 18px;
			color: #232323;
			background: #fff;
			border: 1px solid #ddd;
		}

		.disabled {
			background: #f5f5f5;
			.day { opacity: .4; }
		}

		.day {
			box-sizing: border-box;
			height: 43px;
			padding: 7px 15px 0 0;
			text-align: right;
		}

		.sun, .holiday {
			color: #d14e40;
			.day { color: #d14e40; }
		}

		.sat {
			color: #0078be;
			.day { color: #0078be; }
		}

		.room-list {
			padding-left: 20px;
		}

		.room-item {
			&:not(:first-child) { margin-top: 4px; }
			a {
				display: block;
				height: 26px;
				line-height: 26px;
				padding: 0 11px;
				font-size: 14px;
				color: #fff;
				text-decoration: none;

				@include ellipsis;

				&.is-possible { background: #65b3e0; }
				&.is-wait { background: #de5a7d; }
				&.is-end { background: #999; }
				&.is-raffle { background: #3fbab9; }
				&.is-tel { background: #787ddb; }
			}
		}
	}

	.period-time-info {
		.wrap {
			border: 1px solid #ddd;
			@include clearfix;
		}

		.desc {
			margin-top: 14px;
			text-align: right;
			font-weight: 300;
			font-size: 16px;
		}
	}

	.period-info {
		box-sizing: border-box;
		float: left;
		width: 50%;
		height: 184px;


		dl {
			display: inline-block;
			font-size: 0;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}

		dt {
			box-sizing: border-box;
			display: inline-block;
			width: 140px;
			padding-left: 13px;
			vertical-align: middle;
			position: relative;
			font-size: 18px;
			color: #232323;

			&:before {
				content: '';
				display: block;
				width: 5px;
				height: 5px;
				background: #232323;
				border-radius: 3px;
				position: absolute;
				top: 13px;
				left: 0;
			}
		}

		dd {
			box-sizing: border-box;
			display: inline-block;
			width: 130px;
			height: 130px;
			padding-top: 33px;
			text-align: center;
			vertical-align: middle;
			line-height: 1.2;
			font-weight: 500;
			background: #f7f7f7;
			border-radius: 65px;
			position: relative;

			&.start:after {
				content: '~';
				width: calc(200% + 34px);
				text-align: center;
				font-weight: 500;
				font-size: 28px;
				color: #232323;
				position: absolute;
				top: 45px;
				left: 0;
			}

			&.end {
				margin-left: 34px;
			}

			.year {
				font-size: 18px;
				color: #b0895c;
			}

			.month-day {
				font-size: 32px;
				color: #232323;
			}
		}
	}

	.time-info {
		box-sizing: border-box;
		float: right;
		width: 50%;
		height: 184px;
		padding-top: 16px;
		background: #f7f7f7;

		dl {
			display: block;
			width: 285px;
			margin: 0 auto;

			@include clearfix;

			&:not(:first-child) {
				margin-top: 7px;
			}
		}

		dt {
			float: left;
			width: 50%;
			font-size: 16px;
			position: relative;

			&:after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-left: 4px solid #666;
				position: absolute;
				top: 8px;
				right: 0;
			}
		}

		dd {
			box-sizing: border-box;
			float: right;
			width: 50%;
			padding-left: 22px;
			font-size: 16px;
			color: #232323;
		}

		.flag {
			box-sizing: border-box;
			display: inline-block;
			width: 50px;
			height: 24px;
			line-height: 22px;
			margin-right: 5px;
			text-align: center;
			font-size: 15px;
			color: #fff;
			border: 1px solid #fff;

			&.is-daytime-am {
				background-color: #4ca728;
				border-color: #4ca728;
				&:before { border-color: #4ca728; }
			}
			&.is-daytime-pm {
				background-color: #ce5d17;
				border-color: #ce5d17;
				&:before { border-color: #ce5d17; }
			}
			&.is-night {
				background-color: #01609a;
				border-color: #01609a;
				&:before { border-color: #01609a; }
			}
			&.is-midenight {
				background-color: #07304c;
				border-color: #07304c;
				&:before { border-color: #07304c; }
			}
			&.is-whitenight {
				color: #666;
				background-color: none;
				border-color: #999;
				&:before { border-color: #999; }
			}
		}
	}
}
/* 나의 정보 관리 */
.mypage-myinfo {
	.frame {
		padding: 70px 170px 85px;
		background: #fff;
		border: 30px solid #f5f5f5;
	}

	.frame-inner {
		padding: 40px 80px 40px 52px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;

		&.no-tb-line { 
			border: none;
			padding: 30px 80px 0 52px;
		}

		&.no-t-line {
			border-top: 0;
		}
	}

	.head-desc {
		width: 100%;
		margin-bottom: 30px;
		text-align: center;
		line-height: 1.3;
		letter-spacing: -.05em;

		.main-desc {
			color: #232323;
			font-size: 28px;
			font-weight: 500;
		}
		.sub-desc { 
			margin-top: 10px;
			font-size: 18px;
			font-weight: 300;
		}
	}

	.form-head {
		width: 123px;
		font-size: 16px;
		padding: 9px 0 0;
	}

	.foot-desc {
		margin-top: 24px;
		font-size: 16px;
		line-height: 26px;
	}

	.pw-style {
		margin-right: 20px;
		position: relative;
		top: 3px;
	}

	/* 가족/지정 회원 관리 조회 목록 */
	.table {
		th { color: #232323; }
		td { color: #666; }

		.member-type { color: #232323; }
		
		.type1 { color: #d14e40; }
		.type2 { color: #d8671a; }
		.type3 { color: #232323; }
		
		.btn { min-width: 120px; }
		
	}

	/* 회원탈퇴 */
	.sub-txt { font-size: 16px; }

	.layout-form {
		&.no-head {
			margin-top: 30px;

			.form-row:not(:last-child) { padding-bottom: 10px; }
			.form-head {
				width: 0;
				@include hidden;
			}
			.form-cell { width: 100%; }
		}
	}

	.reject-msg { 
		color: #232323;
		font-size: 18px;
		text-align: center;
	}

	/* 예약확인 - 비밀번호 재설정 */
	.pwd-change {
		.state-msg { margin: 0 0 20px; }
	}

}
//
// 결제 FORM 페이지에서 공통 사용하는 유형
// --------------------------------------------------

/* 결제 수단 옵션 */
.pay-option {
	display: inline-block;

	@include clearfix;

	> li {
		float: left;
		margin-right: 70px;
	}
}

/* 최종 결제 정보 */
.final-pay-info {
	border-top: 1px solid #232323;

	dl {
		display: block;
		padding: 0 30px;
		line-height: 59px;
		font-weight: 500;
		font-size: 16px;
		color: #232323;
		border-bottom: 1px solid #ddd;

		@include clearfix;
	}

	dt {
		float: left;
	}

	dd {
		float: right;
	}

	.total {
		background: #f5f5f5;

		dd {
			font-weight: 500;
			font-size: 22px;
			color: #d14e40;
		}
	}

	/* 패키지예약 결제정보 구분 */
	.pay-opt {
		padding: 10px 0;
		border-bottom: 1px solid #ddd;

		dl { 
			border-bottom: 0;
			line-height: 40px;
		}

		dt { font-weight: 300; }
	}
}

/* 결제 위약규정 동의 */
.pay-agree {
	margin-top: 50px;
	text-align: center;
	font-size: 16px;
	color: #232323;
}
/* 교통안내 - 셔틀버스 */
.guide-traffic-bus {

	.text-card-list {
		@include clearfix;

		.item {
			float: left;
			color: #666;
			font-size: 17px;
			font-weight: 500;
			box-sizing: border-box;
			width: 290px;
			height: 110px;
			border: 1px solid #232323;
			text-align: center;

			strong { color: #006097; }

			&:not(:first-child) {
				margin-left: 13px;
			}
		}

		.item-inner {
			display: table-cell;
			vertical-align: middle;
			width: 290px;
			height: 110px;
		}
	}

	/* 버스 운영기간 */
	.bus-operation-info {
		padding: 34px 0 45px;
		background: #f5f5f5;
		text-align: center;
		
		.main-tit {
			color: #232323;
			font-size: 26px;
			font-weight: 500;
			letter-spacing: -.05em;
		}

		.period {
			margin-top: 3px;
			text-align: center;

			.point {
				color: #b0895c;
				font-size: 16px;
				font-weight: 400;
			}

			&.dot-bu-list {
				width: 550px;
				margin-left: auto;
				margin-right: auto;
				text-align: left;
			}
		}
		.btn-area { 
			margin-top: 20px;

			.btn { min-width: 160px; }
			.btn + .btn { margin-left: 8px; }
		}
	}
	
	/* 버스 예약마감 안내 */
	.bus-closed-info {
		padding: 16px 0 20px;
		border: 1px solid #ddd;

		@include clearfix;

		> div {
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding: 15px 15px 15px 54px;

			&:first-child { border-right: 1px solid #ddd; }
		}

		.tit {
			margin-bottom: 14px;
			color: #006097;
			font-size: 20px;
			font-weight: 500;
			letter-spacing: -.05em;
		}

		.ref { font-size: 15px; }
	}

	/* 셔틀버스 운영정보 테이블 */
	.bus-time-table {
		.course {
			height: 50px;
			color: #fff;
			font-size: 18px;
			font-weight: 500;
			line-height: 50px;
			text-align: center;

			&.to-pyeongchang { background: #788ea3; }
			&.from-pyeongchang { background: #999; }
		}

		/* 탑승장 위치 링크 */
		td.time {
			a { 
				display: inline-block;
				color: #b0895c;
				font-size: 16px;
				font-weight: 500;
			}
			a:after {
				content: '>';
				display: inline-block;
				margin-left: 3px;
				font-size: 12px;
				vertical-align: 2px;
			}
			
			a + span { margin-left: 20px; }
		}

		/* 타임테이블 2단구성 */
		@include clearfix;

		.group-1 {
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding-right: 15px;
		}
		.group-2 {
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding-left: 15px;
		}
	}

	.bus-info-desc {
		> div {
			padding: 46px 0 50px;
			border-top: 1px solid #ddd;

			&:first-child { border-top: 0; }
			&:last-child { padding-bottom: 0; }

			@include clearfix;
		}
		
		.head-sub {
			float: left;
			margin-left: 10px;
			font-size: 26px;
			letter-spacing: -.05em;
		}

		.cont-wrap {
			margin-left: 255px;

			@include clearfix;

			&.col2 {
				.dot-bu-list { 
					float: left;
					&:first-child { width: 445px; }
				}
			}

			li { letter-spacing: -.025em; }
		}
	}

	/* 매표소 안내 */
	.ticket-box { margin-top: 25px; }

	.tit-round {
		display: inline-block;
		height: 30px;
		margin-bottom: 15px;
		padding: 0 13px;
		border-radius: 15px;
		background: #999;
		color: #fff;
		font-size: 16px;
		font-weight: 300;
		line-height: 30px;
	}

	.etc-bu-list {
		li {
			position: relative;
			padding-left: 17px;
			font-size: 15px;
			font-weight: 300;

			&:not(:first-child) { margin-top: 2px; }

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 7px;
				left: 0;
				width: 9px;
				height: 9px;
				background: url('@@img/guide/pyeongchang/traffic/bu-etc.png') no-repeat left top;
			}
		}
	}

	/* 강원 무료 셔틀버스 */
	.dot-bu-list + .next-content { margin-top: 15px; }
}
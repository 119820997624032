/* 메인페이지 공통 */
.section-main {
	margin-bottom: -120px;

	.visual-banner {
		min-width: $content-width;
		height: 750px;
		position: relative;
		overflow: hidden;

		.bx-wrapper {
			width: 100%;
			height: 100%;
		}

		.bx-viewport {
			width: 100%;
			height: 100%;
			background: #999;
		}

		.slider-list {
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		.slider-item {
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			padding-top: 75px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.link {
			display: block;
			width: $content-width;
			height: 100%;
			margin: 0 auto;
			text-decoration: none;
			background: url('about:blank');
		}

		.txt-wrap {
			width: $content-width;
			margin: 0 auto;
			text-align: center;
			color: #fff;
			position: relative;
			top: 50%;
			transform: translate(0,-50%);
		}

		.main-copy {
			line-height: 1.3;
			font-weight: 500;
			font-size: 60px;
			letter-spacing: 0;
		}

		.sub-copy {
			margin-top: 11px;
			line-height: 1.3;
			font-weight: 300;
			font-size: 20px;
		}

		.bx-controls {
			width: $content-width;
			height: 100%;
			margin-left: -$content-width/2;
			text-align: center;
			font-size: 0;
			position: absolute;
			top: 0;
			left: 50%;
		}

		.bx-prev {
			box-sizing: border-box;
			display: block;
			top: calc(50% - 30px);
			left: -10px;
			z-index: 101;
			@include afterSpriteIR($main-visual-prev,43px,100px,absolute);
		}

		.bx-next {
			box-sizing: border-box;
			display: block;
			top: calc(50% - 30px);
			right: -10px;
			z-index: 101;
			@include afterSpriteIR($main-visual-next,43px,100px,absolute);
		}

		.bx-controls-auto {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: 647px;
			z-index: 102;
		}

		.bx-controls-auto-item {
			display: inline-block;
			vertical-align: middle;
		}

		.bx-start {
			display: block;
			position: relative;
			@include afterSpriteIR($main-visual-play,30px,30px);
		}

		.bx-stop {
			display: block;
			position: relative;
			@include afterSpriteIR($main-visual-pause,30px,30px);
		}

		.bx-pager {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: 647px;
			z-index: 102;
		}

		.bx-pager-item {
			display: inline-block;
			vertical-align: middle;

			&:not(:first-child) { margin-left: 10px; }
		}

		.bx-pager-link {
			display: block;
			width: 10px;
			height: 0;
			max-height: 10px;
			padding-top: 10px;
			background: #fff;
			border-radius: 5px;
			overflow: hidden;
			transition: all .2s;

			&.active { width: 30px; }
		}
	}

	.reservation-form {
		width: $content-width;
		margin: -98px auto -50px;
		position: relative;
		z-index: 101;

		.tab-region {
			height: 48px;
			font-size: 0;
			overflow: hidden;

			a {
				font-size: 20px;
				color: rgba(255,255,255,.7);
				text-decoration: none;
				position: relative;

				&.selected {
					font-weight: 500;
					color: #fff;
				}

				&:not(:first-child) {
					margin-left: 37px;

					&:before {
						content: '';
						display: block;
						width: 1px;
						height: 20px;
						background: rgba(255,255,255,.3);
						position: absolute;
						top: 5px;
						left: -18px;
					}
				}
			}
		}
	}

	.date-search-form {
		box-sizing: border-box;
		height: 100px;
		background: #fff;
		position: relative;
		z-index: 10;

		@include clearfix;

		.select {
			width: 100%;
			font-weight: normal;
			border-color: transparent;
			vertical-align: top;
			font-size: 20px;
			color: #232323;
		}

		.txt {
			width: 100%;
			font-weight: normal;
			border-color: transparent;
			background-color: #fff;
			vertical-align: top;
			font-size: 20px;
			color: #232323;
		}

		.cell {
			box-sizing: border-box;
			float: left;
			width: 245px;
			height: 100px;
			padding: 23px 15px 0;
			position: relative;

			&:not(:first-child) {
				border-left: 1px solid #e8e8e8;
			}

			label {
				display: block;
				padding-left: 15px;
				font-size: 14px;
				color: #a1a1a1;
			}
		}

		.btn-submit {
			box-sizing: border-box;
			display: block;
			width: 220px;
			height: 100px;
			line-height: 100px;
			padding-left: 34px;
			text-align: left;
			font-size: 20px;
			color: #fff;
			background: #af8d5d url('@@img/main/reservation-submit.png') no-repeat 145px 50%;
			position: absolute;
			top: 0;
			right: 0;
		}

		/* 달력 선택 영역 */
		.date-picker-wrap {
			display: none;

			.dim {
				width: 100%;
				height: 100%;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 10;
			}

			> .date-picker {
				position: absolute;
				bottom: 100px;
				right: 220px;
				z-index: 11;
			}

			.ui-datepicker-multi-2 {
				height: 332px;
			}
		}
	}

	.section-title {
		line-height: 1.2;
		text-align: center;
		font-weight: 500;
		font-size: 36px;
		color: inherit;
	}

	.notice-info {
		box-sizing: border-box;
		width: $content-width;
		margin: 0 auto;
		padding: 49px 0 73px;

		@include clearfix;

		.latest {
			float: left;
			box-sizing: border-box;
			width: 390px;
			padding-right: 50px;
			position: relative;

			&:after {
				content: '';
				width: 1px;
				background: #e1e1e1;
				position: absolute;
				top: 5px;
				bottom: 4px;
				right: 0;
			}

			&:not(:first-child) {
				margin-left: 50px;
			}

			.sub-title {
				height: 43px;
				font-weight: 500;
				font-size: 20px;
				color: #232323;
			}
		}

		.list {
			font-weight: 300;
			color: #666;

			li {
				line-height: 26px;
				overflow: hidden;
			}

			a {
				text-decoration: none;

				&:hover,
				&:focus {
					.title { text-decoration: underline; }
				}
			}

			.title {
				float: left;
				max-width: calc(100% - 75px);
				font-size: 15px;

				@include ellipsis;

				&:hover,
				&:focus { text-decoration: underline; }

				&.full { width: 100%; }
			}

			.date {
				float: right;
				width: 75px;
				text-align: right;
				font-size: 14px;
			}
		}

		.btn-more {
			display: block;
			width: 24px;
			height: 0;
			max-height: 24px;
			padding-top: 24px;
			background: url('@@img/main/btn-more.png');
			position: absolute;
			top: 0;
			right: 50px;
			overflow: hidden;
		}

		.cs-center {
			float: right;
			width: 325px;
			position: relative;

			.sub-title {
				height: 43px;
				font-weight: 500;
				font-size: 20px;
				color: #232323;
			}

			.tel {
				line-height: 1;
				font-weight: 500;
				font-size: 28px;
				color: #006097;
				letter-spacing: 0;
				position: absolute;
				top: 0;
				right: 0;
			}

			.info {
				line-height: 26px;
				font-weight: 300;
				color: #665;

				@include clearfix;
				
				dt {
					float: left;
					width: 50%;
					font-size: 15px;
				}

				dd {
					float: right;
					width: 50%;
					text-align: right;
					font-size: 14px;
					letter-spacing: 0;
				}
			}
		}
	}
}
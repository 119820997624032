/* 회원가입 인덱스 - 회원유형 선택 */
.join-index {
	.select-type {
		width: 1000px;
		margin: 0 auto;

		@include clearfix;
	}

	.select-block {
		box-sizing: border-box;
		width: 490px;
		height: 540px;
		padding-top: 245px;
		text-align: center;
		border: 2px solid #f5f5f5;
		position: relative;

		&.is-type1 {
			float: left;
			background: #f5f5f5 url('@@img/join/member-type1.png') no-repeat 50% 76px;

			&:hover {
				background-color: #fff;
				background-image: url('@@img/join/member-type1-on.png');
				border-color: #232323;
			}
		}

		&.is-type2 {
			float: right;
			background: #f5f5f5 url('@@img/join/member-type2.png') no-repeat 50% 76px;

			&:hover {
				background-color: #fff;
				background-image: url('@@img/join/member-type2-on.png');
				border-color: #232323;
			}
		}

		&:hover {
			.btn-primary {
				color: #fff;
				background-color: #232323;
				border-color: #232323;
			}
		}
	}

	.type-title {
		font-weight: 500;
		font-size: 28px;
		color: #232323;
	}

	.type-desc {
		margin-top: 12px;
		line-height: 26px;
		font-size: 16px;
		color: #666;

		li:before {
			content: '';
			display: inline-block;
			width: 3px;
			height: 3px;
			margin-right: 7px;
			vertical-align: middle;
			background: #b4b4b4;
			position: relative;
			top: -2px;
		}
	}

	.btn-area {
		margin-top: 20px;
	}

	.btn-primary {
		box-sizing: border-box;
		display: inline-block;
		height: 60px;
		line-height: 58px;
		padding: 0 30px;
		font-weight: 400;
		font-size: 18px;
		color: #232323;
		text-decoration: none;
		background: transparent;
		border: 1px solid #232323;
		cursor: pointer;
		transition: color .2s, background .2s, border .2s;
	}
}
.customer-missing {
	.info-box {
		background-color: #f5f5f5;
		text-align: center;
		letter-spacing: -.05em;
		margin-bottom: 50px;
		padding: 43px 0 43px;

		.desc-title {
			font-size: 18px;
			color: #232323;
			line-height: 30px;
			margin-bottom: 9px;
		}

		.desc-text {
			font-weight: 300;
			font-size: 15px;
			line-height: 22px;

			span { font-weight: 500; }
		}
	}
	
	.missing-detail {
		position: relative;
		border: 20px solid #f5f5f5;
		padding: 25px 0 70px;
		text-align: center;
		letter-spacing: -.05em;

		.info-data {
			width: 840px;
			margin: 0 auto;
			border: none;
			table-layout: fixed;

			tr:not(:first-child) th,
			tr:not(:first-child) td {
				border-top: 1px solid #ddd;
			}

			th {
				height: 50px;
				text-align: left;
				padding: 0 0 0 33px;
				font-weight: 500;
				font-size: 16px;
				color: #232323;
				border: 0 none;
			}

			td {
				height: 50px;
				text-align: left;
				font-weight: 300;
				font-size: 16px;
				color: #666;
				padding: 0 10px 0 14px;
				border: 0 none;
			}
		}
	}
	
	.missing-tit {
		font-size: 24px;
		color: #232323;
		font-weight: 500;
		line-height: 42px;
		margin: 46px 0 20px;
	}

	.missing-date {
		position: absolute;
		right: 20px;
		top: 24px;
		font-size: 16px;
		line-height: 24px;
	}
	
	.missing-content {
		padding-top: 28px;
		text-align: center;
		word-wrap: break-word;
		width: 840px;
		margin: 0 auto;
	}
}
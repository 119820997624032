/* 연혁 */
.company-history {

	.history-list-wrap {
		padding: 0 100px;

		@include clearfix;

		&:not(:first-child) {
			margin-top: 90px;
		}

		.list-head {
			box-sizing: border-box;
			float: left;
			width: 310px;
			min-height: 60px;
			padding: 12px 0 0 145px;
			font-weight: 500;
			color: #232323;
			font-size: 20px;
		}

		&.seq1 .list-head { background: url('@@koimg/company/history-list1.png') no-repeat; }
		&.seq2 .list-head { background: url('@@koimg/company/history-list2.png') no-repeat; }
		&.seq3 .list-head { background: url('@@koimg/company/history-list3.png') no-repeat; }
	}

	.history-list {
		box-sizing: border-box;
		float: left;
		width: 690px;
		position: relative;

		> li {
			@include clearfix;

			&:not(:first-child) {
				padding-top: 25px;
			}
		}

		&:before {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			background: #d9d8d6;
			position: absolute;
			top: 0;
			left: 0;
		}

		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			background: #d9d8d6;
			position: absolute;
			top: 0;
			left: 100px;
		}
	}

	.history-list-sub {
		box-sizing: border-box;
		float: left;
		width: calc(100% - 100px);

		> li {
			@include clearfix;

			&:not(:first-child) {
				padding-top: 25px;
			}
		}

		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			background: #d9d8d6;
			position: absolute;
			top: 0;
			left: 200px;
		}
	}

	.year {
		float: left;
		width: 100px;
		text-align: center;
		font-weight: 700;
		font-size: 16px;
		color: #232323;
	}

	.month {
		float: left;
		width: 100px;
		padding-top: 2px;
		text-align: center;
		font-size: 15px;
		color: #666;
	}

	.history-con {
		box-sizing: border-box;
		float: left;
		width: calc(100% - 100px);
		padding-left: 40px;
		font-size: 15px;
	}
}
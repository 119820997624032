.customer-tel {
	.tel-list-area {
		border-bottom: 1px solid #999;

		tbody {
			th { text-align: center; background-color: #fff; }
		}

		tbody.group-line {
			th, tr:last-child td { border-bottom: 1px solid #999; }

			&:last-child {
				th, tr:last-child td { border-bottom: none; }
			}
		}

		.tel-num {
			font-weight: 400;
			color: #232323;
			letter-spacing: 0;
		}
	}
}
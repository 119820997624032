/* 변경 */
.login-find-change {

	.table-container {
		width: 700px;
		margin: 0 auto;
	}

	.layout-form {
		margin: 0 160px;
	}

	.form-head {
		width: 165px;
	}

	.check-wrap {
		@include clearfix;

		.txt { float: left; }
		.txt + div { float: right; }
	}

	.foot-info {
		margin-top: 30px;
		text-align: center;

		.txt {
			margin-right: 18px;
			font-weight: 300;
			font-size: 16px;

			&:before {
				content: '';
				display: inline-block;
				margin-right: 9px;
				vertical-align: middle;
				position: relative;
				top: -2px;

				@include sprite($ico-info);
			}
		}
	}
}
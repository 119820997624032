/* 회원전용몰  */
.sellinfo-member-mall {

	.banner-list {
		@include clearfix;

		li {
			float: left;
			width: 380px;
			height: 495px;
			background: #f5f5f5 url('@@img/sellinfo/logo.png') no-repeat 50%;

			&:not(:first-child) { margin-left: 30px; }
		}
	}
	
}
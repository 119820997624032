/* 휘닉스 상품권 */
.story-gift-card {

	/* 기프트카드 소개 */
	.gift-card-intro {
		box-sizing: border-box;
		height: 200px;
		padding: 66px 60px 70px;
		border: 1px solid #ddd;
		background: #fff url('@@img/story/bg-gift-card.png') no-repeat right 60px center;
		
		.title {
			color: #232323;
			font-size: 24px;
			font-weight: 400;
		}

		.desc {
			margin-top: 8px;
			font-size: 16px;
			font-weight: 300;
		}
	}

	/* 전자 상품권 종류 */
	.gift-card-list {
		@include clearfix;

		.item {
			float: left;
			width: calc((100% - 60px) / 3);
			&:not(:first-child) { margin-left: 30px; }
		}

		.info {
			margin-top: 20px;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
		}
	}
	
	/* 서비스 이용약관 */
	.license-content {
		padding: 50px 65px 0;
		border-top: 1px solid #232323;

		.no-l-margin { margin-left: 0; }
	}
	
}
/* 휘닉스 회원 앱 */
.story-app {
	
	.app-intro { border-top: 1px solid #232323; }
	
	.main-copy {
		padding: 54px 0 18px;
		color: #232323;
		font-size: 24px;
		font-weight: 500;
	}

	.desc {
		font-size: 16px;
		font-weight: 300;
		text-align: center;
	}

	.app-list {
		margin-top: 65px;
		font-size: 0;
		text-align: center;
		
		li {
			display: inline-block;
			width: 311px;
			text-align: center;
			vertical-align: top;

			&:first-child { margin-right: 130px; }
		}

		.view {
			width: 311px;
			height: 552px;
			background: #f5f5f5;
		}

		.btn-area { margin-top: 20px; }
	}
}
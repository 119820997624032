/* 예약 완료 */
.reservation-complete {

	.complete-msg {
		margin-bottom: 24px;
		line-height: 1.3;
		text-align: center;
		font-weight: 500;
		font-size: 28px;
		color: #232323;
		letter-spacing: -.05em;

		.desc {
			font-weight: 300;
			font-size: 20px;
			color: #666;
		}
	}

	.foot-state-desc {
		padding: 28px 0;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		border-bottom: 1px solid #ddd;
	}
	
	.foot-desc {
		margin-top: 30px;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		color: #666;

		.btn { margin-left: 15px; }
	}
}
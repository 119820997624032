/* 골프 - 예약 안내 */
.golf-reservation {

	/* 2단 영역 */
	.half-wrap {
		@include clearfix;

		.group-1 { 
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding-right: 15px;
		}
		.group-2 { 
			box-sizing: border-box;
			float: right;
			width: 50%;
			padding-left: 15px;
		}
	}

	.bottom-line {
		padding-bottom: 20px;
		border-bottom: 1px solid #232323;
	}
	
}
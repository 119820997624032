/* 슬로프/리프트 */
.snowpark-slope-lift {

	.top-intro {
		position: relative;
		padding: 80px 0 50px;
		text-align: center;
		

		.head-copy {
			color: #232323;
			font-size: 24px;
			font-weight: 500;

			span { color: #006097; }
		}
		.sub-copy {
			margin-top: 15px;
			font-size: 16px;
			font-weight: 300;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: calc(50% - 32px);
			width: 63px;
			height: 50px;
			background: url('@@img/snowpark/ico-slope-lift.png') no-repeat;
		}
	}

	/* 슬로프 제원 */
	.slope-info-wrap {
		margin-bottom: 80px;
		height: 240px;
		background: url('@@img/snowpark/bg-slope-info.jpg') 50%;
	}

	.slope-info {
		width: $content-width;
		margin: 0 auto;

		@include clearfix;

		.info {
			box-sizing: border-box;
			float: left;
			width: 171px;
			height: 171px;
			margin: 35px 0 0 55px;
			padding-top: 40px;
			border-radius: 100%;
			border: 1px solid rgba(255,255,255,.7);
			background: transparent;
			color: #fff;
			text-align: center;

			&:first-child { 
				margin-left: 67px;
				padding-top: 55px;
				background: #fff;
			}
		}

		.title {
			color: #07304c;
			font-size: 26px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: -.05em;
		}

		.txt {
			font-size: 16px;
			font-weight: 400;
			letter-spacing: -.05em;

			&.row { line-height: 48px; }
		}
		.txt2 {
			font-size: 26px;
			font-weight: 500;
			line-height: 40px;
			
			.num {
				font-size: 36px;
				vertical-align: baseline;
			}
		}
		.txt3 {
			font-size: 28px;
			font-weight: 500;
			letter-spacing: -.05em;
			line-height: 40px;
		}
	}

	/* 슬로프맵 */
	.slope-map-title {
		padding-left: 0;
		font-size: 34px;
		text-align: center;
	}

	.slope-filter {
		margin: 20px 0 28px;
		text-align: center;

		label span {
			padding-right: 0;
			color: #232323;
			font-size: 16px;
		}
		.check + .check { margin-left: 40px; }
	}

	.slope-map {
		position: relative;
		height: 650px;
		background: url('@@img/snowpark/bg-slope-map.jpg') no-repeat left top;
	
		.course {
			position: absolute;
			top: 0;
			left: 0;

			&.beginner { z-index: 1; }
			&.intermediate { z-index: 4; }
			&.advanced { z-index: 3; }
			&.expert { z-index: 2; }
			&.park { z-index: 5; }
			&.olympic { z-index: 6; }
		}
	}
}
/* 이용안내 - 주변 골프장 안내 */
.guide-golf-info {

	.golf-info-list {
		border-top: 1px solid #7a7a7a;

		.item {
			overflow: hidden;
			margin-top: 50px;

			&:not(:first-child) {
				margin-top: 60px;
			}

			&:nth-child(even) {
				overflow: hidden;

				.info { 
					float: left;
					padding-left: 7px;
				}
				.info2 { float: right; }

				.logo {
					float: left;
					width: 143px;
				}

				.guide-data { 
					margin-left: 20px;
				}
			}
		}

		.info {
			width: 400px;
			float: right;
		}

		.info2 { 
			position: relative;
			width: 750px;
			float: left;

			img {
				width: 750px;
				height: 300px;
				vertical-align: top;
			}

		}

		.tit-area {
			overflow: hidden;
			margin-top: 20px;
			
		}

		.logo {
			float: left;
			width: 106px;
		}

		.tit {
			float: left;
			padding-left: 40px;
			font-size: 24px;
			font-weight: 500;
			color: #232323;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 1px;
				height: 100%;
				background: #ddd;
				position: absolute;
				left: 0;
			}

			span {
				display: block;
				font-size: 16px;
				color: #666;
				font-weight: 300;
			}
		}

		.guide-data {
			margin-top: 35px;

			dl {
				&:after {
					content: "";
					display: block;
					clear: both;
				}

				&:not(:first-child) {
					margin-top: 15px;
				}
			}
			dt {
				float: left;
				width: 90px;
				font-size: 16px;
				font-weight: 500;
				padding-left: 10px;
				position: relative;

				&:before {
					content: "";
					display: inline-block;
					width: 3px;
					height: 3px;
					background: #b4b4b4;
					position: absolute;
					left: 0;
					top: 10px;
				}
			}
			dd {
				float: right;
				font-size: 15px;
				font-weight: 300;
				width: calc(100% - 100px);
			}
		}
	}

}
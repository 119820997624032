/* 이벤트 공통 */
.section-event {

	.photo-event-img {
		margin-top: 40px;
		text-align: center;

		.img {
			&:not(:first-child) { margin-top: 20px; }
		}

		img {
			max-width: 100%;
		}
	}
}
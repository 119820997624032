/* 건축가 소개 */
.architect-intro {
	padding: 60px 0;
	background: #f5f5f5;

	.wrap {
		display: table;
		width: $content-width;
		margin: 0 auto;
	}

	.photo {
		display: table-cell;
		width: 262px;
		vertical-align: top;

		img { width: 100%; }
	}

	.con-wrap {
		display: table-cell;
		width: 820px;
		padding-top: 8px;
		padding-left: 70px;
		vertical-align: middle;
	}

	.name {
		margin-bottom: 20px;
		font-weight: 500;
		font-size: 24px;
		color: #232323;

		span {
			font-size: 16px;
			color: #666;
		}
	}

	.desc-top {
		margin: 7px 0 15px;
		line-height: 1.4;
		font-size: 20px;
		font-weight: 300;
		color: #666;

		strong { font-weight: 500; color: #232323; }
	}


	.desc {
		margin: 7px 0;
		line-height: 1.6;
		font-size: 16px;
		color: #232323;
	}

	.desc2 {
		margin: 7px 0;
		line-height: 1.6;
		font-weight: 300;
		font-size: 16px;
	}

	.special {
		box-sizing: border-box;
		width: 750px;
		margin-top: 40px;
		padding: 0 21px;
		border: 1px solid #999;

		.con {
			margin: -1px 0;
			padding: 20px 0;
			text-align: center;
			background: #f5f5f5;
		}

		blockquote {
			font-weight: 300;
			font-size: 18px;
			color: #b0895c;
		}
	}
}
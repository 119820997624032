/* 이용안내 - 날씨안내 */
.guide-weather {

	iframe { vertical-align: top; }

	.tab-page { margin-bottom: 60px; }

	/* 날씨 아이콘 */
	.weather-ico-big { width: 110px; height: 110px; }
	.weather-ico { width: 60px; height: 60px; }
	.ico-wrap {
		padding: 20px 0;
	}

	/* 현재날씨 */
	.current-weather {
		padding: 50px 0;
		background: #f5f5f5;
		text-align: center;
		font-size: 0;
		color: #232323;
		overflow: hidden;

		.main {
			display: inline-block;
			box-sizing: border-box;
			width: 424px;
			height: 173px;
			vertical-align: top;
			white-space: nowrap;
			background: #fff;
			border: 1px solid #ddd;
			box-shadow: inset 0 -1px 0 #fafafa;

			&:after {
				content: '';
				display: inline-block;
				width: 0;
				height: 100%;
				vertical-align: middle;
			}

			.ico {
				display: inline-block;
				margin-right: 20px;
				vertical-align: middle;
			}

			.txt {
				display: inline-block;
				padding-right: 40px;
				line-height: 1.3;
				vertical-align: middle;
				text-align: center;
				font-weight: 300;
				font-size: 20px;
				white-space: normal;

				strong {
					font-weight: 500;
					font-size: 32px;
				}
			}
		}

		.data {
			display: inline-block;
			box-sizing: border-box;
			width: 160px;
			height: 173px;
			margin-left: 10px;
			vertical-align: top;
			font-size: 16px;
			background: #fff;
			border: 1px solid #ddd;

			dt {
				box-sizing: border-box;
				display: block;
				height: 117px;
				padding-top: 70px;
				border-bottom: 1px solid #ddd;
				box-shadow: inset 0 -1px 0 #fafafa;
			}

			dd {
				display: block;
				height: 54px;
				line-height: 54px;
				font-weight: 500;
				box-shadow: inset 0 -1px 0 #fafafa;
			}

			.t1 { background: url('@@img/guide/weather/current-data-1.png') no-repeat 50% 25px; }
			.t2 { background: url('@@img/guide/weather/current-data-2.png') no-repeat 50% 25px; }
			.t3 { background: url('@@img/guide/weather/current-data-3.png') no-repeat 50% 25px; }
			.t4 { background: url('@@img/guide/weather/current-data-4.png') no-repeat 50% 25px; }
		}
	}

	.weather-copyright {
		margin-top: 10px;
		padding: 0 5px;
		font-weight: 300;
		font-size: 15px;
		color: #999;
		overflow: hidden;

		.copy { float: left; }
		.provider { float: right; }
	}
}
/* 영상정보처리기기 운영 관리 방침 */
.license-img-info {

	.license-filter {
		ul {
			li {
				width: calc(100% / 2);

				&:nth-child(2) ~ li { margin-top: 20px; }
			}
		}
	}
}
/* 웨딩 */
.party-wedding {
	
	/* 사진 영역 */
	.photo-wrap {
		height: 500px;
		position: relative;

		&[data-len='1'] {
			.bx-pager-item { width: 0; overflow: hidden; }
			.bx-pager-item + .other-control { margin-left: 0; }
		}
	}

	.contact-reservation {
		padding: 35px 0;
		background: #f6f3ee;

		.wrap {
			width: $content-width;
			margin: auto;

			@include clearfix;
		}

		.contact {
			float: left;
			width: 50%;
			padding: 6px 0 12px;
		}

		.reservation {
			box-sizing: border-box;
			float: right;
			width: 50%;
			padding: 6px 0 12px 50px;
			border-left: 1px solid #ddd;
		}

		.title {
			float: left;
			padding-left: 9px;
			padding-right: 42px;
			font-weight: 500;
			font-size: 22px;
			color: #232323;
			line-height: 25px;
		}

		.desc {
			float: left;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
		}

		.link-action {
			display: block;
			position: relative;
			
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 8px;
				right: 30px;

				@include sprite($room-link);
			}
		}
		
	}

	.detail-content {
		width: $content-width;
		margin: 0 auto;
		padding: 86px 0 0;
	}

	.room-title {
		margin-bottom: 46px;
		text-align: center;

		.title {
			font-size: 32px;
			color: #232323;

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				margin: 17px auto 24px;
				background: #111;
			}
		}

		.desc {
			font-weight: 300;
			font-size: 16px;
		}
	}

	.info-list {
		padding-top: 2px;
		padding-left: 7px;

		li {
			box-sizing: border-box;
			display: table;
			width: 100%;
			padding-left: 18px;
			font-weight: 300;
			font-size: 16px;
			letter-spacing: 0;
			position: relative;
			color: #232323;

			&:not(:first-child) { margin-top: 6px; }

			&:before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				background: #666;
				position: absolute;
				top: 9px;
				left: 4px;
			}
		}

		.head {
			display: table-cell;
			width: 116px;

			&.inline { display: inline-block; }
		}

		.head-desc {
			margin-bottom: 10px;

			strong { 
				display: block;
				width: 100%;
				font-weight: 500;
				color: #b0895c;
			}

			.inline { 
				display: inline-block;
				width: auto;
			}
		}
	}

	.info-img {
		display: table-cell;
		width: 770px;
		height: 380px;
		vertical-align: top;
		background-position: 0 0;
		background-repeat: no-repeat;
	}

	.basic-info {
		box-sizing: border-box;
		display: table;
		width: 100%;
		table-layout: fixed;
		position: relative;

		&:not(:last-child) {
			margin-bottom: 60px;
		}

		.con {
			box-sizing: border-box;
			display: table-cell;
			padding: 50px 0 50px 0;
			vertical-align: middle;
		}
	}

	.info-guide {
		box-sizing: border-box;
		display: table;
		width: 100%;
		table-layout: fixed;
		margin-bottom: 60px;
		position: relative;
		background-position: 0 0;
		background-repeat: no-repeat;

		.con {
			box-sizing: border-box;
			display: table-cell;
			padding: 50px 0 50px 50px;
			vertical-align: middle;
		}
	}

	/* 웨딩 - 제주 */
	.common-basic-info {
		margin-bottom: 60px;
	}

}
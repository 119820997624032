/* 약관 페이지 공통 */
.section-license {

	.top-desc {
		margin-bottom: 47px;
		&:not(:first-child) { margin-top: 47px; }
	}
	
	.head-version-select {
		position: relative;

		select {
			position: absolute;
			top: -6px;
			right: 0;
		}
	}
	
}
/* 골프 - 인트로 */
.golf-intro {
	/* 상단 메인비주얼 */
	.main-photo {
		height: 500px;
	}

	/* 골프 문의,예약 */
	.contact-reservation {
		padding: 35px 0;
		background: #f6f3ee;

		.wrap {
			width: $content-width;
			margin: auto;

			@include clearfix;
		}

		.contact {
			float: left;
			width: 50%;
			padding: 6px 0 12px;

			strong {
				font-size: 18px;
			}
		}

		.reservation {
			box-sizing: border-box;
			float: right;
			width: 50%;
			padding: 6px 0 12px 70px;
			border-left: 1px solid #ddd;
		}

		.title {
			float: left;
			padding-left: 9px;
			padding-right: 42px;
			font-weight: 500;
			font-size: 22px;
			color: #232323;
		}

		.desc {
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
		}

		.link-action {
			display: block;
			position: relative;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 8px;
				right: 0;

				@include sprite($room-link);
			}
		}
	}

	/* 인트로 */
	.main-intro {
		padding: 94px 0 60px;
		text-align: center;

		.main-copy {
			color: #232323;
			font-size: 32px;
			font-weight: 400;
			line-height: 42px;
			
			&:after {
				content: '';
				display: block;
				margin: 20px auto 24px;
				width: 26px;
				height: 2px;
				background: #232323;
				text-align: center;
			}
		}
		.sub-copy {
			font-size: 18px;
			font-weight: 300;
		}

	}

	/* 전체코스 */
	.meter-info {
		display: inline-block;
		color: #232323;
		font-weight: 500;

		span {
			display: block;
			font-size: 32px;
		}
	}

	.course-map-all {
		margin-top: 50px;
		height: 560px;
	}

	.course-info {
		padding: 45px 0 50px;
		background: #f5f5f5;

		@include clearfix;
	
		.info {
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding-left: 85px;

			.title {
				position: relative;
				color: #232323;
				font-size: 20px;
				font-weight: 500;
				line-height: 1;
				
				span { color: #01609a; }
				
				&:before {
					content: '';
					display: inline-block;
					position: absolute;
					top: 3px;
					left: -45px;
					width: 27px;
					height: 2px;
					background: #01609a;
					vertical-align: top;
				}
			}
			
			.desc {
				margin-top: 20px;
				font-size: 16px;
				font-weight: 300;
			}
		}
	}


	/* 마운틴코스, 레이크코스 */
	.course-map {
		position: relative;
		width: 1200px;
		height: 390px;
		background: url('@@img/golf/phoenix-course-map.jpg') no-repeat left top;

		.hole {
			width: 0;
			height: 0;
			position: absolute;

			&:after {
				display: block;
				width: 20px;
				height: 20px;
				line-height: 20px;
				background: #fff;
				border-radius: 100%;
				color: #232323;
				font-size: 12px;
				font-weight: 700;
				text-align: center;
				transform: translate(-50%,-50%);
			}

			&.on:after {
				width: 30px;
				height: 30px;
				line-height: 30px;
				background: #232323;
				color: #fff;
				font-size: 14px;
			}

			@for $i from 1 through 18 {
				&.hole#{$i}:after { content: '#{$i}'; }
			}

			/* 휘닉스C.C - mountain */
			&.hole1 { top: 284px; left: 286px; }
			&.hole2 { top: 190px; left: 450px; }
			&.hole3 { top: 84px; left: 560px; }
			&.hole4 { top: 82px; left: 415px; }
			&.hole5 { top: 155px; left: 230px; }
			&.hole6 { top: 63px; left: 357px; }
			&.hole7 { top: 107px; left: 190px; }
			&.hole8 { top: 260px; left: 112px; }
			&.hole9 { top: 300px; left: 409px; }
	
			/* 휘닉스C.C - lake */
			&.hole10 { top: 255px; left: 763px; }
			&.hole11 { top: 295px; left: 1022px; }
			&.hole12 { top: 357px; left: 1074px; }
			&.hole13 { top: 255px; left: 1042px; }
			&.hole14 { top: 182px; left: 764px; }
			&.hole15 { top: 172px; left: 672px; }
			&.hole16 { top: 122px; left: 830px; }
			&.hole17 { top: 68px; left: 792px; }
			&.hole18 { top: 182px; left: 552px; }
		}

		
	}

	/* Hole 정보 */
	.course-hole-info {

		@include clearfix;
		
		.info-area {
			box-sizing: border-box;
			display: table-cell;
			width: 600px;
			padding-left: 10px;
			padding-top: 100px;
			vertical-align: top;
		}

		.hole-area {
			display: table-cell;
			width: 600px;
			
			img { width: 600px; }
		}

		/* hole 정보 */
		.title {
			color: #006097;
			font-size: 18px;

			span {
				display: block;
				color: #232323;
				font-size: 36px;
				font-weight: 500;
				line-height: 1.2;
			}
		}

		.sub-info {
			margin-top: 20px;
			font-size: 16px;
			color: #232323;
			font-weight: 400;
		}

		.hole-tip {
			margin-top: 26px;
			padding-right: 140px;

			dt {
				color: #232323;
				font-size: 20px;
				font-weight: 500;
			}
			dd {
				margin-top: 14px;
				font-size: 15px;
				font-weight: 300;
				text-align: left;
			}
		}

		.control { 
			margin-top: 36px;
			.btn + .btn { margin-left: 6px; }
		}

		.tee-list {
			margin-top: 40px;

			li {
				font-size: 15px;
				font-weight: 300;
				line-height: 21px;

				&:not(:first-child) { margin-top: 10px; }

				&:before {
					content: '';
					display: inline-block;
					width: 21px;
					height: 21px;
					margin-right: 10px;
					border-radius: 100%;
					vertical-align: top;
					box-sizing: border-box;
				}

				&.gold:before { background: #ffc704; }
				&.blue:before { background: #006097; }
				&.white:before { 
					background: #fff;
					border: 1px solid #ddd;
				}
				&.red:before { background: #dd0101; }
			}
		}
	}




	/* 태기산C.C */
	&.taegisan {

		.course-map {
			position: relative;
			width: 1200px;
			height: 390px;
			background: url('@@img/golf/taegisan-course-map.jpg') no-repeat left top;

			.hole {

				&:after {
					width: 30px;
					height: 30px;
					line-height: 30px;
				}

				&.on:after {
					width: 30px;
					height: 30px;
					line-height: 30px;
					background: #232323;
					color: #fff;
					font-size: 14px;
				}

				&.hole1 { top: 315px; left: 475px; }
				&.hole2 { top: 325px; left: 712px; }
				&.hole3 { top: 266px; left: 752px; }
				&.hole4 { top: 294px; left: 602px; }
				&.hole5 { top: 230px; left: 628px; }
				&.hole6 { top: 185px; left: 628px; }
				&.hole7 { top: 115px; left: 703px; }
				&.hole8 { top: 52px; left: 627px; }
				&.hole9 { top: 165px; left: 470px; }
			}
		}

		.course-hole-info {
			.info-area { padding-top: 80px; }
		
		}
	}

}
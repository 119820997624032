/* 푸드 스토리 */
.story-food {
	
	.photo-story1 {
		height: 900px;
		background: url('@@img/story/bg-food-story1.jpg') no-repeat 50% 50%;
		background-size: cover;
		background-attachment: fixed;
	
		.top-flag { padding-top: 200px; }
	}

	.food-intro {
		padding: 110px 0 130px;

		.typo-main,
		.typo-sub { text-align: center; }
		.typo-sub { margin-top: 40px; }

		.food-info-list {
			margin-top: 50px;

			@include clearfix;
	
			.item {
				float: left;
				width: 400px;
			}
			.thumb {
				width: 400px;
				height: 400px;
			}
			.desc {
				margin-top: 30px;
				font-size: 16px;
				font-weight: 300;
				text-align: center;
			}
		}
	}

	.photo-story2 {
		height: 900px;
		background: url('@@img/story/bg-food-story2.jpg') no-repeat 50% 50%;
		background-size: cover;
		background-attachment: fixed;

		.desc {
			padding-top: 550px;
			padding-left: 505px;
			
			p { color: #fff; }

			.typo-sub { margin: 54px 0 18px; }
		}
	}

}
/* 단체행사 시설 */
.party-facilities {
	
	.inner-list {
		margin: 10px;

		li {
			padding-left: 10px;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				width: 5px;
				height: 1px;
				background: #666;
				position: absolute;
				top: 12px;
				left: 0;
			}
		}
	}
}
/* 객실 */
.reservation-room {

	/* 날짜 선택 전 메시지 */
	.before-search-msg {
		padding: 150px 0;
		text-align: center;
		font-weight: 300;
		font-size: 18px;
		color: #232323;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
	}

	/* 추첨예약안내 */
	.raffle-guide {
		text-align: center;
	}

	/* 추첨예약일정/기준 */
	.raffle-schedule-rule {

		.top-title {
			margin-bottom: 23px;
			line-height: 1.3;
			text-align: center;

			.head {
				font-weight: 300;
				font-size: 24px;
				color: #232323;
			}
			.main {
				font-weight: 500;
				font-size: 40px;
				color: #232323;
				letter-spacing: -.05em;

				span { color: #b0895c; }
			}
		}

		.rule-head {
			margin-top: 50px;
			margin-bottom: 20px;

			.title {
				font-weight: 500;
				font-size: 26px;
				color: #232323;
			}

			.desc {
				margin-top: 14px;
				font-weight: 300;
				font-size: 16px;

				li:not(:first-child) {
					margin-top: 4px;
				}
			}
		}
	}

	/* 추첨 회원 번호 영역 */
	.raffle-member-number {
		margin-bottom: 20px;
		padding: 25px 0;
		text-align: center;
		font-size: 20px;
		background: #f5f5f5;

		.head {
			margin-right: 15px;
			vertical-align: middle;
			font-weight: 500;
			color: #232323;
		}

		.value {
			display: inline-block;
			vertical-align: middle;
			font-weight: 300;

			&:before {
				content: '';
				display: inline-block;
				width: 1px;
				height: 18px;
				margin-right: 15px;
				vertical-align: middle;
				background: #ddd;
				position: relative;
				top: -1px;
			}
		}
	}

	/* 추첨신청 */
	.raffle-apply {

		.foot-desc {
			margin-top: 28px;
			padding-left: 23px;
			text-indent: -19px;
			font-weight: 300;
			font-size: 16px;
			color: #232323;

			li:not(:first-child) {
				margin-top: 10px;
			}

			.num {
				margin-right: 2px;
				font-weight: 500;
			}

			.sub {
				font-size: 15px;
				color: #c1a37f;
			}
		}
	}

	/* 추첨 상단 메시지 */
	.raffle-top-desc {
		padding-bottom: 43px;
		text-align: center;
		font-weight: 300;
		font-size: 28px;
		color: #232323;
		letter-spacing: -.05em;
	}

	/* 추첨신청확인 */
	.raffle-apply-inquiry {

	}

	/* 추첨결과확인 */
	.raffle-result {
		.state-ok {
			color: #dc3434;
		}
	}

}
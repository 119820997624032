/* 분양정보안내  */
.sellinfo-certificate {

	.certificate-list {
		font-size: 0;
	
		.item {
			box-sizing: border-box;
			display: inline-block;
			width: 277px;
			height: 285px;
			background: #fff;
			border: 1px solid #ddd;
			text-align: center;
			vertical-align: middle;
		
			&:not(:nth-child(4n+1)) { margin-left: 30px; }
			&:nth-child(4) ~ .item { margin-top: 30px; }
		}

		.tit {
			position: relative;
			margin-top: 50px;
			height: 146px;
			color: #232323;
			font-size: 18px;
			font-weight: 500;
			line-height: 22px;

			&:after {
				content: '';
				display: block;
				width: 100px;
				height: 100px;
				position: absolute;
				top: 44px;
				left: calc(50% - 50px);
			}
		
			&.ctf1:after { @include sprite($sellinfo-ctf1); }
			&.ctf2:after { @include sprite($sellinfo-ctf2); }
			&.ctf3:after { @include sprite($sellinfo-ctf3); }
			&.ctf4:after { @include sprite($sellinfo-ctf4); }
			&.ctf5:after { @include sprite($sellinfo-ctf5); }
			&.ctf6:after { @include sprite($sellinfo-ctf6); }
			&.ctf7:after { @include sprite($sellinfo-ctf7); }
			&.ctf8:after { @include sprite($sellinfo-ctf8); }
		}

		.btn { width: 120px; }
	}

}
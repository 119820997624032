/* 로그인 공통 */
.section-login {

	/* 상단 카피 영역 */
	.main-copy {
		margin-bottom: 35px;
		padding-top: 17px;
		text-align: center;
		letter-spacing: -.05em;

		.copy {
			line-height: 1.2;
			font-weight: 300;
			font-size: 34px;
			color: #000;
		}

		.desc {
			margin-top: 20px;
			line-height: 30px;
			font-weight: 300;
			font-size: 20px;
			color: #666;
		}
	}

	/* 본문 제목 */
	.head-content {
		margin-top: -7px;
		margin-bottom: 25px;
		text-align: center;
		line-height: 1.2;
		font-weight: 400;
		font-size: 44px;
		color: #000;
		letter-spacing: -.05em;
	}

	.main-box {
		box-sizing: border-box;
		width: 1000px;
		margin: 0 auto;
		padding: 60px 0;
		border-top: 2px solid #232323;
		border-bottom: 1px solid #ddd;
	}

	.msg-area {
		margin-top: -5px;
		margin-bottom: 49px;
		text-align: center;

		.copy {
			line-height: 1.2;
			font-size: 28px;
			color: #232323;
		}

		.desc {
			margin-top: 8px;
			line-height: 30px;
			font-weight: 300;
			font-size: 20px;
			color: #232323;
		}
	}

	/* 휴대폰 인증 안내 */
	.auth-msg {
		text-align: center;
		line-height: 30px;
		font-weight: 300;
		font-size: 20px;

		&:before {
			content: '';
			display: block;
			width: 140px;
			height: 140px;
			margin: 0 auto 14px;
			background: url('@@img/login/auth-phone.png') no-repeat 50% 0;
		}
	}

	/* 인증 방법 선택 */
	.auth-type {
		width: 1000px;
		margin: 70px auto 0;

		@include clearfix;
	}
	/* 인증 방법 선택 */
	.select-block {
		box-sizing: border-box;
		width: 490px;
		height: 420px;
		padding-top: 222px;
		text-align: center;
		border: 2px solid #f5f5f5;
		position: relative;

		&.is-type1 {
			float: left;
			background: #f5f5f5 url('@@img/login/auth-type1.png') no-repeat 50% 60px;

			&:hover {
				background-color: #fff;
				background-image: url('@@img/login/auth-type1-on.png');
				border-color: #232323;
			}
		}

		&.is-type2 {
			float: right;
			background: #f5f5f5 url('@@img/login/auth-type2.png') no-repeat 50% 60px;

			&:hover {
				background-color: #fff;
				background-image: url('@@img/login/auth-type2-on.png');
				border-color: #232323;
			}
		}

		&:hover {
			.btn-primary {
				color: #fff;
				background-color: #232323;
				border-color: #232323;
			}
		}

		.type-desc {
			line-height: 26px;
			font-size: 16px;
			color: #666;

			li:before {
				content: '';
				display: inline-block;
				width: 3px;
				height: 3px;
				margin-right: 7px;
				vertical-align: middle;
				background: #b4b4b4;
				position: relative;
				top: -2px;
			}
		}

		.btn-area {
			margin-top: 20px;
		}

		.btn-primary {
			box-sizing: border-box;
			display: inline-block;
			height: 60px;
			line-height: 58px;
			padding: 0 30px;
			font-weight: 400;
			font-size: 18px;
			color: #232323;
			text-decoration: none;
			background: transparent;
			border: 1px solid #232323;
			cursor: pointer;
			transition: color .2s, background .2s, border .2s;
		}
	}

}
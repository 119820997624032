/* 회원권 사용실적  */
.sellinfo-history {	
	.member-num-form {
		padding: 20px 0;
		border-top: 1px solid #232323;
		text-align: center;

		label {
			display: inline-block;
			margin-right: 15px;
			color: #232323;
			font-size: 18px;
			font-weight: 500;
			line-height: 40px;
		}

		.select { 
			width: 240px;
			vertical-align: top;
		}
	}
}
/* 워터파크 - 워터파크 티켓  */
.waterpark-ticket {
	
	.ticket-area {
		//display: none;
	}

	.tit-season {
		color: #006097;
		font-weight: 500;
	}

	/* 임시 */
	.tab-cont-wrap {
		.tab-cont {
			display: none;
			&.selected { display: block; }
		}
	}

	.box-info {
		box-sizing: border-box;
		padding: 46px 50px 50px;
		background: #f5f5f5;

		.dot-bu-list li:not(:first-child) { margin-top: 20px; }
	
		&.no-bg {
			background: none;
			padding: 0;
			margin-top: 20px;
		}
	}

	.photo-sample-wrap {
		display: table;
		width: 100%;
		border: 1px solid #ddd;
		text-align: center;

		> div {
			display: table-cell;
			box-sizing: border-box;
			width: 50%;
			padding: 40px 0 50px;

			&:first-child { border-right: 1px solid #ddd; }
		}
	}

	.photo-sample {
		.tit {
			display: inline-block;
			margin-bottom: 26px;
			font-size: 24px;
			font-weight: 500;
			text-align: center;
			letter-spacing: -.05em;

			&:before {
				content: '';
				display: inline-block;
				width: 58px;
				height: 44px;
				margin-right: 8px;
				vertical-align: -5px;
			}

			&.case-good { 
				color: #006097;
				&:before { @include sprite($waterpark-good); }
			}
			&.case-bad { 
				color: #d14e40;
				&:before { @include sprite($waterpark-bad); }
			}
		}
		.desc {
			margin-top: 16px;
			font-size: 16px;
			font-weight: 300;
			text-align: center;
		}
		img {
			width: 160px;
			height: 190px;
			font-size: 0;
			vertical-align: top;
		}
		ul {
			width: 370px;
			margin: 0 auto;

			@include clearfix;
			
			li {
				float: left;
				width: 160px;

				&:first-child { margin-right: 50px; }
			}
		}
	}

	/* 이용방법 */
	.use-guide-process {
		box-sizing: border-box;
		width: 100%;
		padding: 60px 0 50px 65px;
		background: #f5f5f5;

		@include clearfix;

		li {
			position: relative;
			float: left;
			width: 234px;
			padding-right: 26px;
			text-align: center;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 70px;
				right: 0;
				width: 26px;
				height: 44px;
				
				@include sprite($waterpark-arrow-right);
			}

			&:last-child:after { display: none; }
		}

		.step {
			color: #006097;
			font-size: 16px;
			font-weight: 500;
			line-height: 1;

			&:after {
				content: '';
				display: block;
				width: 120px;
				height: 120px;
				margin: 12px 0 0 60px;
			}
		}

		.tit {
			padding: 16px 0 6px;
			color: #232323;
			font-size: 18px;
			font-weight: 500;
		}

		.desc {
			font-size: 15px;
			font-weight: 300;
		}

		/* 아이콘 */
		&.season li:nth-child(1) .step:after { @include sprite($waterpark-guide-season1); }
		&.season li:nth-child(2) .step:after { @include sprite($waterpark-guide-season2); }
		&.season li:nth-child(3) .step:after { @include sprite($waterpark-guide-season3); }
		&.season li:nth-child(4) .step:after { @include sprite($waterpark-guide-season4); }
		&.room li:nth-child(1) .step:after { @include sprite($waterpark-guide-room1); }
		&.room li:nth-child(2) .step:after { @include sprite($waterpark-guide-room2); }
		&.room li:nth-child(3) .step:after { @include sprite($waterpark-guide-room3); }
		&.room li:nth-child(4) .step:after { @include sprite($waterpark-guide-room4); }
	}
}
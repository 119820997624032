/* 쿠폰 예약 목록 */
.reservation-coupon-list {

	.title-desc {
		margin-left: 20px;
		font-weight: 300;
		font-size: 15px;
		color: #b0895c;
	}

	.table-form {
		display: table;
		width: 100%;
		table-layout: fixed;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #232323;

		.form-head {
			box-sizing: border-box;
			display: table-cell;
			width: 220px;
			padding: 15px 30px 15px;
			vertical-align: middle;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
			background: #f5f5f5;
			border-right: 1px solid #ddd;
		}

		.form-cell {
			box-sizing: border-box;
			display: table-cell;
			padding: 15px 30px;
			vertical-align: middle;
			background: #fff;
		}
	}

	.coupon-number-form {
		.input-wrap { @include clearfix; }
		.input { float: left; }
		.action { float: right; }
	}

	.btn-list-action {
		width: 100px;
	}

	.coupon-list {
		margin-top: 30px;
		.form-head { vertical-align: top; }
		.form-cell {
			padding: 0;
		}
	}

	.coupon-info {
		@include clearfix;

		.no-item {
			line-height: 60px;
			font-size: 16px;
		}

		> li {
			padding: 0 30px;
			@include clearfix;

			&:not(:first-child) { border-top: 1px solid #ddd; }
		}

		.action {
			float: right;
			padding-top: 10px;
		}
		
		.info {
			float: left;
			line-height: 60px;
			font-weight: 300;
			font-size: 16px;

			> p { float: left; }
		}

		.num {
			width: 150px;
			font-weight: 500;
		}

		.name {
			width: 410px;
			@include ellipsis;
		}
	}
}
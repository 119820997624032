/* 휘닉스 스토리 - 공통 */
.section-story {

	/* 텍스트 */
	.main-copy {
		color: #fff;
		font-size: 32px;
		font-weight: 400;
		text-align: center;
		letter-spacing: -.05em;
	}
	.sub-copy {
		margin-top: 23px;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
	}

	.typo-main {
		color: #232323;
		font-size: 28px;
		font-weight: 400;
		line-height: 1.3;
		letter-spacing: -.05em;
	}
	.typo-sub {
		color: #232323;
		font-size: 20px;
		font-weight: 400;
		letter-spacing: -.05em;
	}
	.typo-sub2 {
		font-size: 16px;
		font-weight: 300;
	}

	.story-content {
		background: #fff;
	}

	.typo-story {
		display: table;
		width: $content-width;
		margin: 0 auto;
		padding: 60px 65px 65px;
		background: #fff;

		.typo-area {
			display: table-cell;
			width: 385px;
			vertical-align: top;
		}
		.desc-area {
			display: table-cell;
			vertical-align: top;
		
			p {
				font-size: 16px;
				font-weight: 300px;
			}
		}
	}

	.top-flag {
		span {
			display: block;
			color: #fff;
			font-size: 18px;
			font-weight: 400;
			line-height: 69px;
			text-align: center;

			&:before,
			&:after {
				content: '';
				display: block;
				width: 40px;
				height: 1px;
				margin: 0 auto;
				background: rgba(255,255,255,.7);
			}
		}

		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 95px;
			margin: 0 auto 10px;
			background: rgba(255,255,255,.7);
		}
	}

	.bottom-flag {
		span {
			display: block;
			color: #232323;
			font-size: 18px;
			font-weight: 400;
			line-height: 69px;
			text-align: center;

			&:before,
			&:after {
				content: '';
				display: block;
				width: 40px;
				height: 1px;
				margin: 0 auto;
				background: #666;
			}
		}

		&:before {
			content: '';
			display: block;
			width: 1px;
			height: 95px;
			margin: 0 auto;
			background: #666;
		}
	}
	
	/* 하단 배너영역 */
	.bottom-banner {
		.typo-main { 
			margin-top: 120px;
			text-align: center;
		}
	}

	/* 하단 배너 리스트 */
	.story-list {
		margin-top: 70px;

		@include clearfix;

		.item {
			float: left;
			width: 370px;
			height: 370px;
			background: #f7f7f7;
			text-align: center;
			vertical-align: top;

			&:not(:nth-child(3n-2)) { margin-left: 45px; }

			&.brand { background: url('@@img/story/banner-brand.jpg') no-repeat left top; }
			&.food { background: url('@@img/story/banner-food.jpg') no-repeat left top; }
			&.pyeongchang { background: url('@@img/story/banner-pyeongchang.jpg') no-repeat left top; }
			&.jeju { background: url('@@img/story/banner-jeju.jpg') no-repeat left top; }
		}

		a {
			display: table-cell;
			width: 370px;
			height: 370px;
			background: rgba(0,0,0,.3);
			vertical-align: middle;
			text-decoration: none;
			transition: all .4s;

			&:after {
				content: '';
				box-sizing: border-box;
				display: inline-block;
				width: 110px;
				height: 0;
				margin-top: 0;
				opacity: 0;
				border: 1px solid rgba(255,255,255,.7);
				background: url('@@img/story/more.png') no-repeat 50%;
				transition: all .2s;
				overflow: hidden;
			}
		}

		a:hover,
		a:focus {
			background: rgba(0,0,0,.7);

			&:after {
				content: '';
				height: 45px;
				margin-top: 13px;
				opacity: 1;
			}
		}

		.title {
			color: #fff;
			font-size: 24px;
			font-weight: 500;
			
			span {
				display: block;
				font-size: 15px;
			}
		}
	}

	
	.main-intro {
		position: relative;
		height: 900px;

		.btn-move { 
			display: block;
			position: absolute;
			bottom: 30px;
			left: 50%;
			width: 40px;
			height: 40px;
			margin-left: -20px;
			background: url('@@img/story/arrow-bottom.png') no-repeat 50%;
			
		}
	}

}
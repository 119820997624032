/* 나의이용내역 - 스노우 파크이용내역 - 시즌권관리 */
.mypage-history-season {

	.info-msg { 
		width: 100%;
		padding: 45px 0 49px;
		border-bottom: 1px solid #ddd;
		color: #232323;
		font-size: 16px;
		font-weight: 300;
		text-align: center;
	}

	.sub-txt {
		font-size: 16px;
		.location { color: #232323; }
	}

	/* 스키 시즌권 */
	.season-pass-wrap {
		padding: 80px 0;
		background: #f5f5f5;
		font-size: 0;
		text-align: center;

		.season-pass {
			display: inline-block;
			position: relative;
			width: 385px;
			height: 599px;
			text-align: center;
			vertical-align: top;
	
			/* 사용자 등록정보 */
			&.profile {
				margin-right: 20px;
				background: url('@@img/mypage/season-pass-profile.jpg') no-repeat left top;

			}
		}

		.photo {
			margin: 94px 0 0 98px;
			box-sizing: border-box;
			width: 190px;
			height: 236px;
			border: 1px solid #ddd;
			overflow: hidden;

			img { max-width: 100%; }
		}
		
		.btn-area {
			position: absolute;
			top: 349px;
			width: 100%;
			text-align: center;

			.btn {
				font-size: 16px;
				font-weight: 500;
			}
		}
		
		.info {
			margin-top: 70px;
			padding: 0 48px 0 66px;
			text-align: left;

			@include clearfix;

			.name,
			.height {
				color: #323232;
				font-size: 30px;
				font-weight: 500;
			}
			.height { float: right; }
		}
	}

	/* 연간이용권 */
	.year-pass-wrap {
		padding: 80px 0;
		background: #f5f5f5;
		font-size: 0;
		text-align: center;

		.year-pass {
			display: inline-block;
			position: relative;
			width: 385px;
			height: 599px;
			text-align: center;
			vertical-align: top;

			/* 사용자 등록정보 */
			&.profile {
				margin-right: 20px;
				background: url('@@img/mypage/year-pass-profile.jpg') no-repeat left top;

			}
		}

		.photo {
			margin: 94px 0 0 98px;
			box-sizing: border-box;
			width: 190px;
			height: 236px;
			border: 1px solid #ddd;
			overflow: hidden;

			img { max-width: 100%; }
		}

		.btn-area {
			position: absolute;
			top: 349px;
			width: 100%;
			text-align: center;

			.btn {
				font-size: 16px;
				font-weight: 500;
			}
		}

		.info {
			margin-top: 70px;
			padding: 0 48px 0 66px;
			text-align: left;

			@include clearfix;

			.name,
			.height {
				color: #323232;
				font-size: 30px;
				font-weight: 500;
			}
			.height { float: right; }
		}
	}

}
/* 객실 예약 변경 신청 */
.pop-room-change {
	&.common-pop-body { width: 1040px; }

	/* 통합예약 본문 콘텐츠 재정의 */
	.section-reservation {
		/* 날짜 선택 검색 form 사이즈 조정 */
		.date-search-form {
			margin-bottom: 0;
			
			.option { width: 190px; }
			.date { width: 212px; }
			.count { width: 145px; }
			.txt { background-position: 150px 50%; }
			.date-picker-wrap > .date-picker { left: 190px; }
		}
	}

	.scroll-wrap {
		height: 522px;
		overflow: scroll;
		overflow-x: hidden;
	}
}

/* 객실 예약 변경 신청 - 결제하기 */
.pop-room-change2 {
	&.common-pop-body { width: 800px; }

	.option-input-list {
		padding: 15px 10px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	/* 결제 수단 옵션 */
	.pay-option {
		display: inline-block;

		@include clearfix;

		> li {
			float: left;
			margin-right: 70px;
		}
	}

	.pay-desc {
		margin-bottom: 10px;
	}

	/* 결제 위약규정 동의 */
	.pay-agree {
		margin-top: 30px;
	}

	.total-price {
		font-weight: 500;
		font-size: 22px;
		color: #d14e40;
	}

	/* 최종 결제 정보 */
	.final-pay-info {
		dl {
			line-height: 49px;
		}
	}
}
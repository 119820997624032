.customer-inquiry {
	.inquiry-box {
		background-color: #f5f5f5;
		padding: 48px 100px;
	}

	.form-head {
		width: 140px;
		font-size: 16px;
	}

	.btn-action { min-width: 180px; }
}
/* 개인정보처리방침 */
.license-privacy {

	/* 약관리스트 */
	.license-filter {
		ul {
			li {
				width: calc(100% / 2);

				&:nth-child(2) ~ li { margin-top: 20px; }
			}
		}
	}
}
/* 제주 메인 */
.main-jeju {
	.date-search-form {
		.btn-submit {
			background-color: #d8671a;
		}
	}

	.quick-link {
		padding: 128px 0 70px;
		color: #232323;
		background: #f5f3ee;

		.list {
			width: $content-width;
			margin: 0 auto;
			@include clearfix;
		}

		li {
			float: left;
			box-sizing: border-box;
			position: relative;
			
			&:nth-child(1) { width: 440px; }
			&:nth-child(2) { width: 390px; }
			&:nth-child(3) { width: 370px; }

			&:not(:last-child) {
				padding-right: 75px;

				&:after {
					content: '';
					display: block;
					width: 1px;
					height: 74px;
					background: #e1e1e1;
					position: absolute;
					top: 7px;
					right: 40px;
				}
			}
		}

		a {
			box-sizing: border-box;
			display: block;
			height: 80px;
			padding: 5px 0 0 93px;
			text-decoration: none;

			&.seq1 { background: url('@@img/main/jeju/quick1.png') no-repeat 9px 50%; }
			&.seq2 { background: url('@@img/main/jeju/quick2.png') no-repeat 9px 50%; }
			&.seq3 { background: url('@@img/main/jeju/quick3.png') no-repeat 9px 50%; }

			&:hover, &:focus {
				.con { color: #000; }
			}
		}

		.title {
			line-height: 1.3;
			font-weight: 500;
			font-size: 20px;
			color: #232323;
		}

		.con {
			margin-top: 8px;
			line-height: 22px;
			font-size: 16px;
			font-weight: 300;
			color: #666;
		}
	}

	.food-info {
		padding: 95px 0 0;
		color: #232323;

		.section-title {
			height: 77px;
		}
	}

	.promotion {
		padding: 95px 0 0;
		color: #232323;

		.section-title {
			height: 77px;
		}

		.label {
			background: #d8671a;
		}
	}

	.wedding-info {
		padding: 95px 0 100px;
		color: #232323;

		.section-title {
			height: 77px;
		}

		.wrap {
			width: $content-width;
			height: 600px;
			margin: 0 auto;
			position: relative;
			overflow: hidden;
		}

		.bg {
			overflow: hidden;

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
			}
		}

		.bg1 {
			width: 600px;
			height: 600px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.bg2 {
			width: 600px;
			height: 300px;
			position: absolute;
			top: 0;
			left: 600px;
		}

		.bg3 {
			width: 300px;
			height: 300px;
			position: absolute;
			top: 300px;
			left: 900px;
		}

		.scroll-check {
			.bg {
				opacity: 0;
				margin-top: 100px;
				transition: all .4s;
				transform: translate3d(0,0,0);
			}
			.bx-wrapper {
				opacity: 0;
				bottom: -100px;
				transition: all .4s;
			}

			&.in {
				.bg { opacity: 1; }
				.bg1 { margin-top: 0; transition: all .4s; }
				.bg2 { margin-top: 0; transition: all .6s .05s; }
				.bg3 { margin-top: 0; transition: all 1.2s .15s; }
				.bx-wrapper {
					opacity: 1;
					bottom: 0;
					transition: all .9s .1s;
				}
			}
		}

		.bg-item {
			&.ready {
				position: relative;
				z-index: 10;
				.bg:after { opacity: 0; }
				.bg1:after { left: 100px; }
				.bg2:after { left: -100px; }
				.bg3:after { top: -100px; }
			}

			&.show {
				.bg:after { opacity: 1; }
				.bg1:after { left: 0; transition: all .7s; }
				.bg2:after { left: 0; transition: all .7s .05s; }
				.bg3:after { top: 0; transition: all .7s .1s; }
			}
		}

		.bg-item:nth-child(1) {
			.bg1:after { background: url('@@img/main/jeju/wedding1-1.jpg'); }
			.bg2:after { background: url('@@img/main/jeju/wedding1-2.jpg'); }
			.bg3:after { background: url('@@img/main/jeju/wedding1-3.jpg'); }
		}

		.bg-item:nth-child(2) {
			.bg1:after { background: url('@@img/main/jeju/wedding2-1.jpg'); }
			.bg2:after { background: url('@@img/main/jeju/wedding2-2.jpg'); }
			.bg3:after { background: url('@@img/main/jeju/wedding2-3.jpg'); }
		}

		.bx-wrapper {
			width: 300px;
			height: 300px;
			background: #f5f3ee;
			position: absolute;
			bottom: 0;
			left: 50%;
			z-index: 100;
		}

		.bx-viewport {
			position: relative;
			overflow: hidden;
		}

		.item {
			padding: 40px 30px 0 30px;
		}

		.copy {
			line-height: 30px;
			font-weight: 500;
			font-size: 22px;
			color: #232323;
		}

		.desc {
			margin-top: 15px;
			line-height: 23px;
			font-weight: 300;
			font-size: 16px;
			color: #666;
		}

		.bx-controls-direction {
			width: 132px;
			height: 66px;
			background: #2e2e2e;
			position: absolute;
			bottom: 0;
			left: 300px;
			overflow: hidden;
			z-index: 100;

			&:after {
				content: '';
				clear: both;
				display: block;
				width: 1px;
				height: 26px;
				background: #6d6d6d;
				position: absolute;
				top: calc(50% - 13px);
				left: 50%;
			}

			a {
				float: left;
				display: block;
				width: 66px;
				height: 0;
				max-width: 66px;
				padding-top: 66px;
				overflow: hidden;

				&.bx-prev {
					background: url('@@img/main/slider-prev.png') no-repeat 50% 50%;
				}

				&.bx-next {
					background: url('@@img/main/slider-next.png') no-repeat 50% 50%;
				}
			}
		}
	}

	.yuminart {
		box-sizing: border-box;
		height: 640px;
		padding: 95px 0 0;
		color: #fff;
		background: #000 url('@@img/main/jeju/bg-yuminart.jpg') no-repeat 50% 0;

		.section-title {
			height: 77px;
		}

		.wrap {
			width: $content-width;
			height: 256px;
			margin: 0 auto;

			@include clearfix;

			.bg {
				float: left;
				width: 216px;
				height: 256px;
				position: relative;
				overflow: hidden;

				&:not(:first-child) { margin-left: 30px; }

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					transform: rotate(.001deg);
					transition: all .4s;
				}

				&:nth-child(1):after { background: url('@@img/main/jeju/yuminart1.jpg'); }
				&:nth-child(2):after { background: url('@@img/main/jeju/yuminart2.jpg'); }
				&:nth-child(3):after { background: url('@@img/main/jeju/yuminart3.jpg'); }
				&:nth-child(4):after { background: url('@@img/main/jeju/yuminart4.jpg'); }
				&:nth-child(5):after { background: url('@@img/main/jeju/yuminart5.jpg'); }

				&:hover:after {
					transform: scale(1.2) rotate(.001deg);
					transition: all 2s;
				}
			}

			&.scroll-check {
				.bg {
					top: 50px;
					opacity: 0;
					transition: all .4s;
				}

				&.in {
					.bg {
						top: 0;
						opacity: 1;
						transition: all .5s;

						&:nth-child(1) { transition-delay: .05s; }
						&:nth-child(2) { transition-delay: .1s; }
						&:nth-child(3) { transition-delay: .15s; }
						&:nth-child(4) { transition-delay: .2s; }
						&:nth-child(5) { transition-delay: .25s; }
					}
				}
			}
		}

		.action {
			margin-top: 60px;
			text-align: center;
		}

		.link {
			box-sizing: border-box;
			display: inline-block;
			width: 135px;
			height: 40px;
			line-height: 38px;
			vertical-align: middle;
			text-align: center;
			font-size: 16px;
			text-decoration: none;
			letter-spacing: 0;
			border: 1px solid #e1e1e1;
			border-radius: 20px;
			transition: all .4s;

			&:hover,
			&:focus {
				color: #232323;
				background: #fff;
			}

			& + .link {
				margin-left: 5px;
			}
		}
	}

	.notice-info {
		.cs-center {
			.tel { color: #cd651f; }
		}
	}
}
/* 스노우 파크 - 시즌권 지문인증 */

.snowpark-auth {
	.notice-msg {
		box-sizing: border-box;
		padding: 54px 0 50px 220px;
		border: 1px solid #ddd;
		background: url('@@img/snowpark/bg-fingerprint.png') no-repeat 50px 50%;

		color: #232323;
		font-size: 18px;
		font-weight: 400;
	}

	.auth-process {
		@include clearfix;

		.item {
			position: relative;
			float: left;
			text-align: center;

			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 16px;
				height: 25px;
				background: url('@@img/snowpark/arrow-right.png') no-repeat left top;
			}
		}

		.guide {
			display: table;
			box-sizing: border-box;
			border: 1px solid #d9d8d6;
		}

		.num {
			display: table-cell;
			vertical-align: middle;
		}
		
		.view {
			display: table-cell;
			vertical-align: middle;
			border-left: 1px dashed #d9d8d6;
		}

		.desc {
			margin-top: 15px;
			font-size: 15px;
			font-weight: 400;
			letter-spacing: -.05em;
		}

		/* 키오스크 */
		&.kiosk {
			.item {
				width: 340px;

				&:after {
					top: 145px;
					left: -50px;
				}

				&:nth-child(3) ~ .item { margin-top: 25px; }
				&:not(:nth-child(3n+1)) { margin-left: 89px; }
				&:nth-child(3n+1):after { display: none; }
			}

			.guide {
				width: 340px;
				height: 340px;
			}
			.num { width: calc(100% - 280px); }
			.view { width: 280px; }
		}

		/* 시즌권 전용 */
		&.season-private {
			.item {
				width: 270px;
				
				&:after {
					top: 101px;
					left: -26px;
				}

				&:not(:first-child) { margin-left: 40px; }
				&:first-child:after { display: none; }
			}
			
			.guide {
				width: 270px;
				height: 240px;
			}
			.num { width: calc(100% - 220px); }
			.view { width: 220px; }
		}
	}
}
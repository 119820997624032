/* 제주의 여름 스토리 */
.story-jeju {
	
	.main-intro {
		height: 900px;
		background: url('@@img/story/bg-jeju-summer.jpg') no-repeat 50% 50%;
		background-size: cover;
		background-attachment: fixed;
	
		.top-flag { padding-top: 200px; }
	}


	.place-story {

		.place1 {
			height: 540px;
			background: url('@@img/story/bg-jeju-place1.jpg') no-repeat 50% 50%;
			// background-size: cover;
			// background-attachment: fixed;
		}
		.place2 {
			height: 540px;
			background: url('@@img/story/bg-jeju-place2.jpg') no-repeat 50% 50%;
			// background-size: cover;
			// background-attachment: fixed;
		}
		.place3 {
			height: 540px;
			background: url('@@img/story/bg-jeju-place3.jpg') no-repeat 50% 50%;
			// background-size: cover;
			// background-attachment: fixed;
		}

		
		.place-title {
			color: #232323;
			font-size: 42px;
			font-weight: 400;
			line-height: 1.2;

			span {
				display: block;
				color: #b0895c;
				font-size: 20px;
				font-weight: 700;
			}
		}
	}

	
}
/* 이용시설별 안내, 메뉴별안내 리스트 */
.eating-guide {
	/* 상단 배너영역 */
	.top-banner-area {
		padding: 55px 0 43px;
		background: #f5f3ee;
		font-size: 0;

		.wrap {
			width: $content-width;
			margin: 0 auto;
		}

		a { display: block; text-decoration: none; }

		.banner-list {
			display: inline-block;
			font-size: 0;
			vertical-align: top;

			.item {
				display: inline-block;
				width: 260px;
				height: 260px;
				vertical-align: top;

				&:not(:first-child) { margin-left: 30px; }
				&:nth-child(2) { margin-top: 100px; }
				&:nth-child(3) { margin-top: 20px; }
			}

			&:after {
				content: '';
				width: 0;
				height: 100%;

			}
		}

		.facilities-info {
			box-sizing: border-box;
			display: inline-block;
			width: 360px;
			height: 360px;
			padding-left: 90px;
			vertical-align: top;
			font-style: 0;
			white-space: nowrap;
			
			&:after {
				content: '';
				display: inline-block;
				width: 0;
				height: 100%;
				vertical-align: middle;
			}

			.con-wrap {
				display: inline-block;
				width: 100%;
				vertical-align: middle;
				white-space: normal;
			}
	
			.title {
				position: relative;
				color: #232323;
				font-size: 24px;
				font-weight: 500;

				&:after {
					content: '';
					width: 28px;
					height: 1px;
					position: absolute;
					top: 50px;
					left: 0;
					background: #666;
				}
			}
			
			.desc {
				margin-top: 30px;
				font-size: 16px;
				font-weight: 300;
			}
		}
	}

	.tab-block-wrap {
		padding: 80px 0 40px;
		
		.tab-block {
			width: $content-width;
			margin: 0 auto;
		}
	}

	/* 시설 리스트 */
	.facilities-list-wrap {
		background: #f5f5f5;
		padding: 50px 0 60px;
	}

	.facilities-list {
		width: $content-width;
		margin: 0 auto;
		font-size: 0;

		.item {
			box-sizing: border-box;
			display: inline-block;
			position: relative;
			width: 380px;
			background: #fff;

			&:not(:nth-child(3n+1)) { margin-left: 30px; }
			&:nth-child(3) ~ .item { margin-top: 30px; }
		
		}

		a {
			display: block;
			box-sizing: border-box;
			height: 170px;
			padding: 20px 20px 0 20px;
			text-decoration: none;
			border: 1px solid transparent;
			
			&:after {
				content: '';
				box-sizing: border-box;
				display: block;
				position: absolute;
				bottom: 10px;
				right: 10px;
				width: 34px;
				height: 34px;
				border: 1px solid #232323;
				background: url('@@img/eating/pyeongchang/plus.png') no-repeat 50%;
			}
		}

		a:hover, a:focus { 
			border: 1px solid #232323;
		
			&:after {
				border: 1px solid transparent;
				background: #232323 url('@@img/eating/pyeongchang/plus-on.png') no-repeat 50%;
			}
		}

		.tit {
			margin-bottom: 14px;
			color: #232323;
			font-size: 20px;
			font-weight: 500;
		}

		.sub-tit {
			display: inline-block;
			width: 80px;
			vertical-align: middle;

			~ span {
				display: inline-block;
				max-width: calc(100% - 90px);
				vertical-align: middle;

				@include ellipsis;
			}
		}

		.dot-bu-list li {
			display: block;
			font-size: 15px;
			&:before { background: #666; }
		}

	}

}
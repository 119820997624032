/* 제주 - 교통안내 - 위치 */
.guide-traffic-map-jeju {

	.map-area {
		width: 1200px;
		height: 520px;
		overflow: hidden;
	}

	.print-area {
		height: 0;
		margin-right: 20px;
		text-align: right;
		position: relative;
		top: -60px;
		z-index: 1;
	}

	.vehicles-area {

		.head-sub {
			margin-bottom: 20px;
		}

		.desc {
			margin-left: 5px;
			padding-left: 10px;
			font-weight: 300;
			font-size: 16px;
			color: #666;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				width: 3px;
				height: 3px;
				background: #666;
				position: absolute;
				left: 0;
				top: 10px;
			}

			strong { 
				color: #232323;
				font-weight: 500;
			}
		}

		.wrap {
			margin: 20px 0 25px;
			padding: 0 0 0 670px;
			border: 8px solid #f5f5f5;
			background: url('@@img/guide/jeju/traffic/navi.jpg') no-repeat 0 115px;
		}

		.list {
			padding: 48px 0;
			font-weight: 300;
			font-size: 16px;

			li {
				padding-left: 32px;
				position: relative;

				&:not(:first-child) { margin-top: 18px; }
			}

			.title { color: #d86719; }
		}

		.num {
			display: inline-block;
			width: 25px;
			height: 25px;
			line-height: 25px;
			text-align: center;
			font-size: 12px;
			color: #fff;
			background: #232323;
			border-radius: 13px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.ref-bu-list {
			margin-left: 15px;
		}
	}

	.table-container {
		td { 
			font-size: 15px;
			color: #666;
		}
	}

	.shuttle-wrap {
		overflow: hidden;

		.item {
			float: left;
			width: 590px;

			&:not(:first-child) {
				float: right;
			}
		}

		.desc { margin-bottom: 18px; }
	}

	.shuttle-desc-wrap {
		background: #f5f5f5;
		margin-top: 60px;
		padding: 50px 0;
	}

	.shuttle-desc-list {
		box-sizing: border-box;
		display: table;
		margin: 0 auto;
		width: $content-width;
		height: 350px;
		padding: 0 0 0 800px;
		background: url('@@img/guide/jeju/traffic/map-shuttle.jpg') no-repeat 0 0;

		ul {
			display: table-cell;
			vertical-align: middle;
		}
		li {
			font-size: 15px;
			margin-bottom: 15px;
			padding-left: 15px;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				width: 3px;
				height: 3px;
				background: #b4b4b4;
				position: absolute;
				left: 0;
				top: 10px;
			}
		}
	}

	.thumb-info-list {
		.con { padding-top: 220px; }

		.desc { 
			font-weight: 500;
			font-size: 22px;
			color: #232323;
		}
	}
}
/* 이메일 추출 방지 정책 */
.license-email {

	.desc {
		margin-bottom: 20px;
	}

	.license-email-area {
		padding: 34px 58px 37px;
		box-sizing: border-box;
		border: 1px solid #ddd;
		line-height: 24px;
		font-size: 15px;
		font-weight: 300;

		.license-tit {
			margin-bottom: 23px;
			font-size: 16px;
			font-weight: 500;
			color: #232323;
		}

		.license-list {
			margin-bottom: 20px;

			li { 
				padding-left: 19px;
				text-indent: -19px;
			 }
		}

		.notice {
			position: relative;
			padding-left: 15px;
			text-indent: -14px;
		}
	}

	
}
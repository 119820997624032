/* 분양정보안내  */
.sellinfo-guide {
	.next-content { margin-top: 25px; }
	
	.box-desc {
		margin-top: 30px;
		padding: 24px 25px 30px;
		border: 1px solid #ddd;
		text-align: center;

		.tit {
			color: #232323;
			font-size: 18px;
			font-weight: 500;
		}
		.desc {
			margin: 12px 0 17px;
			font-size: 16px;
			font-weight: 300;
		}
	}

	/* 수입인지세 */
	.head-sub3 + .head-sub-info {
		margin-top: -30px;
		margin-bottom: 10px;
	}

	/* 서류양식 다운로드 */
	.form-list {
		padding: 50px 50px;
		background: #f5f5f5;
		
		ul {
			width: 100%;
			@include clearfix;
			
			li {
				float: left;
				width: calc((100% - 39px) / 3);
			}
			li:not(:nth-child(3n+1)) { margin-left: 19px; }
			li:nth-child(3) ~ li { margin-top: 20px; }
		}

		.btn { display: block; }
		
		p {
			margin-top: 20px;
			text-align: center;
		}
	}

	.table-container {
		strong { color: #666; }

		.line {
			display: inline-block;
			padding: 0 10px;
			font-size: 10px;
			vertical-align: 3px;
		}

		td.top { vertical-align: top; }

		.table-inner-list {
			.txt { margin-left: 17px; }
			.ref-bu-list {
				margin-top: 10px;
				margin-left: 17px;
			}
		}
	}
}
.customer-faq {
	.faq-list {
		letter-spacing: -.025em;
		border-bottom: 1px solid #dadada;

		> dt {
			position: relative;
			font-size: 16px;
			color: #232323;
			border-top: 1px solid #dadada;

			a {
				display: block;
				padding: 12px 50px 13px 64px;
				text-decoration: none;
			}

			a:after {
				content: '';
				display: inline-block;
				position: absolute;
				top: 18px;
				right: 21px;
				border: none;

				@include sprite($customer-plus);
			}

			&:before {
				content: "";
				position: absolute;
				left: 22px;
				top: 16px;

				@include sprite($customer-question);
			}

			&.active {
				font-weight: 500;
				background-color: #f5f5f5;

				a:after {
					width: 12px;
					height: 0;
					border: 1px solid #232323;
					position: absolute;
					top: 24px;
				}
			}
		}

		> dd {
			display: none;
			font-size: 16px;
			position: relative;
			border-top: 1px solid #dadada;
			padding: 23px 58px 23px 100px;
			word-wrap: break-word;

			&:before {
				content: "";
				position: absolute;
				left: 58px;
				top: 22px;

				@include sprite($customer-answer);
			}
		}
	}
}
/* 프리미엄키즈 */
.snowpark-premium-kids {
	
	/* 스키학교, 강습장 미리보기(VR) */
	.video-area {

		@include clearfix;

		.video-tab {
			box-sizing: border-box;
			float: left;
			width: 350px;
			background: #f7f7f7;
			border: 1px solid #d9d8d6;

			.tab {
				box-sizing: border-box;
				width: 100%;
				height: 160px;

				&:not(:first-child) { border-top: 1px solid #d9d8d6; }

				&.selected { 
					background: #fff url('@@img/snowpark/school/arrow-right2.png') no-repeat right 38px center;
					
					.tit {
						color: #232323;
						&:before { background: #232323; }
					}
				}
			}

			a {
				display: block;
				height: 160px;
				line-height: 160px;
				padding-left: 50px;
				text-decoration: none;
			}

			.tit {
				color: #666;
				font-size: 18px;
				font-weight: 400;

				&:before {
					content: '';
					display: inline-block;
					width: 6px;
					height: 6px;
					margin-right: 8px;
					border-radius: 100%;
					background: #999;
					vertical-align: 2px;
				}
			}
		}

		.video-box {
			float: left;
			width: 850px;
			height: 482px;
			background: #eee;
		}
	}
	
	/* 하단 포토슬라이드 영역 */
	.bottom-photo-area {
		margin-top: 80px;
		margin-bottom: -125px;
		background: #f7f7f7;

		.wrap {
			width: $content-width;
			margin: 0 auto;
			padding: 60px 0 100px;
		}
	}

	.thumb-list2 {
		font-size: 0;
	
		.item {
			display: inline-block;
			width: 275px;
			vertical-align: top;
	
			&:not(:nth-child(3n+1)) { margin-left: 33px; }
		}
				
		.info {
			height: 80px;
			line-height: 1.3;
			background: #fff;
		}

		.desc {
			display: table-cell;
			width: 275px;
			height: 80px;
			font-weight: 300;
			font-size: 16px;
			text-align: center;
			vertical-align: middle;
		}
	}

	.thumb-list-slider-wrap {
		position: relative;

		.bx-controls-direction {
			display: block;
			width: 100%;
			max-width: $content-width;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 100;
			transform: translate(-50%,0);
		}

		.bx-prev {
			display: block;
			width: 44px;
			height: 67px;
			margin-top: -34px;
			top: -47px;
			left: -82px;
			@include afterSpriteIR($ico-photo-prev-inverse,44px,67px,absolute);
		}
	
		.bx-next {
			display: block;
			width: 44px;
			height: 67px;
			margin-top: -34px;
			top: -47px;
			right: -82px;
			@include afterSpriteIR($ico-photo-next-inverse,44px,67px,absolute);
		}
	}
}
/* 객실 선택 달력 */
.reservation-room-calendar {
	padding: 25px 0 0;

	.head {
		width: 345px;
		margin: 0 auto 43px;
		text-align: center;
		position: relative;
	}

	.year-month {
		line-height: 1;
		font-weight: 500;
		font-size: 44px;
		color: #232323;
	}

	.btn-prev {
		display: block;
		position: absolute;
		top: 5px;
		left: 0;

		@include spriteIR($reservation-calendar-prev);
	}

	.btn-next {
		display: block;
		position: absolute;
		top: 5px;
		right: 0;

		@include spriteIR($reservation-calendar-next);
	}

	.type-list {
		margin-bottom: 27px;
		text-align: center;

		dd {
			display: inline-block;
			font-weight: 300;
			font-size: 16px;
			color: #232323;

			&:not(:first-child) { margin-left: 40px; }

			&:before {
				content: '';
				box-sizing: border-box;
				display: inline-block;
				width: 15px;
				height: 15px;
				border-radius: 10px;
				margin-right: 10px;
				vertical-align: middle;
				background: #aaa;
				position: relative;
				top: -2px;
			}

			&.is-possible:before { background: #75c8e0; }
			&.is-wait:before { background: #d14e40; }
			&.is-close:before { background: #efea9d; border: 1px solid #aaa; }
			&.is-end:before { background: #fff; border: 1px solid #aaa; }
			&.is-raffle:before { background: #3fbab9; }
			&.is-tel:before { background: #787ddb; }
		}
	}

	table {
		width: 100%;
		table-layout: fixed;
	}

	th {
		box-sizing: border-box;
		width: calc(100% / 7);
		height: 50px;
		text-align: right;
		padding: 0 15px 0 0;
		font-weight: 400;
		font-size: 16px;
		color: #232323;
		border: 1px solid #ddd;
	}

	td {
		box-sizing: border-box;
		width: calc(100% / 7);
		height: 170px;
		vertical-align: top;
		font-weight: 400;
		font-size: 18px;
		color: #232323;
		background: #fff;
		border: 1px solid #ddd;
	}

	.disabled {
		background: #f5f5f5;
		.day { opacity: .4; }
	}

	.day {
		box-sizing: border-box;
		height: 40px;
		padding: 7px 15px 0 0;
		text-align: right;
	}

	.sun, .holiday {
		color: #d14e40;
		.day { color: #d14e40; }
	}

	.sat {
		color: #0078be;
		.day { color: #0078be; }
	}

	.room-list {
		padding-left: 20px;
	}

	.room-item {
		&:not(:first-child) { margin-top: 4px; }
		a {
			display: block;
			height: 26px;
			line-height: 26px;
			font-size: 14px;
			color: #232323;
			text-decoration: none;

			@include ellipsis;

			&:before {
				content: '';
				box-sizing: border-box;
				display: inline-block;
				width: 15px;
				height: 15px;
				border-radius: 10px;
				margin-right: 7px;
				vertical-align: middle;
				background: #aaa;
				position: relative;
				top: -2px;
			}

			&.is-possible:before { background: #75c8e0; }
			&.is-wait:before { background: #d14e40; }
			&.is-close:before { background: #efea9d; border: 1px solid #aaa; }
			&.is-end:before { background: #fff; border: 1px solid #aaa; }
			&.is-raffle:before { background: #3fbab9; }
			&.is-tel:before { background: #787ddb; }
		}
	}
}

/* 체험휴식 공통 */
.section-experience {

	.text-card-list {
		font-size: 0;

		.item {
			box-sizing: border-box;
			display: inline-block;
			width: 380px;
			height: 225px;
			padding-top: 43px;
			vertical-align: top;
			text-align: center;
			border: 1px solid #777;

			&:not(:nth-child(3n+1)) { margin-left: 30px; }
			&:nth-child(3) ~ .item { margin-top: 40px; }
		}

		.title {
			margin-bottom: 13px;
			padding-bottom: 13px;
			font-weight: 500;
			font-size: 18px;
			color: #01609a;
			position: relative;

			@include ellipsis;

			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 1px;
				background: #666;
				position: absolute;
				bottom: 0;
				left: calc(50% - 10px);
			}
		}

		.con {
			line-height: 23px;
			font-weight: 300;
			font-size: 16px;
		}

		.con2 {
			margin-top: 14px;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
		}
	}

	/* 영업 시간 - 시간 간격 */
	.office-hours {
		strong { 
			display: inline-block;
			width: 100px;
		}
	}

	.text-card-list2 {
		@include clearfix;
		
		.item {
			float: left;
			color: #010101;
			font-size: 18px;
			font-weight: 500px;
			box-sizing: border-box;
			width: 380px;
			height: 145px;
			border: 1px solid #76777b;
			text-align: center;
			

			strong { color: #d25d13; }

			&:not(:nth-child(3n+1)) {
				margin-left: 30px;
			}

			&:nth-child(3) ~ .item {
				margin-top: 10px;
			}
		}

		.item-inner {
			display: table-cell;
			vertical-align: middle;
			width: 380px;
			height: 145px;
		}
	}

}
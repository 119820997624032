/* 메인 썸네일 목록 */
.main-thumb-list {
	width: $content-width;
	margin: 0 auto;
	position: relative;

	.link {
		text-decoration: none;

		&:hover,
		&.focus {
			.img {
				transform: scale(1.1) rotate(3deg);
			}
		}
	}

	.item {
		width: 276px;
	}

	.thumb {
		height: 298px;
		position: relative;
		overflow: hidden;
	}

	.img {
		width: 100%;
		height: 100%;
		vertical-align: top;
		transition: all .4s;
	}

	.label {
		display: block;
		width: 125px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		color: #fff;
		letter-spacing: 0;
		background: #07304c;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.con {
		padding: 27px 9px;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 20px;
		color: #232323;

		@include ellipsis;
	}

	.info {
		height: 44px;
		line-height: 22px;
		margin-top: 10px;
		font-weight: 300;
		font-size: 15px;
		color: #666;
		word-wrap: break-word;
		overflow: hidden;
	}

	.bx-pager {
		font-size: 0;
		position: absolute;
		top: -53px;
		right: 0;
	}

	.bx-pager-item {
		display: inline-block;
		vertical-align: top;

		&:not(:first-child) { margin-left: 10px; }
	}

	.bx-pager-link {
		display: block;
		width: 10px;
		height: 0;
		max-height: 10px;
		padding-top: 10px;
		background: #999;
		border-radius: 5px;
		overflow: hidden;
		transition: all .2s;

		&.active { width: 30px; }
	}
}
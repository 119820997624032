/* 약관동의 */
.join-agree {

	.agree-all {
		width: 1000px;
		margin: 0 auto;
		line-height: 83px;
		text-align: center;
		font-weight: 500;
		font-size: 20px;
		color: #232323;
		border-top: 2px solid #232323;
	}
	
}
/* 패키지 상세 */
.package-detail {

	.package-title { 
		padding: 43px 0 30px;
		border-top: 1px solid #232323;
		color: #232323;
		font-size: 34px;
		font-weight: 500;
		text-align: center;
		line-height: 1;
	}

	.btn-area {
		margin: 50px 0 95px;
		text-align: center;

		.btn-action + .btn-action {
			margin-left: 18px;
		}
	}

	.package-content {
		min-height: 400px;
	}


}
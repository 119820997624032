/* 로그인 폼 */
.login-form {

	/* 로그인 박스 */
	.login-main-form {
		box-sizing: border-box;
		width: 800px;
		margin: 0 auto;
		padding: 74px 131px 56px;
		background: #f5f5f5;
		position: relative;
	}

	.input-row {
		width: calc(100% - 141px);

		@include clearfix;

		+ .input-row { margin-top: 10px; }

		label {
			float: left;
			width: 102px;
			line-height: 48px;
			font-weight: 500;
			font-size: 18px;
			color: #232323;
			letter-spacing: -.05em;
		}
	}

	.input {
		float: right;
		box-sizing: border-box;
		display: block;
		width: calc(100% - 102px);
		height: 48px;
		line-height: 46px;
		padding: 0 20px;
		font-size: 16px;
		color: #555;
		background: #fff;
		border: 1px solid #ddd;
		border-radius: 0;
	}

	.btn-submit {
		box-sizing: border-box;
		display: block;
		width: 131px;
		height: 106px;
		font-weight: 500;
		font-size: 20px;
		color: #fff;
		letter-spacing: -.05em;
		background: #232323;
		border: 0 none;
		position: absolute;
		top: 74px;
		right: 131px;
	}

	.option-row {
		margin-top: 20px;
		padding-left: 102px;
		font-weight: 300;
		font-size: 16px;
	}

	.action {
		box-sizing: border-box;
		width: 800px;
		margin: 40px auto 0;
		padding: 16px 0 23px;
		text-align: center;
		position: relative;

		@include clearfix;

		&:before {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			background: #ccc;
			position: absolute;
			top: 0;
			left: 50%;
		}

		.btn + .btn { margin-left: 7px; }

		.msg {
			margin-bottom: 16px;
			font-weight: 300;
			font-size: 20px;
			color: #232323;
		}
	}

	.find {
		float: left;
		width: 50%;
	}

	.join {
		float: right;
		width: 50%;
	}
}
/* 이벤트 등록 */
.event-write {

	.board-write {
		padding-bottom: 50px;
		border-bottom: 1px solid #232323;
	}

	.event-title-input {
		display: table;
		height: 40px;
		width: 100%;

		.event-name {
			box-sizing: border-box;
			display: table-cell;
			height: 46px;
			padding: 0 35px;
			vertical-align: middle;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
			white-space: nowrap;
			background: #f5f5f5;
			border: 1px solid #ddd;
			border-right: 0;
		}

		.article-subject {
			box-sizing: border-box;
			display: table-cell;
			width: 100%;
		}
	}

	.txt-input {
		margin-top: 50px;

		label { @include hidden; }

		.txt {
			width: 100%;
			height: 200px;
		}
	}

	.multi-file-input {
		margin-top: 50px;
		border-top: 1px solid #eee;

		.layout-form {
			padding: 30px 0 30px 30px;
		}

		.file-result {
			margin-top: 5px;

			@include clearfix;

			.file-name {
				float: left;
				width: calc(100% - 127px);
				height: 40px;
				line-height: 40px;
				font-weight: 300;
				font-size: 16px;

				@include ellipsis;
			}

			.btn-del {
				float: right;
				width: 117px;
			}
		}

		.input-action {
			padding-top: 20px;
			text-align: center;
			border-top: 1px solid #eee;
		}
	}
}
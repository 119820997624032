/* 체험,휴식 - 쇼핑시설 */
.experience-shop {

	/* 허브체험센터 */
	.text-card-list {		
		.item { height: 110px; }
		.title:before { display: none; }
	}

	/* 스키용품점 */
	.common-basic-info {
		.top-info {
			display: table-row;
			width: 100%;

			.title {
				margin: 0 50px;
				padding: 34px 0 18px;
				border-bottom: 1px solid #ddd;
				letter-spacing: -.05em;
				color: #006097;
				font-size: 20px;
				font-weight: 500;
				span {
					font-weight: 300;
				}
			}
		}
	}
	
	.next-content { margin-top: 30px; }

}
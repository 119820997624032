/* 이벤트 목록 */
.event-list {

	.event-filter {
		margin-bottom: 50px;
		padding: 15px 25px;
		background: #f5f5f5;
		font-size: 0;

		.tab {
			display: inline-block;
			margin: 5px;
			vertical-align: top;

			&.selected {
				a {
					color: #fff;
					background: #232323;
					border-color: #232323;
				}
			}
		}

		a {
			box-sizing: border-box;
			display: block;
			height: 40px;
			line-height: 38px;
			padding: 0 30px;
			font-size: 16px;
			color: #666;
			text-decoration: none;
			background: #fff;
			border: 1px solid #ddd;
		}
	}

}
/* 이용약관 */
.com-license {

	/* 약관리스트 */
	.license-filter {
		box-sizing: border-box;
		padding: 45px 0;
		border: 1px solid #ddd;

		color: #232323;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.1;
				
		ul {
			@include clearfix;

			li {
				box-sizing: border-box;
				float: left;
				width: calc(100% / 3);
				padding-left: 65px;

				&:nth-child(3) ~ li { margin-top: 20px; }
			}
		}
	}

	/* 약관내용 */ 
	.license-content {
		padding: 60px 65px 0;
		font-size: 15px;
		font-weight: 300;
		line-height: 24px;
		
		.license:not(:last-child) { margin-bottom: 35px; }

		.license-tit {
			color: #232323;
			font-size: 16px;
			font-weight: 400;
		}

		.license-txt,
		.license-list { margin-left: 35px; }
		.license-list {
			li {
				padding-left: 19px;
				text-indent: -19px;
			}
		}
		.next-content { margin-top: 35px; }

		.table-container {
			margin: 5px 0;
			text-indent: 0;

			th {
				font-size: 13px;
			}

			td {
				font-size: 13px;
			}

			li {
				padding-left: 0;
				text-indent: 0;
			}
		}
	}
}
/* 주변지역 축제/행사 */
.experience-nearby {
	
	.place-info-list {
		.item {
			overflow: hidden;

			&:not(:first-child) {
				margin-top: 85px;
			}

			&:nth-child(even) {
				overflow: hidden;

				.info { float: left; }
				.info2 { float: right; }
			}
		}

		.info {
			width: 400px;
			float: right;
		}

		.info2 { 
			position: relative;
			width: 750px;
			float: left;
		}

		.img {
			width: 750px;
			height: 300px;
			vertical-align: top;
		}

		.tit {
			padding-top: 5px;
			font-size: 24px;
			font-weight: 500;
			color: #232323;
		}

		.con {
			padding: 15px 0;
			font-size: 15px;
			font-weight: 300;

			p, ul {
				&:not(:first-child) {
					margin-top: 10px;
				}
			}
		}

		.bu {
			display: inline-block;
			padding-left: 17px;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 14px;
				height: 14px;
				background: url('@@img/experience/ico-bullet.png') 0 0 no-repeat;
				position: absolute;
				top: 5px;
				left: 0;
			}
		}
		
		.use-info {
			margin-bottom: 20px;
			font-weight: 300;

			p {
				margin-left: 10px;

				strong { font-size: 15px; }

				&:not(:last-child) {
					margin-bottom: 5px;
				}
			}
		}

		.tit-sub {
			position: relative;
			padding-left: 10px;
			margin-bottom: 6px;
			font-size: 16px;
			font-weight: 500;
			color: #232323;

			&:before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				background: #b4b4b4;
				position: absolute;
				left: 0;
				top: 12px;
			}
		}

		.go-site {
			position: absolute;
			top: 320px;
			right: 5px;
		}

	}

	.inner-info-table {
		width: 100%;
		table-layout: fixed;
		line-height: 1.5;
		font-size: 15px;
		border: 0;
		margin-top: 20px;
		
		tr:not(:last-child) th, tr:not(:last-child) td {
			padding-bottom: 5px;
		}

		th {
			padding-left: 39px;
			text-align: left;
			font-weight: 500;
			color: #666;
			border: 0;

			&.ico-phone { background: url('@@img/experience/ico-phone.png') 7px 0 no-repeat; }
			&.ico-address { background: url('@@img/experience/ico-address.png') 9px 3px no-repeat; }
			&.ico-time { background: url('@@img/experience/ico-time.png') 7px 3px no-repeat; }
		}
		
		td {
			border: 0;
			font-weight: 300;

			&:before {
				content: ':';
				display: inline-block;
				padding-right: 10px;
				font-weight: 500;
			}
		}
	}

}
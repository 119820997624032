/* CI & BI */
.company-identity {

	.identity-concept {
		padding-top: 70px;
		//height: 830px;
		background: url('@@img/company/identity-concept.png') no-repeat 50% 80px;

		.con-wrap {
			width: $content-width;
			margin: 0 auto;
		}

		.dec {
			font-size: 16px;
			color: #666;
			line-height: 27px;
			text-align: center;
			margin-bottom: 65px;
			
			&.first {
				padding-top: 562px;
				margin-bottom: 25px;
			}
		}
		
		.dec strong {
			font-size: 22px;
			font-weight: 500;
		}
	}

	 .brand-structure {		
		background: #f7f7f7;

		.con-wrap {
			box-sizing: border-box;
			margin: 0 auto;
			padding-top: 60px;
			height: 670px;
			background: url('@@img/company/identity-structure.png') no-repeat 176px 70px;
			width: $content-width;
		}
	
		.dec {
			margin: 0 0 26px 833px;
			font-size: 16px;
			line-height: 26px;

			&.first {
				padding-top: 160px;
			}
		}
	}

	 .brand-identity {
		@include clearfix;

		.con {
			margin-top: 34px;
			padding-left: 8px;
			font-size: 16px;
			line-height: 25px;
		}
	}

	 .identity-bi {
		float: left;
		width: 370px;

		&:last-child {
			width: 790px;
			margin-left: 40px;
		}

		.tit {
			padding: 0 0 20px 0;
			font-size: 16px;
			color: #232323;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background: #d9d8d6;
				position: absolute;
				top: 14px;
				left: 0;
			}

			span {
				display: inline-block;
				padding: 0 15px 0 8px;
				background: #fff;
				position: relative;
			}
		}

		.bi {
			overflow: hidden;

			span {
				box-sizing: border-box;
				float: left;
				display: block;
				width: 370px;
				height: 160px;
				text-align: center;
				padding-top: 42px;
				border: 1px solid #d9d8d6;

				&:not(:first-child) { float: right; }
			}
		}
 	}

	.brand-service-bi {
		width: $content-width;
		height: 339px;
		border: 1px solid #d9d8d6;
		box-sizing: border-box;

		@include clearfix;

		.brand-service-list {
			float: left;
			width: 50%;
			height: 100%;
			position: relative;

			&:not(:first-child) {
				&:after {
					content: '';
					display: block;
					width: 1px;
					height: 100%;
					background: #d9d8d6;
					position: absolute;
					top: 0;
					right: 600px;
				}

				li {
					padding-left: 140px;
				}
			}

			.tit {
				display: inline-block;
				width: 100%;
				height: 50px;
				text-align: center;
				font-size: 16px;
				border-bottom: 1px solid #d9d8d6;
				line-height: 50px;
			}
			
			li {
				margin-top: 36px;
				padding-left: 100px;
			}
		}
	}

}
/* 체험,휴식 - 관광 곤돌라 */
.experience-gondolra {

	.common-basic-info {
		.desc {
			margin-bottom: 18px;
			font-size: 16px;
			font-weight: 300;
		}
	}

	.dot-bu-list {
		li { display: block; }
	
		&.price {
			color: #232323;
			strong {
				display: inline-block;
				width: 155px;
			}
		}
	}
}
/* 객실 상세 - 콘도/유스호스텔 */
.room-detail-condo {

	.common-top-info {
		background: #f6f3ee;
	}

	/* 객실 기본정보 */
	.room-info-wrap {
		@include clearfix;

		.data-layout-table {
			float: left;
			width: 350px;
			margin-top: 15px;
		}
	}

	/* 평면도 보기 테이블 */
	.viewer-table {
		td {
			vertical-align: top;
		}
	}

	/* 보기버튼 */
	.btn-view {
		display: block;
		width: 170px;
		margin: 0 auto;
		padding: 0;

		&:not(:first-child) {
			margin-top: 5px;
		}
	}

	/* 체크인아웃 시간 */
	.time-con {
		float: right;

		dl {
			float: left;
			width: 130px;
			height: 130px;
			text-align: center;
			background: #f7f7f7;
			border-radius: 65px;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 16px;
				height: 1px;
				background: #b0895c;
				position: absolute;
				top: 55px;
				left: calc(50% - 8px);
			}

			&:first-child:after {
				content: '~';
				width: calc(200% + 34px);
				text-align: center;
				font-weight: 500;
				font-size: 28px;
				color: #232323;
				position: absolute;
				top: 45px;
				left: 0;
			}
		}

		dl + dl { margin-left: 34px; }

		dt {
			box-sizing: border-box;
			display: block;
			height: 56px;
			padding-top: 30px;
			font-size: 15px;
			color: #b0895c;
		}

		dd {
			display: block;
			font-weight: 500;
			font-size: 28px;
			color: #232323;
			letter-spacing: 0;
		}
	}

	/* 하단 정보 */
	.foot-info-title {
		width: $content-width;
		margin-left: auto;
		margin-right: auto;
	}

	.foot-info {
		margin-bottom: -120px;
		padding: 60px 0;
		background: #f5f5f5;

		.wrap {
			width: $content-width;
			display: table;
			table-layout: fixed;
			margin: 0 auto;
		}
	}

	.foot-item {
		box-sizing: border-box;
		display: table-cell;
		width: calc(100% / 6);
		padding-bottom: 15px;
		vertical-align: top;

		.site-pyeongchang & {
			@for $i from 1 through 6 {
				&:nth-child(#{$i}) { background: url('@@img/room/condo-foot#{$i}.png') no-repeat 50% 0; }
			}
		}

		.site-jeju & {
			width: calc(100% / 4);

			@for $i from 1 through 4 {
				&:nth-child(#{$i}) { background: url('@@img/room/jeju/condo-foot#{$i}.png') no-repeat 50% 0; }
			}

			dd {
				padding: 0 50px;
			}
		}

		&:not(:first-child) { border-left: 1px solid #ddd; }
		
		dt {
			display: block;
			height: 60px;
			padding-top: 74px;
			text-align: center;
			font-size: 18px;
			color: #232323;
		}

		dd {
			display: block;
			padding: 0 10px;
			font-weight: 300;
			font-size: 15px;
		}

		li {
			padding-left: 12px;
			text-indent: -12px;

			&:before {
				content: '•';
				display: inline;
				margin-right: 4px;
			}

			&:not(:first-child) { margin-top: 7px; }
		}

		.bu {
			margin-top: 7px;
			padding-left: 17px;
			text-indent: -17px;
		}
	}
}
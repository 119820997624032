/* 통합예약 공통 */
.section-reservation {

	/* 통합 예약 공통 탭메뉴 */
	.tab-reservation {
		min-width: $content-width;
		margin-bottom: 55px;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 60px);
			background: #f5f5f5;
			border-top: 1px solid #999;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.wrap {
			width: $content-width;
			margin: 0 auto;
			position: relative;
		}

		.main {
			height: 60px;

			@include clearfix;

			a {
				display: block;
				text-decoration: none;
			}
		}

		.main-item {
			box-sizing: border-box;
			float: left;
			width: 285px;
			height: 60px;
			line-height: 58px;
			margin-left: -1px;
			text-align: center;
			font-size: 20px;
			color: #999;
			background: #fff;
			border: 1px solid #ddd;
			border-bottom: 1px solid #999;

			&.selected {
				font-weight: 700;
				color: #232323;
				background: #f5f5f5;
				border: 1px solid #999;
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				z-index: 1;
			}
		}

		.sub {
			padding: 40px 0;
			text-align: center;
			font-size: 0;
			white-space: nowrap;

			a {
				box-sizing: border-box;
				display: block;
				height: 140px;
				padding-top: 90px;
				text-decoration: none;
			}
		}

		.sub-item {
			box-sizing: border-box;
			display: inline-block;
			width: 140px;
			height: 140px;
			text-align: center;
			font-size: 18px;
			color: #666;
			background: #fff;
			border-radius: 70px;
			overflow: hidden;

			&:not(:first-child) { margin-left: 40px; }

			&.room { @include sprite($reservation-cate-room-off); }
			&.golf { @include sprite($reservation-cate-golf-off); }
			&.snowpark { @include sprite($reservation-cate-snowpark-off); }
			&.waterpark { @include sprite($reservation-cate-waterpark-off); }
			&.package { @include sprite($reservation-cate-package-off); }
			&.ticket { @include sprite($reservation-cate-ticket-off); }

			&.selected {
				background-color: #b0895c;
				color: #fff;

				&.room { @include sprite($reservation-cate-room-on); }
				&.golf { @include sprite($reservation-cate-golf-on); }
				&.snowpark { @include sprite($reservation-cate-snowpark-on); }
				&.waterpark { @include sprite($reservation-cate-waterpark-on); }
				&.package { @include sprite($reservation-cate-package-on); }
				&.ticket { @include sprite($reservation-cate-ticket-on); }
			}
		}

		.util {
			position: absolute;
			top: 2px;
			right: 0;

			@include clearfix;

			li {
				float: left;
				margin-left: 5px;
			}
		}
	}

	/* 예약 스탭 */
	.step-progress {
		margin-bottom: 60px;
		text-align: center;
		font-size: 0;
		white-space: nowrap;

		li {
			display: inline-block;
			vertical-align: middle;
			font-weight: 300;
			font-size: 20px;

			&:not(:last-child) {
				padding-right: 77px;
				position: relative;

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 5px;
					right: 27px;

					@include sprite($reservation-step-off);
				}
			}

			&.selected {
				font-weight: 500;
				color: #232323;

				&:after {
					@include sprite($reservation-step-on);
				}
			}
		}
	}

	/* 날짜 선택 검색 form */
	.date-search-form {
		box-sizing: border-box;
		height: 80px;
		padding: 1px;
		margin-bottom: 60px;
		position: relative;
		z-index: 10;

		@include clearfix;

		&:before {
			content: '';
			box-sizing: border-box;
			display: block;
			width: 100%;
			height: 100%;
			border: 1px solid #ddd;
			position: absolute;
			top: 0;
			left: 0;
		}

		.select {
			width: 100%;
			font-weight: normal;
			border-color: transparent;
			vertical-align: top;
		}

		.txt {
			width: 100%;
			font-weight: normal;
			border-color: transparent;
			background: #fff url("@@img/com/input-datepicker.png") no-repeat 211px 50%;
			vertical-align: top;
		}

		.cell {
			box-sizing: border-box;
			float: left;
			height: 100%;
			padding: 30px 15px 0;
			position: relative;

			&:not(:first-child):after {
				content: '';
				display: block;
				width: 1px;
				height: 40px;
				margin-top: -20px;
				background: #ddd;
				position: absolute;
				top: 50%;
				left: 0;
			}

			label {
				display: block;
				font-size: 14px;
				color: #a1a1a1;
				position: absolute;
				top: 16px;
				left: 30px;
			}
		}

		.option { width: 260px; }
		.date { width: 264px; }
		.count { width: 190px; }

		.btn-submit {
			width: 220px;
			height: 80px;
			line-height: 80px;
			text-align: center;
			font-size: 20px;
			color: #fff;
			background: #232323;
			position: absolute;
			top: 0;
			right: 0;
		}

		/* 달력 선택 영역 */
		.date-picker-wrap {
			display: none;

			.dim {
				width: 100%;
				height: 100%;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 10;
			}

			> .date-picker {
				box-shadow: 2px 2px 5px 0 rgba(0,0,0,.2);
				position: absolute;
				top: 79px;
				left: 260px;
				z-index: 11;
			}
		}
	}
}
/* 교통안내 - 위치 */
.guide-traffic-map {

	.map-area {
		width: 1200px;
		height: 500px;
		overflow: hidden;
	}

	.print-area {
		height: 0;
		margin-right: 20px;
		text-align: right;
		position: relative;
		top: -60px;
		z-index: 1;
	}

	.car-navi {
		.head-sub {
			margin-bottom: 5px;
		}

		.desc {
			padding-left: 5px;
			font-weight: 300;
			font-size: 16px;
		}

		.wrap {
			min-height: calc(486px + 160px);
			margin-top: 34px;
			padding: 0 0 0 545px;
			border: 8px solid #f5f5f5;
			background: url('@@img/guide/pyeongchang/traffic/navi.jpg') no-repeat 162px 80px;
		}

		.list {
			padding: 90px 0;
			font-weight: 300;
			font-size: 16px;

			li {
				padding-left: 32px;
				position: relative;

				&:not(:first-child) { margin-top: 18px; }
			}

			.title { color: #006097; }
		}

		.num {
			display: inline-block;
			width: 25px;
			height: 25px;
			line-height: 25px;
			text-align: center;
			font-size: 12px;
			color: #fff;
			background: #232323;
			border-radius: 13px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.ktx-info {
		.head-sub span {
			font-weight: 300;
		}
	}

	.ktx-route {
		padding: 50px 0;
		text-align: center;
		background: #f5f5f5;

		.main {
			margin-bottom: 32px;
			font-weight: 500;
			font-size: 24px;
			color: #b0895c;
		}

		.route {
			display: inline-block;
			height: 125px;
			font-size: 0;
			white-space: nowrap;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 2px;
				background: #ddd;
				position: absolute;
				top: 44px;
				left: 0;
			}

			li {
				display: inline-block;
				font-weight: 300;
				font-size: 16px;
				position: relative;

				&:not(:first-child) {
					margin-left: 120px;
				}

				&:after {
					content: '';
					box-sizing: border-box;
					display: block;
					width: 30px;
					height: 30px;
					background: #fff;
					border: 4px solid #999;
					border-radius: 15px;
					position: absolute;
					top: 30px;
					left: -15px;
				}
			}

			span {
				position: absolute;
				top: 75px;
				left: 0;
				transform: translate(-50%,0);
			}

			.start,
			.arrival {
				&:after {
					width: 90px;
					height: 90px;
					background: #666 url('@@img/guide/pyeongchang/traffic/ico-train.png') no-repeat 50% 50%;
					border: 0;
					border-radius: 45px;
					top: 0;
					left: -45px;
				}

				span {
					font-weight: 500;
					font-size: 18px;
					color: #232323;
					top: 94px;
				}
			}
			
			.start:after { background: #666 url('@@img/guide/pyeongchang/traffic/ico-train.png') no-repeat 50% 50%; }
			.arrival:after { background: #006097 url('@@img/guide/pyeongchang/traffic/ico-train.png') no-repeat 50% 50%; }
		}
	}

	.bus-info {
		> div {
			@include clearfix;
		}

		.city {
			margin-top: 40px;
			padding: 36px 0 0;
			border-top: 1px solid #ddd;
		}

		.head-sub {
			box-sizing: border-box;
			float: left;
			width: 155px;
			margin-bottom: 0;
		}

		.con-wrap {
			float: right;
			width: calc(100% - 155px);
			font-size: 16px;
			position: relative;

			&:not(:last-child) { margin-bottom: 20px; }
		}

		h5 {
			margin-bottom: 10px;
			font-weight: 500;
			font-size: 20px;
			color: #232323;
		}

		.btn-area {
			position: absolute;
			top: 39px;
			right: 0;

			.btn { width: 167px; }
		}
	}
}
/* 행사 웨딩 문의 안내 */
.party-contact {

	.accordion-list {
		margin-top: 10px;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
	}

	.accordion-tit {
		font-size: 18px;
		color: #232323;
		background: #fff;
		height: 49px;
		padding: 0 20px;
		line-height: 49px;
		border-top: 1px solid #ddd;

		a {
			display: inline-block;
			width: 100%;
			height: 100%;
			font-weight: 300;
			background: url('@@img/party/pyeongchang/ico-plus.png') no-repeat right 50%;
		}

		&:first-child { border: 0; }

		&.on {
			background: #f5f5f5;

			a { 
				font-weight: 500;
				background: url('@@img/party/pyeongchang/ico-minus.png') no-repeat right 50%;
			}
		}
	}

	.accordion-con {
		border-top: 1px solid #ddd;
		padding: 25px 20px;
		overflow: hidden;

		table {
			width: 100%;
			table-layout: fixed;
			border-spacing: 0;
			border: 0;

			td {
				padding: 5px 15px;
				vertical-align: top;
				border: 0;
			}

			th {
				padding: 5px 15px;
				vertical-align: top;
				background: none;
				border: 0;
				text-align: left;
			}

			.tit {
				font-weight: 500;
				font-size: 16px;
				color: #b0895c;
			}

			.name { 
				font-weight: 500;
				font-size: 16px;
			}
		}
	}

}
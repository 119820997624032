/* 예약상품 정보 */
.reservation-product {

	/* 상품정보 */
	.product-info {
		box-sizing: border-box;
		word-wrap: break-word;

		.main {
			font-size: 36px;
			color: #232323;
		}

		.sub {
			font-weight: 500;
			font-size: 24px;
			color: #b0895c;
		}

		.desc {
			margin-top: 18px;
			line-height: 1.3;
			font-weight: 500;
			font-size: 22px;
			color: #232323;
		}

		.info-list {
			margin-top: 18px;

			> li {
				padding: 0 0 0 13px;
				font-weight: 300;
				font-size: 16px;
				position: relative;

				&:not(:first-child) { margin-top: 4px; }

				&:after {
					content: '';
					display: block;
					width: 3px;
					height: 3px;
					background: #b4b4b4;
					position: absolute;
					top: 11px;
					left: 0;
				}

				ul { margin-top: 5px; }
			}
		}
	}

	/* 상품정보 텍스트 - 이미지 */
	.product-info-container {
		display: table;
		width: 100%;
		margin-bottom: 50px;
		table-layout: fixed;

		.img {
			display: table-cell;
			width: 50%;
			height: 260px;
			vertical-align: top;

			img { width: 100%; }
		}
		
		.product-info {
			display: table-cell;
			width: 50%;
			height: 260px;
			padding: 25px 28px 0 28px;
			vertical-align: middle;
		}
	}

	/* 상품정보 single */
	.product-info-single {
		padding: 53px 60px 53px;
		background: #f5f5f5;
		margin-bottom: 40px;

		.main {
			font-size: 28px;
		}
	}
}
/* 이용안내 - 휘닉스 제주 소개 */
.guide-intro-jeju {

	.top-intro {
		padding-top: 287px;
		background: url('@@img/guide/jeju/intro/top-visual.jpg') no-repeat 50% 0;

		.wrap {
			width: $content-width;
			margin: 0 auto;
			padding: 43px 0 56px;

			@include clearfix;
		}

		.con {
			box-sizing: border-box;
			float: left;
			width: 50%;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
			white-space: nowrap;

			&:not(:first-child) {
				padding-left: 125px;
				border-left: 1px solid #ddd;
			}
		}
	}

	.main {
		box-sizing: border-box;
		width: 640px;
		height: 300px;
		margin: 0 auto;
		padding-top: 53px;
		text-align: center;
		color: #fff;
		background: rgba(216,103,26,.9);
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 30px;
			height: 2px;
			background: #fff;
			position: absolute;
			top: 154px;
			left: calc(50% - 15px);
		}
	}

	.head-copy {
		height: 115px;
		font-size: 32px;
		line-height: 40px;
		letter-spacing: -.05em;
	}

	.sub-copy {
		line-height: 26px;
		font-weight: 300;
		font-size: 16px;
	}

	.con-wrap2 {
		padding: 61px 0 57px;
		background: #f5f5f5;

		.video-area {
			width: $content-width;
			margin: 0 auto 33px;
		}

		.con {
			margin-top: 30px;
			text-align: center;
			line-height: 28px;
			font-size: 18px;
		}
	}

	.con-wrap3 {
		padding: 70px 0 80px;
		
		.main-copy {
			color: #232323;
			font-size: 32px;
			font-weight: 400;
			text-align: center;

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				margin: 18px auto 24px;
				background: #111;
			}
		}

		.con {
			margin-bottom: 48px;
			font-size: 16px;
			font-weight: 300;
			text-align: center;
		}
	}

	.con-wrap4 {
		.main-copy {
			color: #232323;
			font-size: 32px;
			font-weight: 400;
			text-align: center;

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				margin: 18px auto 24px;
				background: #111;
			}
		}

		.architecture-list {
			@include clearfix;

			.item {
				height: 300px;

				&:not(:first-child) { margin-top: 60px; }
	
				&:nth-child(even) {	
					.info { float: left; }
					.info2 { float: right; }
				}
			}
	
			.info {
				float: right;
				width: 400px;
			}
	
			.info2 {
				float: left;
				width: 750px;
			}

			.architect {
				padding-top: 4px;
				color: #232323;
				font-size: 24px;
				font-weight: 500;

				.eng {
					display: inline-block;
					color: #666;
					font-size: 18px;
					font-weight: 400;
				}
			}

			.desc {
				margin: 10px 0 16px;
				font-size: 16px;
				font-weight: 300;
			}

			.btn {
				display: inline-block;
				width: 160px;
			}
			
			.btn + .btn { margin-left: 3px; }
		}
	}

	.service-wrap {
		width: $content-width;
		margin: 0 auto;
		padding: 60px 0 0;
		letter-spacing: -.05em;
		overflow: hidden;
	}

}
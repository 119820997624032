/* 이용안내 - 운영안내 - 현장스케치 */
.guide-sketch {
	.list-head {
		margin-bottom: 20px;
		
		.count { 
			font-size: 16px;
			text-align: right;
		}
	}

	/* 현장 무비 스케치 */
	.movie-filter {
		margin-bottom: 15px;
		padding: 15px 100px 0 100px;
		text-align: center;
		border-top: 1px solid #232323;
		
		/* label {
			margin: 5px 0;

			&:not(:first-child) { margin-left: 60px; }
		
			span {
				color: #999;
				font-weight: 300;
				font-size: 16px;
			}
			
			input:checked + span { 
				color: #232323;
				font-weight: 500;
			}
		} */
	}

	.movie-content {}
}
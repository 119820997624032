/* 분양안내 메인 */
.sellinfo-index {
	margin-bottom: -120px;

	.main-banner {
		width: 100%;
		height: 100%;
		min-height: 700px;
		position: relative;

		.bx-wrapper {
			width: 100%;
			height: 100%;
		}

		.bx-viewport {
			width: 100%;
			height: 100%;
			min-height: 700px;
			background: #999;
		}

		.slider-list {
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		.slider-item {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.bx-pager {
			box-sizing: border-box;
			width: 100%;
			text-align: center;
			font-size: 0;
			white-space: nowrap;
			position: absolute;
			top: calc(45% + 90px);
			left: 0;
			z-index: 100;
		}

		.bx-pager-item {
			display: inline-block;
			vertical-align: top;

			&:not(:first-child) { margin-left: 10px; }

			& + .other-control { margin-left: 20px; }
		}

		.bx-pager-link {
			display: block;
			width: 10px;
			height: 10px;
			background: #fff;
			border-radius: 5px;

			&.active { width: 30px; }
		}

		// line
		&:after {
			content: '';
			display: block;
			width: 1px;
			background: rgba(255,255,255,.7);
			position: absolute;
			top: calc(45% + 230px);
			bottom: 160px;
			left: 50%;
			z-index: 90;
		}
	}

	.scroll-down {
		width: 1px;
		position: absolute;
		top: calc(45% + 160px);
		left: 50%;
		z-index: 90;

		span {
			display: block;
			font-weight: 300;
			font-size: 12px;
			color: #fff;
			letter-spacing: 0;
			white-space: nowrap;
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(-50%,0);

			&:after {
				content: '';
				display: block;
				width: 13px;
				height: 13px;
				background: url('@@img/sellinfo/scroll-down.png') no-repeat;
				position: absolute;
				top: 26px;
				left: calc(50% - 6px);
				animation: arw-balloon 1s ease-in-out infinite;

				@keyframes arw-balloon {
					0%, 100% {
						transform: translate(0,0);
					}
					50% {
						transform: translate(0,5px);
					}
				}
			}
		}
	}

	.bg-full {
		width: 100%;
		height: 100%;
		min-height: 700px;
		background-position: 50% 50%;
		background-size: cover;
		position: fixed;
		top: 0;
		left: 0;

		.dim {
			width: 100%;
			height: 100%;
			opacity: 0;
			background: rgba(12,23,46,.7);
			position: fixed;
			top: 0;
			left: 0;
		}
	}

	.banner-con {
		box-sizing: border-box;
		display: table;
		width: 640px;
		height: 280px;
		border: 6px solid #fff;
		position: absolute;
		// top: 50%;
		top: calc(45% - 140px);
		left: calc(50% - 320px);
		// transform: translate(-50%,-65%);
		z-index: 90;

		.txt-wrap {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			color: #fff;
		}

		.top {
			font-size: 18px;
			padding-bottom: 40px;
			position: relative;

			&:after {
				content: '';
				display: block;
				width: 40px;
				height: 1px;
				background: rgba(255,255,255,.7);
				position: absolute;
				bottom: 24px;
				left: calc(50% - 20px);
			}
		}

		.title {
			padding-bottom: 50px;
			line-height: 1.3;
			font-size: 32px;
		}
	}

	.main-con {
		height: 640px;
		margin-top: -160px;
		position: relative;
		z-index: 90;

		&:after {
			content: '';
			display: block;
			width: 1px;
			height: 125px;
			background: rgba(255,255,255,.7);
			position: absolute;
			top: 71px;
			left: 50%;
		}

		.wrap {
			width: $content-width;
			margin: 0 auto;

			@include clearfix;
		}

		.condo {
			float: left;
			width: calc(50% - 30px);
		}

		.golf {
			float: right;
			width: calc(50% - 30px);
		}
	}

	.sell-title {
		height: 71px;
		line-height: 69px;
		text-align: center;
		font-size: 18px;
		color: #fff;
		letter-spacing: -.075em;
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 40px;
			height: 1px;
			background: rgba(255,255,255,.7);
			position: absolute;
			top: 0;
			left: calc(50% - 20px);
		}

		&:after {
			content: '';
			display: block;
			width: 40px;
			height: 1px;
			background: rgba(255,255,255,.7);
			position: absolute;
			bottom: 0;
			left: calc(50% - 20px);
		}
	}

	.sub-title {
		height: 44px;
		padding-top: 10px;
		text-align: center;

		.txt-condo {
			display: inline-block;
			width: 122px;
			height: 0;
			max-height: 24px;
			padding-top: 24px;
			background: url('@@img/sellinfo/tit-condo.png') no-repeat;
			overflow: hidden;
		}

		.txt-golf {
			display: inline-block;
			width: 94px;
			height: 0;
			max-height: 24px;
			padding-top: 24px;
			background: url('@@img/sellinfo/tit-golf.png') no-repeat;
			overflow: hidden;
		}
	}

	.card {
		float: left;
		display: table;
		width: 280px;
		height: 400px;
		text-align: center;
		color: #fff;
		text-decoration: none;
		background-color: #333;
		background-size: cover;
		position: relative;

		&.ready {
			opacity: 0;
			top: 50px;
			transition: all .4s;

			&.on {
				opacity: 1;
				top: 0;
				transition: all 1s;

				&.seq1 { transition-delay: 0; }
				&.seq2 { transition-delay: .4s; }
				&.seq3 { transition-delay: .6s; }
				&.seq4 { transition-delay: .2s; }
			}
		}

		&.seq1 {
			background-image: url('@@img/sellinfo/index-sell-1.jpg');
		}
		&.seq2 {
			margin: 40px 0 0 10px;
			background-image: url('@@img/sellinfo/index-sell-2.jpg');
			
		}
		&.seq3 {
			margin: 73px 0 0;
			background-image: url('@@img/sellinfo/index-sell-3.jpg');
		}
		&.seq4 {
			margin: 13px 0 0 10px;
			background-image: url('@@img/sellinfo/index-sell-4.jpg');
		}

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: rgba(35,35,35,.2);
			position: absolute;
			top: 0;
			left: 0;
			transition: all .4s;
		}

		.con {
			box-sizing: border-box;
			display: table-cell;
			padding-top: 100px;
			vertical-align: middle;
			position: relative;
			z-index: 1;
			transition: all .4s;
		}

		.title {
			font-size: 22px;
			letter-spacing: 0;
		}

		.desc {
			margin-top: 10px;
			line-height: 22px;
			font-weight: 300;
			font-size: 14px;
			letter-spacing: -.05em;
			opacity: 0;
			transition: all .4s;
		}

		.detail {
			box-sizing: border-box;
			display: block;
			width: 110px;
			height: 40px;
			line-height: 38px;
			margin: 17px auto 0;
			text-align: center;
			font-size: 16px;
			letter-spacing: -.025em;
			border: 1px solid #fff;
			border-radius: 20px;
			opacity: 0;
			transition: all .4s;
		}

		&:hover {
			&:before { background: rgba(171,126,74,.9); }
			.con { padding-top: 0; }
			.desc { opacity: 1; }
			.detail { opacity: 1; }
		}
	}

	.main-foot {
		height: 140px;
		background: #f5f5f5;
		position: relative;
		z-index: 90;

		.wrap {
			width: $content-width;
			margin: 0 auto;
			overflow: hidden;
		}
	}

	.contact {
		float: left;
		padding-top: 26px;

		dt {
			font-weight: 700;
			font-size: 18px;
			color: #232323;
		}

		.tel {
			line-height: 1.1;
			font-weight: 700;
			font-size: 30px;
			color: #b0895c;
		}

		.info {
			margin-top: 5px;
			font-weight: 300;
			font-size: 15px;
			color: #666;
		}
	}

	.link-list {
		float: right;
		margin-right: -30px;
		padding-top: 28px;
		overflow: hidden;

		li {
			float: left;
			width: 145px;
			text-align: center;
			font-size: 16px;
			
			&:nth-child(1) { background: url('@@img/sellinfo/service-1.png') no-repeat 50% 0; }
			&:nth-child(2) { background: url('@@img/sellinfo/service-2.png') no-repeat 50% 0; }
			&:nth-child(3) { background: url('@@img/sellinfo/service-3.png') no-repeat 50% 0; }
			&:nth-child(4) { background: url('@@img/sellinfo/service-4.png') no-repeat 50% 0; }
			&:nth-child(5) { background: url('@@img/sellinfo/service-5.png') no-repeat 50% 0; }
			&:nth-child(6) { background: url('@@img/sellinfo/service-6.png') no-repeat 50% 0; }
			&:nth-child(7) { background: url('@@img/sellinfo/service-7.png') no-repeat 50% 0; }
		}

		a {
			display: block;
			width: 120px;
			margin: 0 auto;
			padding-top: 59px;
		}
	}
}
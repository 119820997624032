/* 가족/지정회원 회원변경 */
.pop-myinfo-family {
	&.common-pop-body { width: 600px; }

	.info-desc {
		margin-top: 15px;
		font-weight: 300;
		font-size: 15px;
		color: #232323;
	}

	.family-option {
		@include clearfix;
		
		li {
			float: left;
			&:not(:first-child) { margin-left: 30px; }
		}
	}
}
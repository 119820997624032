/* 휘닉스 이야기 */
.company-phoenix-story {

	.top-intro {
		padding-top: 372px;
		background: url('@@img/company/story-top_visual.jpg') no-repeat 50% 0;

		.wrap {
			width: $content-width;
			margin: 0 auto;
			@include clearfix;

			.con-img { 
				margin-bottom: 10px;
			}
		}
	}

	.main {
		box-sizing: border-box;
		width: 450px;
		height: 400px;
		margin-left: 750px;
		padding: 95px 0 0 50px;
		text-align: left;
		color: #fff;
		background: rgba(31,68,94,.9);
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 30px;
			height: 1px;
			background: #fff;
			position: absolute;
			top: 207px;
			left: calc(0 - 15px);
		}

		.head-copy {
			height: 130px;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: -.05em;
		}
	
		.sub-copy {
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
		}
	}

	.con-wrap {
		margin-top: -272px;
		padding: 57px 0 383px 50px;
		min-height: 326px;
		text-align: left;
		background: url('@@img/company/story-middle_visual.jpg') no-repeat 50% 383px;

		.con {
			font-size: 16px;
		}
		.con:first-child {
			margin-bottom: 30px;
		}
		.con2 {
			font-size: 24px;
			color: #000;
			margin-top: 30px;
			line-height: 34px;
		}
	}

	.video-area {
		overflow: hidden;
	}
	
	.video-tab {
		box-sizing: border-box;
		float: left;
		width: 260px;
		height: 500px;
		background: #f7f7f7;
		border: 1px solid #d9d8d6;

		.tab {
			box-sizing: border-box;
			height: 166px;

			&:not(:first-child) {
				border-top: 1px solid #d9d8d6;
			}

			&.selected {
				background: #fff url('@@img/company/story-ico1.png') no-repeat 216px 50%;

				a {
					display: table-cell;
					width: 285px;
					height: 165px;
					padding: 0 0 0 37px;
					font-size: 18px;
					font-weight: 500;
					color: #232323;
					text-align: left;
					vertical-align: middle;
					line-height: 24px;

					span {
						display: inline-block;
						width: 100%;
						font-size: 12px;
					}
				}
			}
		}

		a {
			display: table-cell;
			width: 285px;
			height: 165px;
			font-size: 18px;
			font-weight: 500;
			color: #8d8d8d;
			text-align: center;
			text-decoration: none;
			vertical-align: middle;
			line-height: 24px;

			span {
				display: inline-block;
				width: 100%;
				font-size: 12px;
			}
		}
	}

	.video-box {
		width: 940px;
		height: 500px;
		float: left;
		background: #eee;
	}
}
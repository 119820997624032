/* 메인 썸네일 목록2 */
.main-thumb-list2 {
	width: $content-width;
	margin: 0 auto;
	position: relative;

	.link {
		text-decoration: none;

		&:hover,
		&.focus {
			.img {
				transform: scale(1.2) rotate(.001deg);
				transition: all 2s;
			}
		}
	}

	.item {
		width: 400px;
		height: 460px;
		position: relative;
	}

	.thumb {
		height: 100%;
		background: #000;
		position: relative;
		overflow: hidden;
	}

	.img {
		width: 100%;
		height: 100%;
		vertical-align: top;
		transform: rotate(.001deg);
		transition: all .4s;
	}

	.con {
		box-sizing: border-box;
		display: block;
		width: 100%;
		height: 130px;
		padding: 29px 30px 0;
		background: rgba(35,35,35,.7);
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.title {
		line-height: 1.2;
		font-weight: 500;
		font-size: 20px;
		color: #fff;

		@include ellipsis;
	}

	.info {
		height: 46px;
		line-height: 23px;
		margin-top: 10px;
		font-weight: 300;
		font-size: 15px;
		color: #fff;
		word-wrap: break-word;
		overflow: hidden;
	}

	.bx-pager {
		font-size: 0;
		position: absolute;
		top: -53px;
		right: 0;
	}

	.bx-pager-item {
		display: inline-block;
		vertical-align: top;

		&:not(:first-child) { margin-left: 10px; }
	}

	.bx-pager-link {
		display: block;
		width: 10px;
		height: 0;
		max-height: 10px;
		padding-top: 10px;
		background: #999;
		border-radius: 5px;
		overflow: hidden;
		transition: all .2s;

		&.active { width: 30px; }
	}
}
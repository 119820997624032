/* 나의 이용내역 */
.mypage-history {
	
	/* 시즌권 관리, 연간이용권 관리 */
	.no-item {
		padding: 190px 0;
		border-bottom: 1px solid #d4d4d4;
		font-size: 18px;
		font-weight: 300;
		text-align: center;
	}
	
	.box-state-desc {
		.main-desc {
			font-size: 26px;
			font-weight: 500;
		}
	}

	/* 사용등록 승인대기 */
	.standby { color: #d24e3f; font-weight: 500; }

	/* 골프회원권 위약내역 */
	.info-box {
		padding: 30px;
		border: 1px solid #ddd;

		.desc { font-size: 16px; }
		.table-container { margin-top: 20px; }
	}

	/* 시즌권 이용내역 */
	.season-history-search {
		margin: 20px 0 30px;
	}

}
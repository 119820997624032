/* 체험,휴식 - 수영,사우나 */
.experience-pool-sauna {

	.table-cell-area {
		h5 { 
			padding: 0 0 15px 5px;
			margin-bottom: 20px;
			border-bottom: 1px solid #ddd;
			font-size: 20px;
			color: #000;
			font-weight: 500;
		}
	}

	.table-container {
		tbody th {
			background: #fcfcfc;
		}
	}

	.inner-list {
		li { 
			position: relative;
			padding-left: 9px;

			&:before {
				content: '';
				display: inline-block;
				width: 2px;
				height: 2px;
				background: #707070;
				position: absolute;
				left: 0;
				top: 11px;
			}
		}
	}
}
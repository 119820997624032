/* 나의 예약/구매 상세 */
.mypage-reservation-detail {

	.head-state-desc {
		padding: 50px 0 40px;
		border-top: 1px solid #232323;
		text-align: center;
		line-height: 1;
		letter-spacing: -.05em;

		.complete-msg {
			color: $main-color;
			font-size: 28px;
			font-weight: 300;
		}

		.desc {
			margin-top: 10px;
			font-size: 16px;
			font-weight: 300;

			strong { color: #333; }
		}
	}

	/* 시즌권 구매취소 표시 */
	.line-through { 
		text-decoration: line-through;
		margin-right: 15px;
	}

	/* 최종 결제 정보 */
	.final-pay-info {
		dl {
			padding: 0 20px;
			font-size: 16px;
			line-height: 49px;
		}
	}

	/* 위약규정, 시즌권 취소/환불규정  */
	.rule-info {
		margin-top: 14px;
		color: #232323;
		font-size: 16px;
		text-align: left;
	}

	.foot-state-desc {
		padding: 28px 0;
		text-align: center;
		font-weight: 300;
		font-size: 16px;
		border-bottom: 1px solid #ddd;

		.complete-msg {
			color: $main-color;
			font-size: 28px;
			font-weight: 300;
		}
	}

	.foot-guide-list {
		margin-top: 15px;

		ul { padding-left: 25px; }
		li {
			position: relative;
			padding-left: 10px;
			font-size: 16px;
			font-weight: 300;
			
			&:not(:first-child) { margin-top: 3px; }

			&:before {
				content: '';
				display: block;
				width: 5px;
				height: 1px;
				background: #747474;
				position: absolute;
				top: 13px;
				left: 0;
			}
		}
	}

}
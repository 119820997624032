/* 마이페이지 공통 */
.section-mypage {

	/* 상태메시지 박스형 */
	.box-state-desc {
		width: 100%;
		padding: 60px 0;
		background: #f5f5f5;
		font-weight: 300;
		text-align: center;

		.main-desc {
			color: #232323;
			font-size: 28px;
		}
		.sub-desc { 
			margin-top: 10px;
			font-size: 18px;
		}
	}

	.common-foot-desc {
		.desc { 
			margin-right: 10px;
			font-size: 15px;
		}
	}

}
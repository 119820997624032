/* HNR 메인 */
.main-hnr {
	.promotion {
		padding: 145px 0 70px;
		color: #232323;
		background: #f7f7f7;

		.section-title {
			height: 77px;
		}
	}

	.brand-story {
		padding: 95px 0 105px;
		color: #fff;
		background: #002d4e;

		.section-title {
			height: 96px;
		}

		.wrap {
			display: table;
			width: $content-width;
			margin: 0 auto;
		}

		.bg {
			display: table-cell;
			width: 600px;
			height: 350px;
			position: relative;
			overflow: hidden;

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background: url('@@img/main/hnr/brand-story.jpg') no-repeat;
				transform: rotate(.001deg);
				transition: all .4s;
			}

			&:hover {
				&:after {
					transform: scale(1.1) rotate(.001deg);
					transition: all 2s;
				}
			}

			&.scroll-check {
				top: 50px;
				opacity: 0;
				transition: all .4s;

				&.in {
					top: 0;
					opacity: 1;
					transition: all 1s .2s;
				}
			}
		}

		.con {
			display: table-cell;
			vertical-align: middle;
			position: relative;

			&.scroll-check {
				top: 50px;
				opacity: 0;
				transition: all .4s;
				transform: translate3d(0,0,0);

				&.in {
					top: 0;
					opacity: 1;
					transition: all 1s;
				}
			}
		}

		.copy {
			line-height: 38px;
			font-weight: 500;
			font-size: 28px;
		}

		.desc {
			margin-top: 18px;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
		}

		.action {
			margin-top: 36px;
		}

		.link {
			box-sizing: border-box;
			display: inline-block;
			width: 135px;
			height: 40px;
			line-height: 38px;
			vertical-align: middle;
			text-align: center;
			font-size: 16px;
			text-decoration: none;
			letter-spacing: 0;
			border: 1px solid #e1e1e1;
			border-radius: 20px;
			transition: all .4s;

			&:hover,
			&:focus {
				color: #232323;
				background: #fff;
			}
		}
	}

	.food-story {
		padding: 95px 0 105px;
		color: #232323;
		background: #f5f3ee;

		.section-title {
			height: 96px;
		}

		.wrap {
			display: table;
			width: $content-width;
			margin: 0 auto;
		}

		.bg {
			display: table-cell;
			width: 600px;
			height: 390px;
			background-size: contain;
			position: relative;
			overflow: hidden;

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background: url('@@img/main/hnr/food-story.jpg') no-repeat;
				transform: rotate(.001deg);
				transition: all .4s;
			}

			&:hover {
				&:after {
					transform: scale(1.1) rotate(.001deg);
					transition: all 2s;
				}
			}

			&.scroll-check {
				top: 50px;
				opacity: 0;
				transition: all .4s;
				transform: translate3d(0,0,0);

				&.in {
					top: 0;
					opacity: 1;
					transition: all 1s;
				}
			}
		}

		.con {
			display: table-cell;
			width: 544px;
			padding-left: 56px;
			vertical-align: middle;
			text-align: center;
			position: relative;

			&.scroll-check {
				top: 50px;
				opacity: 0;
				transition: all .4s;

				&.in {
					top: 0;
					opacity: 1;
					transition: all 1s .2s;
				}
			}
		}

		.copy {
			line-height: 38px;
			font-weight: 500;
			font-size: 28px;
		}

		.desc {
			margin-top: 12px;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
			color: #666;
		}

		.bg2 {
			margin-top: 33px;
			font-size: 0;

			div {
				display: inline-block;
				width: 168px;
				height: 168px;
				vertical-align: top;
				background-size: cover;

				&:not(:first-child) { margin-left: 20px; }

				&:nth-child(1) { background-image: url('@@img/main/hnr/food-story1.jpg'); }
				&:nth-child(2) { background-image: url('@@img/main/hnr/food-story2.jpg'); }
				&:nth-child(3) { background-image: url('@@img/main/hnr/food-story3.jpg'); }
			}
		}

		.action {
			margin-top: 30px;
		}

		.link {
			box-sizing: border-box;
			display: inline-block;
			width: 135px;
			height: 40px;
			line-height: 38px;
			vertical-align: middle;
			text-align: center;
			font-size: 16px;
			text-decoration: none;
			letter-spacing: 0;
			border: 1px solid #363636;
			border-radius: 20px;
			transition: all .4s;

			&:hover,
			&:focus {
				color: #fff;
				background: #363636;
			}
		}
	}

	.quick-link {
		width: $content-width;
		margin: 0 auto;

		@include clearfix;

		section {
			float: left;
			box-sizing: border-box;
			width: 50%;
			padding: 78px 0 120px;
			position: relative;


			&:not(:first-child) {
				&:before {
					content: '';
					width: 1px;
					background: #e1e1e1;
					position: absolute;
					top: 78px;
					bottom: 120px;
					left: 0;
				}
			}
		}

		.sub-title {
			height: 60px;
			line-height: 1;
			text-align: center;
			font-weight: 500;
			font-size: 26px;
			color: #232323;
		}

		.list {
			text-align: center;
			font-size: 0;

			li {
				display: inline-block;
				vertical-align: top;
			}

			a {
				display: block;
				width: 210px;
				text-align: center;
				font-size: 16px;

				&:before {
					content: '';
					display: block;
					width: 90px;
					height: 90px;
					margin: 0 auto 14px;
					background: #000;
				}

				&.condo1:before { background: url('@@img/main/hnr/membership1.png'); }
				&.condo2:before { background: url('@@img/main/hnr/membership2.png'); }
				&.golf1:before { background: url('@@img/main/hnr/membership3.png'); }
				&.golf2:before { background: url('@@img/main/hnr/membership4.png'); }
			}
		}
	}
}
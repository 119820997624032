/* 현장 속보 */
.snowpark-news {
	
	/* 날씨 아이콘 */
	.weather-info {
		display: inline-block;

		img { max-width: 40px; }
		
		li {
			display: inline-block;
			vertical-align: middle;

			&:not(:first-child) { margin-left: 30px; }
		}
	}
}
/* 분양대금 납부, 연회비 납부  */
.sellinfo-payment {
	.head-desc {
		font-size: 16px;
		font-weight: 300;
	}
	.head-desc + .table-container { margin-top: 18px; }
	
	.opt-list {
		padding: 30px;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
		font-size: 16px;

		li:not(:first-child) { margin-top: 28px; }
	}

	/* 금액 강조 */
	.sum {
		color: #d14e40;
		font-weight: 500;
	}
}
/* 워터파크 이용안내 */
.waterpark-guide {
	
	/* 이용 요금 및 할인 안내 */
	.price-table-wrap {
		.table-container:not(:first-child) { margin-top: 30px; }
	
		th, td { 
			box-sizing: border-box;
			height: 40px;
			font-size: 15px;
		}
		
		thead {
			th { font-size: 15px; }

			.tit-season {
				background: #788ea3;
				color: #fff;
				font-size: 16px;
				font-weight: 500;
				letter-spacing: -.05em;
			}
		}

		tbody {
			th { background: none; }
			td {
				padding: 5px 10px 4px;
				font-weight: 300;
			}
		}
	}

	
	.full-content { 
		width: 100%;
		margin: 54px 0 60px;
		padding: 54px 0 60px;
		background: #f5f5f5;
	}

	/* 입장 및 퇴장 안내 */
	.in-out-guide {

		> li:after {
			content: '';
			display: block;
			width: 23px;
			height: 15px;
			margin: 9px 0 6px 70px;

			@include sprite($waterpark-arrow-bottom);
		}
		> li:last-child:after { display: none; }

		.con-wrap {
			box-sizing: border-box;
			padding: 20px 35px;
			background: #fff;

			@include clearfix;

			.con {
				float: left;
				
				&:last-child {
					padding: 3px 0 5px 35px;
					border-left: 1px solid #ddd;
				}
				&.last:last-child {
					border-left: 0;
				}
			}

			.step {
				display: inline-block;
				width: 88px;
				height: 34px;
				border-radius: 17px;
				background: #232323;
				color: #fff;
				font-size: 14px;
				font-weight: 500;
				text-align: center;
				line-height: 34px;
				vertical-align: top;
			}

			.tit {
				box-sizing: border-box;
				display: inline-block;
				width: 190px;
				padding-left: 15px;
				color: #232323;
				font-size: 20px;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: -.05em;
				vertical-align: top;
			}

			.dot-bu-list li { color: #232323; }
		}
	}

	/* 주의사항 */
	.box-quarter {
		border-bottom: 1px solid #ddd;

		.con-wrap {
			display: table;
			width: 100%;
			border-top: 1px solid #ddd;
			border-right: 1px solid #ddd;
		}

		.con {
			box-sizing: border-box;
			display: table-cell;
			width: 50%;
			padding: 37px 40px 40px;
			border-left: 1px solid #ddd;
		}

		.head-sub2 { padding-left: 0; }
		.dot-bu-list li:not(:first-child) { margin-top: 10px; }
	}

}
/* 브랜드 스토리 */
.story-brand {
	
	.photo-story1 {
		height: 900px;
		background: url('@@img/story/bg-brand-story1.jpg') no-repeat 50% 50%;
		background-size: cover;
		background-attachment: fixed;
	
		.top-flag { padding-top: 120px; }
		.main-copy { font-size: 36px; }
	}

	.photo-story2 {
		position: relative;
		height: 900px;
		background: url('@@img/story/bg-brand-story2.jpg') no-repeat 50% 50%;
		background-size: cover;
		background-attachment: fixed;

		.common-content {
			position: relative;
		}
	
		p.desc {
			position: absolute;
			top: 170px;
			left: 600px;
			// color: #232323;
			color: #fff;
			font-size: 18px;
			font-weight: 400;
			line-height: 1.3;
		}
	}

}
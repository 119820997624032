/* 콘도/골프회원 회원인증 */
.pop-condo-golf-auth {
	.layout-form {
		padding: 0 45px;
	}

	.form-head {
		width: 105px;
	}

	.desc {
		margin-top: 25px;
		text-align: center;
		font-weight: 300;
		font-size: 15px;
		color: #b0895c;
	}
}
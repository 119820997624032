/* 나의 혜택 관리 */
.mypage-benefit {

	/* 쿠폰목록 */
	.coupon-list {
		padding: 50px 0;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #d4d4d4;
		font-size: 0;
	
		> ul:not(:first-child) { margin-top: 30px; }
	
		.no-item {
			padding: 65px 0;
			font-size: 18px;
			font-weight: 300;
			text-align: center;
		}

		.item {
			box-sizing: border-box;
			position: relative;
			display: inline-block;
			width: 380px;
			height: 180px;
			padding: 0 30px;
			border: 1px solid #999;
			text-align: center;
			vertical-align: top;
	
			&:not(:nth-child(3n+1)) { margin-left: 30px; }
			&:nth-child(3) ~ .item { margin-top: 30px; }
	
			&.type1 { background: url('@@img/mypage/bg-logo-pyeongchang.png') no-repeat center 29px; } /* 평창 */
			&.type2 { background: url('@@img/mypage/bg-logo-jeju.png') no-repeat center 29px; } /* 제주 */
		}

		.cate {
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			width: 72px;
			height: 42px;
			color: #fff;
			font-size: 15px;
			font-weight: 300;
			text-align: center;
			line-height: 42px;

			&.room { background: #232323; }
			&.golf { background: #4ca728; }
		}

		.con {
			padding-top: 74px;
			color: #232323;
			font-size: 32px;
			font-weight: 500;
			line-height: 1.2;
			letter-spacing: -.05em;
		}

		.period {
			margin-top: 15px;
			padding-top: 12px;
			border-top: 1px solid #ddd;
			font-size: 15px;
			font-weight: 500;
			letter-spacing: .05em;
		}

		
	}

	/* 개별/대량 발송 영역 */
	.send-type-choice {
		height: 40px;
		margin-bottom: 10px;

		@include clearfix;

		.sort { 
			float: left;
			padding-top: 7px;

			label { font-size: 16px; }
		}
		.btn-area { float: right; }
	}

	.list-tel-form {
		width: 510px;
		li:not(:first-child) { margin-top: 10px; }
	}

	.foot-guide {
		padding: 30px 12px;

		p { 
			position: relative;
			padding-left: 20px;
			font-size: 16px;
			line-height: 26px;

			&:not(:first-child) { margin-top: 20px; }
			&:before {
				content: '※';
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	.foot-agree {
		padding: 30px 0;
		text-align: center;
		border-top: 1px solid #d4d4d4;
		border-bottom: 1px solid #d4d4d4;

		label {
			color: #232323;
			font-size: 20px;
			font-weight: 500;
		}
	}
}
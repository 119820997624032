/* 웨딩연회 > 연회장 - 기자재 대여 관련 팝업 */
.pop-party-product-rental {
	&.common-pop-body { width: 1100px; }

	.foot-box-area {
		margin: 50px -30px -20px -30px;
		width: 1100px;
		height: 80px;
		line-height: 80px;
		background: #f5f5f5;
		font-size: 20px;
		font-weight: 500;
		color: #232323;
		text-align: center;
	}

	.ico-tel {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-top: -5px;
		padding-left: 10px;
		vertical-align: middle;
		background: url('@@img/party/jeju/ico-tel.png') no-repeat 0 0;
	}
	.head-sub-desc {
		font-size: 16px;
		font-weight: 300;
	}

	.desc {
		color: #b0895c;
		font-size: 16px;
		margin: 0 0 25px 20px;

		strong { font-weight: 500; }
	}

	.dot-bu-list {
		li {
			span { 
				font-size: 16px;
				color: #b0895c;
			}
		}
	}

	.basic-list-wrap {
		> li { 
			font-size: 16px;
			color: #232323;
			font-weight: 500;

			&:not(:first-child) {
				margin-top: 20px;
			}
		}
	}
	.basic-list {
		margin: 5px 0 0 18px;

		li {
			color: #666;
			font-size: 15px;
			font-weight: 300;
			line-height: 24px;

			strong {
				display: inline-block;
				width: 65px;
				font-weight: 400;
			}
		}
	}

	.table-container {

		td { 
			color: #666;
			strong { 
				color: #232323;
				font-weight: 500;
			}
		}
	}

}
/* 약관/규정 팝업 */
.pop-license-policy {
	&.common-pop-body {
		width: 860px;
	}

	.license-txt {
		box-sizing: border-box;
		height: 318px;
		padding: 30px;
		border: 1px solid #ddd;
		overflow: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}

	.agree-input {
		line-height: 59px;
		padding-left: 28px;
		font-weight: 300;
		font-size: 16px;
		color: #232323;
		border-top: 1px solid #ddd;

		@include clearfix;

		.required { color: #b0895c; }

		.option {
			box-sizing: border-box;
			float: left;
			width: 50%;

			&:not(:first-child) {
				padding-left: 28px;
				border-left: 1px solid #ddd;
			}
		}
	}

	/* 약관 제목 depth-1 */
	.license-tit-1 {
		font-weight: 500;
		font-size: 15px;
		color: #666;

		&:not(:first-child) { margin-top: 30px; }
	}

	/* 약관 내용 문단 */
	.license-p {
		margin: 5px 0;
		font-weight: 300;
		font-size: 15px;
		color: #666;
	}

	/* 약관 내용 목록 */
	.license-list {
		margin: 5px 0;
		font-size: 15px;
		color: #666;

		> li .license-list {
			padding-left: 20px;

			> li .license-list {
				padding-left: 20px;
			}
		}
	}

	/* 약관 표 */
	.table-container {
		margin: 5px 0;
	}
}
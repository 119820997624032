/* 스노우 파크 - 동계올림픽 */
.snowpark-olympic-winter {

	.olympic-symbol-wrap {
		position: relative;
		background: url('@@img/snowpark/olympic-symbol-img.png') no-repeat 39px 72px;
		margin-bottom: 50px;

		.tit {
			font-size: 34px;
			font-weight: 500;
			color: #232323;
			line-height: 45px;
			position: absolute;
			left: 45px;
			top: 82px;
		}
	}

	.olympic-symbol-area {
		width: $content-width;
		height: 1740px;

		.item {
			&:nth-child(1) { position: absolute; right: 0; top: 73px; }
			&:nth-child(2) { position: absolute; left: 45px; top: 503px; }
			&:nth-child(3) { position: absolute; left: 370px; top: 687px; }
			&:nth-child(4) { position: absolute; right: 180px; top: 1085px; }
			&:nth-child(5) { position: absolute; left: 45px; top: 1420px; }
		}

		h5 {
			color: #232323;
			font-size: 20px;
			font-weight: 500;
			padding-bottom: 15px;
			margin-bottom: 15px;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				position: absolute;
				border-bottom: 1px solid #999;
				width: 28px;
				height: 1px;
				left: 0;
				bottom: 0;

			}
		}

		.con {
			color: #666;
			font-size: 16px;
			font-weight: 300;
		}
	}

	.olympic-accordion-list {
		margin-top: 10px;
		border-top: 1px solid #232323;
		border-bottom: 1px solid #ddd;
	}

	.olympic-accordion-tit {
		font-size: 18px;
		color: #232323;
		font-weight: 500;
		height: 79px;
		padding: 0 20px;
		line-height: 79px;
		border-top: 1px solid #ddd;

		a {
			display: inline-block;
			width: 100%;
			height: 100%;
			font-weight: 500;
			background: url('@@img/snowpark/ico-down.png') no-repeat right 50%;
		}

		&:first-child { border: 0; }

		&.on {
			a { 
				font-weight: 500;
				background: url('@@img/snowpark/ico-close.png') no-repeat right 50%;
			}
		}
	}

	.olympic-accordion-con {
		border-top: 1px solid #ddd;
		padding: 25px 0;
		overflow: hidden;
		box-sizing: border-box;

		.thumb { 
			float: left;
			width: 560px;
			height: 320px;

			img { width: 100%; height: 100%; }
		}

		.con { 
			float: left;
			padding: 0 50px;
			box-sizing: border-box;
			width: 640px;
			margin-top: -4px;
			
			p { 
				margin-bottom: 18px;
				padding: 0 5px;
				font-weight: 300;
				font-size: 15px;
			}

			a { 
				color: #232323;
				font-size: 14px;
				text-decoration: underline;
			}
		}
	}
}
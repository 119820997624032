/* 프로그램 혜택 안내 */ 
.snowpark-school-benefit {
	
	.program-benefit-title {
		margin-bottom: 30px;
		color: #232323;
		font-size: 32px;
		font-weight: 400;
		text-align: center;
		letter-spacing: -.05em;
		
		span { color: #01609a; }
	}

	.program-benefit {
		font-size: 0;

		.item {
			box-sizing: border-box;
			display: inline-block;
			width: 380px;
			height: 300px;
			vertical-align: top;
			text-align: center;
			font-style: 0;
			white-space: nowrap;
			border: 1px solid #777;

			&:not(:nth-child(3n+1)) { margin-left: 30px; }
			&:nth-child(3) ~ .item { margin-top: 30px; }
			
			&:after {
				content: '';
				display: inline-block;
				width: 0;
				height: 100%;
				vertical-align: middle;
			}
		}

		.con-wrap {
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			white-space: normal;
		}

		/* 컨텐츠 */
		.num, .sub {
			color: #01609a;
			font-size: 18px;
			font-weight: 500;
		}
		
		.desc {
			margin-bottom: 13px;
			padding-bottom: 13px;
			font-weight: 500;
			font-size: 22px;
			color: #232323;
			position: relative;

			@include ellipsis;

			&:before {
				content: '';
				display: block;
				width: 20px;
				height: 1px;
				background: #999;
				position: absolute;
				bottom: 0;
				left: calc(50% - 10px);
			}

			&.no-line:before { display: none; }
		}
		
		.sub-desc {
			color: #232323;
			font-size: 20px;
			font-weight: 500;
			
			.point { color: #01609a; }
		}

		.sub-desc2 {
			font-size: 15px;
			font-weight: 300;
		}

		.contact {
			color: #232323;
			font-size: 28px;
			font-weight: 500;
		}

		.dot-bu-list {
			margin-top: 14px;
			margin-left: 50px;
			
			li {
				display: inline-block;
				text-align: left;
				font-size: 15px;

				&:before { background: #666; }
				&:not(:first-child) { margin-top: 6px; }
			}
		}

		.btn-area {
			margin-top: 18px;

			.btn { min-width: 142px; }
			.btn + .btn { margin-left: 6px; }
		}

		/* 간격 */
		.num { margin-bottom: 14px; }
		.sub + .sub-desc { margin-top: 5px; }
	}

	/* 프리미엄키즈 */
	.program1 {
		.item {
			&:nth-child(1),
			&:nth-child(2) {
				.con-wrap { vertical-align: top; padding-top: 37px; }
			}
		}
	}

	/* 일반강습 */
	.program2 {
		.item:nth-child(1) { width: 790px; }

		.quotation {
			position: relative;
			padding-top: 68px;
			background: url('@@img/snowpark/school/quotation-marks.png') no-repeat 50% top;
		}
	}

	/* 마스터즈클럽, 외국인 스노우 스쿨, 인터스노우 스쿨, 모글 스노우 스쿨 */
	.program3 {
		.item {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) { height: 420px; }
			&:nth-child(4),
			&:nth-child(5) { height: 380px; }
			&:not(:nth-child(5)) { 
				.con-wrap { vertical-align: top; padding-top: 37px; }
			}
		}
	}

	/* 프리스타일 - 스키 */
	.program7-1 {
		$item4-height: 215px;
		$mt: 30px;

		.item:nth-child(3) { height: 300px + $item4-height + $mt; margin-bottom: -($item4-height + $mt); }
		.item:nth-child(4) { width: 790px; height: $item4-height; }
		.item {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) { 
				.con-wrap { vertical-align: top; padding-top: 37px; }
			}
		}
	}
	/* 프리스타일 - 보드 */
	.program7-2 {
		.item:not(:nth-child(5)) { 
			.con-wrap { vertical-align: top; padding-top: 37px; }
		}
	}
	
	/* 익스트림파크 */
	.program8 {
		.item {
			&:nth-child(1),
			&:nth-child(2) {
				.con-wrap { vertical-align: top; padding-top: 37px; }
			}
		}
	}

}
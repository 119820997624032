/* 분양안내 - 증명서 출력 관련 팝업 */
.pop-sellinfo-action {
	&.common-pop-body { width: 860px; }

	.input-txt-btn {
		&.member-num {
			.txt {
				width: calc(100% - 150px) !important;
				margin-right: 10px;
			}
		
			.btn {
				width: 140px;
				padding: 0;
			}
		}
	}

}
// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$reservation-calendar-next-name: 'reservation-calendar-next';
$reservation-calendar-next-x: 0px;
$reservation-calendar-next-y: 450px;
$reservation-calendar-next-offset-x: 0px;
$reservation-calendar-next-offset-y: -450px;
$reservation-calendar-next-width: 22px;
$reservation-calendar-next-height: 37px;
$reservation-calendar-next-total-width: 590px;
$reservation-calendar-next-total-height: 524px;
$reservation-calendar-next-image: '@@img/sprite/reservation.png';
$reservation-calendar-next: (0px, 450px, 0px, -450px, 22px, 37px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-calendar-next', );
$reservation-calendar-prev-name: 'reservation-calendar-prev';
$reservation-calendar-prev-x: 32px;
$reservation-calendar-prev-y: 450px;
$reservation-calendar-prev-offset-x: -32px;
$reservation-calendar-prev-offset-y: -450px;
$reservation-calendar-prev-width: 22px;
$reservation-calendar-prev-height: 37px;
$reservation-calendar-prev-total-width: 590px;
$reservation-calendar-prev-total-height: 524px;
$reservation-calendar-prev-image: '@@img/sprite/reservation.png';
$reservation-calendar-prev: (32px, 450px, -32px, -450px, 22px, 37px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-calendar-prev', );
$reservation-calendar2-next-name: 'reservation-calendar2-next';
$reservation-calendar2-next-x: 27px;
$reservation-calendar2-next-y: 497px;
$reservation-calendar2-next-offset-x: -27px;
$reservation-calendar2-next-offset-y: -497px;
$reservation-calendar2-next-width: 17px;
$reservation-calendar2-next-height: 27px;
$reservation-calendar2-next-total-width: 590px;
$reservation-calendar2-next-total-height: 524px;
$reservation-calendar2-next-image: '@@img/sprite/reservation.png';
$reservation-calendar2-next: (27px, 497px, -27px, -497px, 17px, 27px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-calendar2-next', );
$reservation-calendar2-prev-name: 'reservation-calendar2-prev';
$reservation-calendar2-prev-x: 0px;
$reservation-calendar2-prev-y: 497px;
$reservation-calendar2-prev-offset-x: 0px;
$reservation-calendar2-prev-offset-y: -497px;
$reservation-calendar2-prev-width: 17px;
$reservation-calendar2-prev-height: 27px;
$reservation-calendar2-prev-total-width: 590px;
$reservation-calendar2-prev-total-height: 524px;
$reservation-calendar2-prev-image: '@@img/sprite/reservation.png';
$reservation-calendar2-prev: (0px, 497px, 0px, -497px, 17px, 27px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-calendar2-prev', );
$reservation-cate-golf-off-name: 'reservation-cate-golf-off';
$reservation-cate-golf-off-x: 150px;
$reservation-cate-golf-off-y: 150px;
$reservation-cate-golf-off-offset-x: -150px;
$reservation-cate-golf-off-offset-y: -150px;
$reservation-cate-golf-off-width: 140px;
$reservation-cate-golf-off-height: 140px;
$reservation-cate-golf-off-total-width: 590px;
$reservation-cate-golf-off-total-height: 524px;
$reservation-cate-golf-off-image: '@@img/sprite/reservation.png';
$reservation-cate-golf-off: (150px, 150px, -150px, -150px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-golf-off', );
$reservation-cate-golf-on-name: 'reservation-cate-golf-on';
$reservation-cate-golf-on-x: 300px;
$reservation-cate-golf-on-y: 0px;
$reservation-cate-golf-on-offset-x: -300px;
$reservation-cate-golf-on-offset-y: 0px;
$reservation-cate-golf-on-width: 140px;
$reservation-cate-golf-on-height: 140px;
$reservation-cate-golf-on-total-width: 590px;
$reservation-cate-golf-on-total-height: 524px;
$reservation-cate-golf-on-image: '@@img/sprite/reservation.png';
$reservation-cate-golf-on: (300px, 0px, -300px, 0px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-golf-on', );
$reservation-cate-package-off-name: 'reservation-cate-package-off';
$reservation-cate-package-off-x: 300px;
$reservation-cate-package-off-y: 150px;
$reservation-cate-package-off-offset-x: -300px;
$reservation-cate-package-off-offset-y: -150px;
$reservation-cate-package-off-width: 140px;
$reservation-cate-package-off-height: 140px;
$reservation-cate-package-off-total-width: 590px;
$reservation-cate-package-off-total-height: 524px;
$reservation-cate-package-off-image: '@@img/sprite/reservation.png';
$reservation-cate-package-off: (300px, 150px, -300px, -150px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-package-off', );
$reservation-cate-package-on-name: 'reservation-cate-package-on';
$reservation-cate-package-on-x: 0px;
$reservation-cate-package-on-y: 0px;
$reservation-cate-package-on-offset-x: 0px;
$reservation-cate-package-on-offset-y: 0px;
$reservation-cate-package-on-width: 140px;
$reservation-cate-package-on-height: 140px;
$reservation-cate-package-on-total-width: 590px;
$reservation-cate-package-on-total-height: 524px;
$reservation-cate-package-on-image: '@@img/sprite/reservation.png';
$reservation-cate-package-on: (0px, 0px, 0px, 0px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-package-on', );
$reservation-cate-room-off-name: 'reservation-cate-room-off';
$reservation-cate-room-off-x: 150px;
$reservation-cate-room-off-y: 300px;
$reservation-cate-room-off-offset-x: -150px;
$reservation-cate-room-off-offset-y: -300px;
$reservation-cate-room-off-width: 140px;
$reservation-cate-room-off-height: 140px;
$reservation-cate-room-off-total-width: 590px;
$reservation-cate-room-off-total-height: 524px;
$reservation-cate-room-off-image: '@@img/sprite/reservation.png';
$reservation-cate-room-off: (150px, 300px, -150px, -300px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-room-off', );
$reservation-cate-room-on-name: 'reservation-cate-room-on';
$reservation-cate-room-on-x: 150px;
$reservation-cate-room-on-y: 0px;
$reservation-cate-room-on-offset-x: -150px;
$reservation-cate-room-on-offset-y: 0px;
$reservation-cate-room-on-width: 140px;
$reservation-cate-room-on-height: 140px;
$reservation-cate-room-on-total-width: 590px;
$reservation-cate-room-on-total-height: 524px;
$reservation-cate-room-on-image: '@@img/sprite/reservation.png';
$reservation-cate-room-on: (150px, 0px, -150px, 0px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-room-on', );
$reservation-cate-snowpark-off-name: 'reservation-cate-snowpark-off';
$reservation-cate-snowpark-off-x: 300px;
$reservation-cate-snowpark-off-y: 300px;
$reservation-cate-snowpark-off-offset-x: -300px;
$reservation-cate-snowpark-off-offset-y: -300px;
$reservation-cate-snowpark-off-width: 140px;
$reservation-cate-snowpark-off-height: 140px;
$reservation-cate-snowpark-off-total-width: 590px;
$reservation-cate-snowpark-off-total-height: 524px;
$reservation-cate-snowpark-off-image: '@@img/sprite/reservation.png';
$reservation-cate-snowpark-off: (300px, 300px, -300px, -300px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-snowpark-off', );
$reservation-cate-snowpark-on-name: 'reservation-cate-snowpark-on';
$reservation-cate-snowpark-on-x: 450px;
$reservation-cate-snowpark-on-y: 0px;
$reservation-cate-snowpark-on-offset-x: -450px;
$reservation-cate-snowpark-on-offset-y: 0px;
$reservation-cate-snowpark-on-width: 140px;
$reservation-cate-snowpark-on-height: 140px;
$reservation-cate-snowpark-on-total-width: 590px;
$reservation-cate-snowpark-on-total-height: 524px;
$reservation-cate-snowpark-on-image: '@@img/sprite/reservation.png';
$reservation-cate-snowpark-on: (450px, 0px, -450px, 0px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-snowpark-on', );
$reservation-cate-ticket-off-name: 'reservation-cate-ticket-off';
$reservation-cate-ticket-off-x: 450px;
$reservation-cate-ticket-off-y: 150px;
$reservation-cate-ticket-off-offset-x: -450px;
$reservation-cate-ticket-off-offset-y: -150px;
$reservation-cate-ticket-off-width: 140px;
$reservation-cate-ticket-off-height: 140px;
$reservation-cate-ticket-off-total-width: 590px;
$reservation-cate-ticket-off-total-height: 524px;
$reservation-cate-ticket-off-image: '@@img/sprite/reservation.png';
$reservation-cate-ticket-off: (450px, 150px, -450px, -150px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-ticket-off', );
$reservation-cate-ticket-on-name: 'reservation-cate-ticket-on';
$reservation-cate-ticket-on-x: 450px;
$reservation-cate-ticket-on-y: 300px;
$reservation-cate-ticket-on-offset-x: -450px;
$reservation-cate-ticket-on-offset-y: -300px;
$reservation-cate-ticket-on-width: 140px;
$reservation-cate-ticket-on-height: 140px;
$reservation-cate-ticket-on-total-width: 590px;
$reservation-cate-ticket-on-total-height: 524px;
$reservation-cate-ticket-on-image: '@@img/sprite/reservation.png';
$reservation-cate-ticket-on: (450px, 300px, -450px, -300px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-ticket-on', );
$reservation-cate-waterpark-off-name: 'reservation-cate-waterpark-off';
$reservation-cate-waterpark-off-x: 0px;
$reservation-cate-waterpark-off-y: 150px;
$reservation-cate-waterpark-off-offset-x: 0px;
$reservation-cate-waterpark-off-offset-y: -150px;
$reservation-cate-waterpark-off-width: 140px;
$reservation-cate-waterpark-off-height: 140px;
$reservation-cate-waterpark-off-total-width: 590px;
$reservation-cate-waterpark-off-total-height: 524px;
$reservation-cate-waterpark-off-image: '@@img/sprite/reservation.png';
$reservation-cate-waterpark-off: (0px, 150px, 0px, -150px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-waterpark-off', );
$reservation-cate-waterpark-on-name: 'reservation-cate-waterpark-on';
$reservation-cate-waterpark-on-x: 0px;
$reservation-cate-waterpark-on-y: 300px;
$reservation-cate-waterpark-on-offset-x: 0px;
$reservation-cate-waterpark-on-offset-y: -300px;
$reservation-cate-waterpark-on-width: 140px;
$reservation-cate-waterpark-on-height: 140px;
$reservation-cate-waterpark-on-total-width: 590px;
$reservation-cate-waterpark-on-total-height: 524px;
$reservation-cate-waterpark-on-image: '@@img/sprite/reservation.png';
$reservation-cate-waterpark-on: (0px, 300px, 0px, -300px, 140px, 140px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-cate-waterpark-on', );
$reservation-step-off-name: 'reservation-step-off';
$reservation-step-off-x: 64px;
$reservation-step-off-y: 450px;
$reservation-step-off-offset-x: -64px;
$reservation-step-off-offset-y: -450px;
$reservation-step-off-width: 24px;
$reservation-step-off-height: 24px;
$reservation-step-off-total-width: 590px;
$reservation-step-off-total-height: 524px;
$reservation-step-off-image: '@@img/sprite/reservation.png';
$reservation-step-off: (64px, 450px, -64px, -450px, 24px, 24px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-step-off', );
$reservation-step-on-name: 'reservation-step-on';
$reservation-step-on-x: 98px;
$reservation-step-on-y: 450px;
$reservation-step-on-offset-x: -98px;
$reservation-step-on-offset-y: -450px;
$reservation-step-on-width: 24px;
$reservation-step-on-height: 24px;
$reservation-step-on-total-width: 590px;
$reservation-step-on-total-height: 524px;
$reservation-step-on-image: '@@img/sprite/reservation.png';
$reservation-step-on: (98px, 450px, -98px, -450px, 24px, 24px, 590px, 524px, '@@img/sprite/reservation.png', 'reservation-step-on', );
$reservation-width: 590px;
$reservation-height: 524px;
$reservation-image: '@@img/sprite/reservation.png';
$reservation-sprites: ($reservation-calendar-next, $reservation-calendar-prev, $reservation-calendar2-next, $reservation-calendar2-prev, $reservation-cate-golf-off, $reservation-cate-golf-on, $reservation-cate-package-off, $reservation-cate-package-on, $reservation-cate-room-off, $reservation-cate-room-on, $reservation-cate-snowpark-off, $reservation-cate-snowpark-on, $reservation-cate-ticket-off, $reservation-cate-ticket-on, $reservation-cate-waterpark-off, $reservation-cate-waterpark-on, $reservation-step-off, $reservation-step-on, );
$reservation: (590px, 524px, '@@img/sprite/reservation.png', $reservation-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

/* 평창 메인 */
.main-pyeongchang {
	.date-search-form {
		.btn-submit {
			background-color: #006097;
		}
	}

	.quick-link {
		padding: 128px 0 70px;
		color: #232323;
		background: #f7f7f7;

		.section-title {
			height: 78px;
		}

		.list {
			text-align: center;
			font-size: 0;

			li {
				display: inline-block;
				vertical-align: top;

				// &:not(:first-child) { margin-left: 44px; }
			}

			a {
				display: block;
				width: 150px;
				text-align: center;
				font-size: 18px;
				white-space: nowrap;
				letter-spacing: -.05em;
				position: relative;

				&:before {
					content: '';
					display: block;
					width: 110px;
					height: 110px;
					margin: 0 auto 14px;
				}

				&.seq1:before { background: url('@@img/main/pyeongchang/quick1.png'); }
				&.seq2:before { background: url('@@img/main/pyeongchang/quick2.png'); }
				&.seq3:before { background: url('@@img/main/pyeongchang/quick3.png'); }
				&.seq4:before { background: url('@@img/main/pyeongchang/quick4.png'); }
				&.seq5:before { background: url('@@img/main/pyeongchang/quick5.png'); }
				&.seq6:before { background: url('@@img/main/pyeongchang/quick6.png'); }
				&.seq7:before { background: url('@@img/main/pyeongchang/quick7.png'); }
				&.seq8:before { background: url('@@img/main/pyeongchang/quick8.png'); }
				&.seq9:before { background: url('@@img/main/pyeongchang/quick9.png'); }
				&.seq10:before { background: url('@@img/main/pyeongchang/quick10.png'); }
				&.seq11:before { background: url('@@img/main/pyeongchang/quick11.png'); }
				&.seq12:before { background: url('@@img/main/pyeongchang/quick12.png'); }
				&.seq13:before { background: url('@@img/main/pyeongchang/quick13.png'); }
				&.seq14:before { background: url('@@img/main/pyeongchang/quick14.png'); }
				&.seq15:before { background: url('@@img/main/pyeongchang/quick15.png'); }
			}

			&.scroll-check {
				a {
					top: 50px;
					opacity: 0;
					transition: all .4s;
					transform: translate3d(0,0,0);
				}

				&.in {
					a {
						top: 0;
						opacity: 1;
						transition: all .5s;
					}

					li:nth-child(1) a { transition-delay: .05s; }
					li:nth-child(2) a { transition-delay: .1s; }
					li:nth-child(3) a { transition-delay: .15s; }
					li:nth-child(4) a { transition-delay: .2s; }
					li:nth-child(5) a { transition-delay: .25s; }
					li:nth-child(6) a { transition-delay: .3s; }
					li:nth-child(7) a { transition-delay: .35s; }
					li:nth-child(8) a { transition-delay: .4s; }
				}
			}
		}
	}

	.ski-board {
		padding: 95px 0 0;
		color: #232323;
		position: relative;

		.section-title {
			height: 77px;
		}
	}

	.ski-slider {
		height: 520px;
		position: relative;
		overflow: hidden;

		&:before {
			content: '';
			display: block;
			width: 50%;
			height: 100%;
			background: rgba(0,0,0,.4);
			position: absolute;
			top: 0;
			right: calc(50% + 600px);
			z-index: 50;
		}

		&:after {
			content: '';
			display: block;
			width: 50%;
			height: 100%;
			background: rgba(0,0,0,.4);
			position: absolute;
			top: 0;
			left: calc(50% + 600px);
			z-index: 50;
		}

		.viewport {
			width: $content-width;
			height: 520px;
			margin: 0 auto;
			position: relative;
		}

		.list {
			position: relative;
		}

		.item {
			position: absolute;

			&.view-in {
				z-index: 1;
			}
		}

		.controls {
			width: 132px;
			height: 66px;
			background: #2e2e2e;
			position: absolute;
			bottom: 0;
			left: 50%;
			z-index: 101;
			overflow: hidden;

			&:after {
				content: '';
				clear: both;
				display: block;
				width: 1px;
				height: 26px;
				background: #6d6d6d;
				position: absolute;
				top: calc(50% - 13px);
				left: 50%;
			}

			button {
				float: left;
				display: block;
				width: 66px;
				height: 0;
				max-width: 66px;
				padding-top: 66px;
				overflow: hidden;

				&.btn-prev {
					background: url('@@img/main/slider-prev.png') no-repeat 50% 50%;
				}

				&.btn-next {
					background: url('@@img/main/slider-next.png') no-repeat 50% 50%;
				}
			}
		}

		.bg {
			width: $content-width;
			height: 520px;
			background-size: cover;
		}

		.content {
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 50;

			.item {
				display: table;
				width: 100%;
				height: 100%;
				background: #f7f7f7;
			}

			dl {
				display: table-cell;
				width: 100%;
				height: 100%;
				text-align: center;
				vertical-align: middle;
			}
		}

		.title {
			line-height: 1.3;
			font-weight: 500;
			font-size: 28px;
			color: #232323;
		}

		.con {
			margin-top: 17px;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
			color: #666;
		}

		.action {
			margin-top: 47px;
		}

		.link {
			box-sizing: border-box;
			display: inline-block;
			width: 135px;
			height: 40px;
			line-height: 38px;
			vertical-align: middle;
			text-align: center;
			font-size: 16px;
			color: #666;
			text-decoration: none;
			letter-spacing: 0;
			border: 1px solid #666;
			border-radius: 20px;
			transition: all .4s;

			&:hover,
			&:focus {
				color: #fff;
				background: #666;
			}

			& + .link {
				margin-left: 5px;
			}
		}
	}

	.promotion {
		padding: 95px 0 70px;
		color: #232323;

		.section-title {
			height: 77px;
		}

		.label {
			background: #01609a;
		}
	}

	.waterpark {
		padding: 95px 0 120px;
		color: #fff;
		background: #000 url('@@img/main/pyeongchang/bg-waterpark.jpg') no-repeat 50% 0;

		.section-title {
			height: 96px;
		}

		.wrap {
			display: table;
			width: $content-width;
			margin: 0 auto;
		}

		.bg {
			display: table-cell;
			width: 542px;
			height: 298px;

			@include clearfix;

			div {
				float: left;
				display: block;
				width: 256px;
				height: 298px;
				position: relative;
				transition: all .4s;

				&:nth-child(1) { background: url('@@img/main/pyeongchang/waterpark1.jpg') no-repeat; }
				&:nth-child(2) { float: right; background: url('@@img/main/pyeongchang/waterpark2.jpg') no-repeat; }
			}

			&.scroll-check {
				div {
					top: 50px;
					opacity: 0;
					transition: all .4s;
					transform: translate3d(0,0,0);
				}

				&.in {
					div:nth-child(1) {
						top: 0;
						opacity: 1;
						transition: all 1s .2s;
					}

					div:nth-child(2) {
						top: 0;
						opacity: 1;
						transition: all 1s .3s;
					}
				}
			}
		}

		.con {
			display: table-cell;
			vertical-align: middle;
			position: relative;

			&.scroll-check {
				top: 50px;
				opacity: 0;
				transition: all .4s;

				&.in {
					top: 0;
					opacity: 1;
					transition: all 1s;
				}
			}
		}

		.copy {
			line-height: 38px;
			font-weight: 500;
			font-size: 28px;
		}

		.desc {
			margin-top: 18px;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
		}

		.action {
			margin-top: 36px;
		}

		.link {
			box-sizing: border-box;
			display: inline-block;
			width: 135px;
			height: 40px;
			line-height: 38px;
			vertical-align: middle;
			text-align: center;
			font-size: 16px;
			text-decoration: none;
			letter-spacing: 0;
			border: 1px solid #e1e1e1;
			border-radius: 20px;
			transition: all .4s;

			&:hover,
			&:focus {
				color: #232323;
				background: #fff;
			}

			& + .link {
				margin-left: 5px;
			}
		}
	}
}
/* 스노우 파크 공통 */
.section-snowpark {
	.table-container {
		.dot-bu-list li { display: block; }

		th.td { font-weight: 400; }
	}

	.arr {
		color: #232323;

		&:before {
			content: '▶';
			display: inline-block;
			margin-left: 10px;
			margin-right: 4px;
			font-size: 7px;
			vertical-align: 2px;
		}
	}

	/* 주의사항 */
	.attention {
		.tit {
			display: inline-block;
			width: 110px;
		}
		.point { 
			color: #01609a;
			font-weight: 400;
		}
	}

	.table-container + .head-sub2 { margin-top: 35px; }

	/* 강사진 소개 */
	.info-box-list.person-info {
		padding: 0 34px;
		border: 1px solid #ddd;
		
		.item:last-child { border-bottom: 0; }

		.thumb { width: 150px; }
		.tit { width: 165px; }
		.info-area { width: 850px; } 
		.info-data {
			dd { font-weight: 300; }

			dl:not(:first-child) { margin-top: 20px; }
		}
	}
}
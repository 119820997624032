/* 워터파크 실내존/실외존  */
.waterpark-zone {
	
	.zone-map {
		height: 560px;
		margin-bottom: 80px;
		position: relative;
		overflow: hidden;

		.img-main {
			img {
				width: 100%;
				height: 100%;
			}
		}

		.control {
			box-sizing: border-box;
			width: 100%;
			height: 38px;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			font-size: 0;
			white-space: nowrap;
			position: absolute;
			top: calc(100% - 38px - 25px);
			left: 0;
			z-index: 100;

			button + button { margin-left: 10px; }
		}

		.btn-vr-close {
			display: block;
			width: 64px;
			height: 64px;
			top: 0;
			right: 0;
			@include afterSpriteIR($room-vr-close,64px,64px,absolute);
		}

		.vr-view {
			width: 100%;
			height: 100%;
			background: #000;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 101;
		}
	}

	/* 주의사항 픽토그램 리스트 */
	.attention-list {
		padding-top: 10px;

		li {
			display: table;
			width: 100%;
			table-layout: fixed;

			&:not(:first-child) { padding-top: 15px; }
		}

		.txt {
			box-sizing: border-box;
			display: table-cell;
			width: 100%;
			height: 55px;
			padding-left: 90px;
			vertical-align: middle;
			font-weight: 300;
			font-size: 16px;
			color: #fff;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
			}

			&.ico1:before { @include sprite($waterpark-attention1); }
			&.ico2:before { @include sprite($waterpark-attention2); }
			&.ico3:before { @include sprite($waterpark-attention3); }
			&.ico4:before { @include sprite($waterpark-attention4); }
			&.ico5:before { @include sprite($waterpark-attention5); }
			&.ico6:before { @include sprite($waterpark-attention6); }
			&.ico7:before { @include sprite($waterpark-attention7); }
			&.ico8:before { @include sprite($waterpark-attention8); }
			&.ico9:before { @include sprite($waterpark-attention9); }
			&.ico10:before { @include sprite($waterpark-attention10); }
			&.ico11:before { @include sprite($waterpark-attention11); }
			&.ico12:before { @include sprite($waterpark-attention12); }
			&.ico13:before { @include sprite($waterpark-attention13); }
			&.ico14:before { @include sprite($waterpark-attention14); }
		}

	}
}
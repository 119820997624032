/* 이용안내 - 휘닉스 평창 소개 */
.guide-intro-pyeongchang {

	.top-intro {
		padding-top: 287px;
		background: url('@@img/guide/pyeongchang/intro/top-visual.jpg') no-repeat 50% 0;

		.wrap {
			width: $content-width;
			margin: 0 auto;
			padding: 43px 0 56px;

			@include clearfix;
		}

		.con {
			box-sizing: border-box;
			float: left;
			width: 50%;
			line-height: 26px;
			font-weight: 300;
			font-size: 16px;
			white-space: nowrap;

			&:not(:first-child) {
				padding-left: 125px;
				border-left: 1px solid #ddd;
			}
		}
	}

	.main {
		box-sizing: border-box;
		width: 640px;
		height: 300px;
		margin: 0 auto;
		padding-top: 76px;
		text-align: center;
		color: #fff;
		background: rgba(0,96,151,.9);
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 30px;
			height: 2px;
			background: #fff;
			position: absolute;
			top: 143px;
			left: calc(50% - 15px);
		}
	}

	.head-copy {
		height: 83px;
		font-size: 32px;
		letter-spacing: -.05em;
	}

	.sub-copy {
		line-height: 26px;
		font-weight: 300;
		font-size: 16px;
	}

	.con-wrap2 {
		padding: 61px 0 57px;
		background: #f5f5f5;

		.video-area {
			width: $content-width;
			margin: 0 auto 33px;
		}

		.con {
			margin-top: 30px;
			text-align: center;
			line-height: 28px;
			font-size: 18px;
		}
	}

	.service-wrap {
		width: $content-width;
		margin: 0 auto;
		padding: 57px 0 0;
		letter-spacing: -.05em;
		overflow: hidden;
	}

}
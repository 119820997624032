/* 체험,휴식 - 테라피 */
.experience-therapy {

	.table-container {
		strong { 
			color: #666;
			font-weight: 500;
		}
	}
	
}
/* 건축아트 아고라 */
.architecture-agora {
	margin-bottom: -120px;

	.vr-view { width: 100%; height: 460px; }
	
	.main-msg {
		margin: 60px 0 60px;
		text-align: center;
		font-size: 32px;
		color: #232323;
		letter-spacing: -.05em;
	}

	.moon-wind-wrap {
		overflow: hidden;
		box-sizing: border-box;
		padding-top: 688px;
		background: url('@@img/architecture/moon-wind-img.jpg') 0 0 no-repeat;

		.tit {
			margin-top: 30px;
			padding: 10px 0 0 30px;
			float: left;
			width: 370px;
			color: #070707;
			font-size: 22px;
			font-weight: 500;
			line-height: 1.2;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				width: 27px;
				height: 1px;
				background: #111;
				position: absolute;
				top: 0;
			}

			span { 
				display: inline-block;
				width: 100%;
				font-size: 18px;
			}
		}
		
		.con {
			margin-top: 30px;
			padding-left: 20px;
			float: left;
			width: 780px;
			font-size: 16px;
			font-weight: 300;

			p {
				&:not(:first-child) {
					margin-top: 13px;
				}
			}
		}
	}
	
}
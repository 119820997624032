/* 로그인 비밀번호 찾기 */
.login-find-pw {

	.layout-form {
		margin: 0 280px 40px;
	}

	.form-head {
		width: 85px;
	}
}
/* 가족/지정회원 시즌권 사용신청 */
.pop-family-apply {
	&.common-pop-body { width: 600px; }
}
/* 가족/지정회원 시즌권 사용등록 */
.pop-family-apply2 {
	&.common-pop-body { width: 720px; }

	.license-txt {
		max-height: 220px;
	}
}
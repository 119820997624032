/* 오시는길 */
.company-map {

	.map-area {
		width: 1200px;
		height: 500px;
		background: #f5f5f5;
	}

	.print-area {
		height: 0;
		margin-right: 20px;
		text-align: right;
		position: relative;
		top: -60px;
		z-index: 1;
	}

	.basic-info-notice {

		.notice {
			margin-top: 30px;
			font-size: 15px;
		}
		
		.list {
			li { color: #fff; }
		}

		.head {
			display: inline-block;
			width: 110px;
		}
	}

}
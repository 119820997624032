/* 회원가입 공통 */
.section-join {

	/* 상단 제목 영역 */
	.main-title {
		margin-top: -7px;
		margin-bottom: 36px;
		text-align: center;

		.title {
			line-height: 1.2;
			font-size: 44px;
			letter-spacing: -.05em;
			color: #000;
		}

		.desc {
			margin-top: 19px;
			line-height: 30px;
			font-weight: 300;
			font-size: 20px;
			color: #666;
		}
	}

	/* 회원가입 스탭 */
	.step-join {
		text-align: center;
		font-size: 0;

		li {
			display: inline-block;
			width: 119px;
			position: relative;

			&:not(:first-child) {
				margin-left: 100px;

				&:before {
					content: '';
					display: block;
					width: 100px;
					height: 1px;
					background: #ddd;
					position: absolute;
					top: 59px;
					left: -100px;
				}
			}

			&.step1 .step { background: url('@@img/join/join-step1-off.png') no-repeat; }
			&.step2 .step { background: url('@@img/join/join-step2-off.png') no-repeat; }
			&.step3 .step { background: url('@@img/join/join-step3-off.png') no-repeat; }

			&.current {
				&.step1 .step { background: url('@@img/join/join-step1-on.png') no-repeat; }
				&.step2 .step { background: url('@@img/join/join-step2-on.png') no-repeat; }
				&.step3 .step { background: url('@@img/join/join-step3-on.png') no-repeat; }

				.title {
					color: #422121;
				}
			}
		}

		.step {
			display: block;
			width: 119px;
			max-height: 119px;
			padding-top: 119px;
			overflow: hidden;
		}

		.title {
			display: block;
			margin-top: 16px;
			margin-bottom: 60px;
			font-weight: 500;
			font-size: 20px;
			color: #999;
		}
	}

	.license-container {
		box-sizing: border-box;
		width: 1000px;
		margin: 0 auto;
		padding: 60px 50px;
		background: #f5f5f5;
	}

	.main-box {
		box-sizing: border-box;
		width: 1000px;
		margin-left: auto;
		margin-right: auto;
		padding: 60px 0;
		border-top: 2px solid #232323;
		border-bottom: 1px solid #ddd;

		.layout-form {
			margin: 0 138px;
		}
	}
}
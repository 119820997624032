/* 직영 및 제휴체인 안내  */
.sellinfo-chain-info {

	.resort-chain-area {
		background: #f5f5f5;
		margin-bottom: -120px;
		padding-bottom: 120px;
	}
	
	.wrap {
		width: $content-width;
		margin: 0 auto;
		padding-top: 60px;
		overflow: hidden;
		box-sizing: border-box;
	}

	.map {
		position: relative;
		float: left;
		width: 590px;
		height: 865px;
		background: url('@@img/sellinfo/korea-map.png') no-repeat left top;
	
		.point {
			display: block;
			width: 590px;
			height: 865px;
			position: absolute;
			top: 0;
			left: 0;
			
			&:nth-child(1) { background: url('@@img/sellinfo/chain-pyeongchang.png') no-repeat; }
			&:nth-child(2) { background: url('@@img/sellinfo/chain-jeju.png') no-repeat; }
			&:nth-child(3) { background: url('@@img/sellinfo/chain-resort-hanwha.png') no-repeat; }
			&:nth-child(4) { background: url('@@img/sellinfo/chain-resort-kumho.png') no-repeat; }
			&:nth-child(5) { background: url('@@img/sellinfo/chain-cc-elysian.png') no-repeat; }
			&:nth-child(6) { background: url('@@img/sellinfo/chain-cc-havich.png') no-repeat; }
			&:nth-child(7) { background: url('@@img/sellinfo/chain-cc-cypress.png') no-repeat; }
			&:nth-child(8) { background: url('@@img/sellinfo/chain-cc-wooridul.png') no-repeat; }
			&:nth-child(9) { background: url('@@img/sellinfo/chain-cc-saintpo.png') no-repeat; }
			&:nth-child(10) { background: url('@@img/sellinfo/chain-cc-rawon.png') no-repeat; }
		}
	}

	.dec-wrap {
		float: left;
		box-sizing: border-box;
		margin-top: 35px;
		width: 600px;
		background: #fff;
	}

	.dec {
		box-sizing: border-box;
		width: 550px;
		height: 775px;
		margin: 15px 40px;
		overflow-y: scroll;
	}

	.dec-con {
		padding: 30px 20px 30px 0;

		h4 {
			margin-bottom: 25px;
			padding-bottom: 3px;
			border-bottom: 1px solid #232323;
			font-size: 26px;
			font-weight: 500;
			color: #000;

			& + h5 { margin-top: 0; }
		}

		h5 {
			margin-top: 30px;
			margin-bottom: 10px;
			padding: 0 5px;
			font-weight: 500;
			font-size: 20px;
			color: #232323;

			& + h6 { margin-top: 0; }
		}

		h6 {
			margin-top: 20px;
			margin-bottom: 10px;
			padding: 0 5px;
			font-weight: 500;
			font-size: 16px;
			color: #232323;
		}
	}

	.link-area { 
		height: 0;
		text-align: right;
		padding-right: 3px;
		color: #232323;
		position: relative;
		top: -34px;
		z-index: 1;

		.guide-link { font-size: 14px; }
	}
}
/* 사이트맵 (전체메뉴) */
.pop-sitemap {
	&.common-pop-body {
		width: 1160px;
	}
	
	.sitemap-list {
		border-top: 1px solid #232323;

		> li {
			padding: 20px 0 20px 17px;

			@include clearfix;

			&:not(:first-child) { border-top: 1px solid #ddd; }
		}
	}

	.main {
		float: left;
		width: 128px;
		height: 36px;
		line-height: 38px;
		text-align: center;
		font-size: 18px;
		color: #fff;
		letter-spacing: -.05em;
		background: #232323;
	}

	.sub-list {
		float: right;
		width: 835px;
		padding-top: 7px;
		font-size: 0;
	}

	.sub-list2 {
		margin-top: 5px;
	}

	.sub-list3 {
		margin-bottom: 5px;
	}

	.sub1 {
		display: inline-block;
		width: 20%;
		vertical-align: top;
		font-weight: 500;
		font-size: 15px;
		color: #232323;

		@include clearfix;

		&:nth-child(5) ~ .sub1 { margin-top: 20px; }
		> a:hover, > a:focus { color: #b0895c; }
	}

	.sub2 {
		font-weight: 300;
		font-size: 15px;
		color: #666;
	}

	.sub3 {
		font-weight: 300;
		font-size: 14px;
		color: #666;
	}

	.site-part {
		float: right;
		width: 900px;

		> li {
			padding: 20px 0;

			@include clearfix;

			&:first-child { padding-top: 0; }
			&:last-child { padding-bottom: 0; }
			&:not(:last-child) { border-bottom: 1px solid #ddd; }
		}
	}

	.site {
		float: left;
		width: 50px;
		padding-top: 4px;
		text-align: center;
		font-weight: 500;
		font-size: 16px;
		color: #b0895c;
	}
}
/* 이용안내 - 이용 요금 및 할인 안내 */
.guide-price {
	/* 운영기간 안내 */
	.operation-period {
		background: #f5f5f5; //임시
	}

	.foot-guide-list {
		margin-top: 15px;
		
		> li {
			padding: 0 20px;
			font-weight: 300;
			font-size: 16px;
			position: relative;
			
			&:not(:first-child) { margin-top: 4px; }

			&:after {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				background: #b4b4b4;
				position: absolute;
				top: 11px;
				left: 12px;
			}
		}
	}

	.foot-desc {
		margin-top: 15px;
		font-size: 16px;
		font-weight: 300;
	}

	/* 컨텐츠 하위 버튼 */
	.content-foot-btn {
		margin-top: 50px;
		text-align: center;
	
		.btn-action + .btn-action {
			margin-left: 18px;
		}
	}

	/* 2단 영역 */
	.half-wrap {
		@include clearfix;

		.group-1 { 
			box-sizing: border-box;
			float: left;
			width: 50%;
			padding-right: 15px;
		}
		.group-2 { 
			box-sizing: border-box;
			float: right;
			width: 50%;
			padding-left: 15px;
		}
	}
	
	.table-container {
		.dot-bu-list li { display: block; }
	}
}
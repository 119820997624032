/* 객실 상세 - 별장 */
.room-detail-villa {
	margin-bottom: -120px;

	.common-top-info {
		background: #f6f3ee;
	}
	
	.main-msg {
		margin: 60px 0 60px;
		text-align: center;
		font-size: 32px;
		color: #232323;
		letter-spacing: -.05em;
	}

	/* 힐리우스 이용안내 */
	.hillius-info {
		display: table;
		width: 100%;
		margin: 0 -20px -20px;
		border-spacing: 20px;
		table-layout: fixed;

		dl {
			box-sizing: border-box;
			display: table-cell;
			width: calc(100% / 3);
			height: 200px;
			line-height: 1.3;
			padding: 195px 0 50px;
			text-align: center;
			border: 1px solid #ddd;

			&:nth-child(1) { background: url('@@img/room/jeju/hillius1.png') no-repeat 50% 70px; }
			&:nth-child(2) { background: url('@@img/room/jeju/hillius2.png') no-repeat 50% 70px; }
			&:nth-child(3) { background: url('@@img/room/jeju/hillius3.png') no-repeat 50% 70px; }
		}

		dt {
			display: block;
			font-size: 16px;

			strong {
				font-size: 20px;
				color: #232323;
			}
		}

		dd {
			display: block;
			margin-top: 20px;
			font-weight: 300;
			font-size: 16px;
		}
	}
}
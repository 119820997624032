/* 찾기 결과 */
.login-find-result {

	.table-container {
		width: 850px;
		margin: 0 auto;
	}

	/* 결과 설명 - 아이콘 포함 */
	.result-msg {
		text-align: center;
		line-height: 30px;
		font-weight: 300;
		font-size: 20px;

		&:before {
			content: '';
			display: block;
			width: 119px;
			height: 119px;
			margin: 0 auto 14px;
			background: url('@@img/login/search-result.png') no-repeat;
		}
	}

}